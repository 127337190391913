import React from 'react';
import { Menu, MenuItem, Sidebar } from 'react-pro-sidebar';
import sideBarMenu from './menu';
import { useNavigate } from 'react-router-dom';
// import sidebarIcon from '../../../assets/icons/KGF_logo_sidebar.svg';
import GoldPulseLogo from '../../../assets/images/goldpulse_logo.png';

interface Props {
  collapsed: boolean;
  role: string;
}

export default function SidebarComponent({ collapsed, role }: Props) {
  const navigate = useNavigate();

  const handleTo = (to: string) => {
    navigate(to);
  };

  const filteredMenu = sideBarMenu.filter(
    (item: {
      label: string;
      to: string;
      icons: {
        inActive: JSX.Element;
        active: JSX.Element;
      };
    }) => {
      if (role === 'Admin') return true;

      const allowedRoles = {
        Overview: ['manager', 'auditor', 'superadmin', 'cashier', 'billing'],
        'Bank Pledge': ['manager', 'cashier', 'superadmin'],
        Loan: ['manager', 'billing', 'superadmin', 'cashier'],
        Customer: ['manager', 'billing', 'superadmin', 'cashier'],
        Transactions: ['manager', 'cashier', 'superadmin'],
        Deposits: ['manager', 'superadmin', 'cashier'],
        'Interest Scheme': ['superadmin'],
        Users: ['Admin', 'superadmin'],
        Investment: ['superadmin'],
        Recovery: ['superadmin', 'manager', 'billing'],
      };

      return allowedRoles[item.label as keyof typeof allowedRoles]?.includes(
        role
      );
    }
  );

  return (
    <Sidebar collapsed={collapsed}>
      <div className="image__header">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={GoldPulseLogo} width={100} height={100} />
          {/* <h3 style={{ margin: 0, fontSize: 20 }}>PULSE GOLD</h3> */}
          {/* <h6 style={{ margin: 0, fontSize: 8 }}>
            Gold Loan Management Software
          </h6> */}
          {/* <img
            src={sidebarIcon}
            alt="sidebarIcon"
            width={'100%'}
            height={'100%'}
          /> */}
        </div>
      </div>
      <Menu>
        {filteredMenu.map((item) => {
          const isActive = location.pathname === item.to;
          const currentIcon = isActive
            ? item.icons.active
            : item.icons.inActive;
          return (
            <MenuItem
              className={`list-item ${isActive ? 'list-item-active' : ''}`}
              key={item.label}
              icon={currentIcon}
              onClick={() => {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                handleTo(item.to);
              }}
            >
              {item.label}
            </MenuItem>
          );
        })}
      </Menu>
    </Sidebar>
  );
}
