import { createSlice } from '@reduxjs/toolkit';
import { getInvestment } from './investmentMiddleware';
import { type InvestmentResponse, type Investment } from './investmentTypes';

interface InitialState {
  investment: Investment;
  investments: InvestmentResponse[];
}

const initialState: InitialState = {
  investment: {
    branch: '',
    amount: 0,
    investedBy: '',
    transactionDate: ''
  },
  investments: []
};

const investmentReducer = createSlice({
  name: 'investmentReducer',
  reducers: {
    resetDeposits(state) {
      state.investments = initialState.investments
    }
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getInvestment.fulfilled, (state, action) => {
      state.investments = action.payload;
    });
  },
});

export const { resetDeposits } = investmentReducer.actions;
export default investmentReducer.reducer;