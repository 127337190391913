import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import { getPrice, updatePrice, getOurReport, getLedger, getDashboard } from './overviewMiddleware';

export const updatePriceAction = async (
  dispatch: AppDispatch,
  payload: { gold: number; silver: number }
) => {
  const data = {
    ...payload,
  };
  const res: any = await dispatch(updatePrice(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Price updated Successfully'));

    return res.payload;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(
      showErrorMessage(
        Boolean(res.payload?.response?.data?.message) ||
          'Error when updating price'
      )
    );
    return false;
  }
};

export const getPriceAction = async (dispatch: AppDispatch) => {
  const res = await dispatch(getPrice({}));
  console.log('res.data', res);
};

export const getOurReportAction = async (
  dispatch: AppDispatch,
  payload: { startDate: string; endDate: string }
) => {
  await dispatch(getOurReport(payload));
};

export const getDashboardAction = async (
  dispatch: AppDispatch,
  payload: { branch: string, startDate: string; endDate: string }
) => {
  await dispatch(getDashboard(payload));
};


export const getLedgerAction = async (
  dispatch: AppDispatch,
  payload: { branch: string; }
) => {
  await dispatch(getLedger(payload));
};

