import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';
import { type LoanPayment } from './customerTypes';

export const craeteNewCustomer = createAsyncThunk(
  'customer/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.CUSTOMERS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomers = createAsyncThunk(
  'customer/getCustomers',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.CUSTOMERS, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  'customer/updateCustomer',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { customerEditId, ...restPayload } = payload;
      const { data } = await putRequest(
        `${API.CUSTOMERS}/${customerEditId}`,
        restPayload
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  'customer/customerById',
  async (payload: Record<string, string>, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.CUSTOMERS}/search`,payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loanRepayment = createAsyncThunk(
  'loan/repayment',
  async (payload: LoanPayment, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${API.LOAN_PAYMENTS}`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
