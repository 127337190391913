/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Spin,
  DatePicker,
  Checkbox,
  type CheckboxProps,
  type DatePickerProps,
} from 'antd';
// import { LoadingOutlined } from '@ant-design/icons';
import ImageUpload from '../../../components/ImageUpload';
import WebcamImage from '../../../components/ImageUpload/webCamUpload';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  createNewCustomerAction,
  updateCustomerAction,
} from '../store/customerActions';
import dayjs, { type Dayjs } from 'dayjs';
import { type Customer } from '../store/customerTypes';
import { type AppDispatch } from '../../../redux/store';

interface Props {
  customer?: Customer;
}

export default function CustomerFormIndex({ customer }: Props): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [showWebCam, setShowWebCam] = useState(false);
  const handleWebCam = (): void => {
    setShowWebCam(!showWebCam);
  };

  const [showWebCam1, setShowWebCam1] = useState(false);
  const handleWebCam1 = (): void => {
    setShowWebCam1(!showWebCam1);
  };

  const [form] = Form.useForm();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [editCustomerId, setEditCustomerId] = useState<
    string | null | undefined
  >(null);
  const [customerName, setCustomerName] = useState<string>('');
  const [aadharNumber, setAadharNumber] = useState<string>('');
  const [branch, setBranch] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState<
    string | null
  >(null);
  const [drivingLicense, setDrivingLicense] = useState<string>('');
  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(null);
  const [nomineeName, setNomineeName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [photo, setPhoto] = useState<string>('');
  const [proofPhoto, setProofPhoto] = useState<string>('');
  const [mail, setMail] = useState<string>('');
  const [permanentAddress, setPermanentAddress] = useState<string>('');
  const [fatherName, setFatherName] = useState<string>('');
  const [area, setArea] = useState<string>('');
  const [pincode, setPincode] = useState<string>('');

  const onChangeCheckbox: CheckboxProps['onChange'] = (e) => {
    if (e.target.checked) {
      setPermanentAddress(address);
      form.setFieldsValue({ permanentAddress: address });
    }
  };

  useEffect(() => {
    if (customer != null) {
      form.setFieldsValue(customer);
      setEditCustomerId(customer._id);
      setCustomerName(customer.name);
      setAadharNumber(customer.aadhar);
      setBranch(customer.branch);
      setPhoneNumber(customer.phoneNumber);
      setAlternatePhoneNumber(customer.alternatePhoneNumber);
      setDrivingLicense(customer.drivingLicenseNumber);
      setDatePickerDate(dayjs(customer.dob));
      setNomineeName(customer.nominee);
      setAddress(customer.address);
      setPhoto(customer.photo);
      setProofPhoto(customer.proofPhoto);
      setMail(customer.mail);
      setPermanentAddress(customer.permanentAddress);
      setFatherName(customer.fatherName);
      setArea(customer.area);
      setPincode(customer.pincode);
    } else {
      form.resetFields();
      setEditCustomerId(null);
      setCustomerName('');
      setAadharNumber('');
      setBranch('');
      setPhoneNumber('');
      setAlternatePhoneNumber('');
      setDrivingLicense('');
      setDatePickerDate(null);
      setNomineeName('');
      setAddress('');
      setPhoto('');
      setProofPhoto('');
      setMail('');
      setPermanentAddress('');
      setFatherName('');
      setArea('');
      setPincode('');
    }
  }, [customer, form]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      if (!customer) {
        setBranch(branchOptionTemp[0].value);
        form.setFieldsValue({
          branch: branchOptionTemp[0].value,
        });
      }
    }
  }, [customer, form]);

  const customValidator = () => {
    return !(
      form.getFieldValue('aadhar') || form.getFieldValue('drivingLicenseNumber')
    );
  };

  const onFinish = async (values: Customer) => {
    setIsLoading(true);
    const action =
      customer != null ? updateCustomerAction : createNewCustomerAction;
    const response = await action(dispatch, {
      ...values,
      photo,
      ...(editCustomerId != null && { customerEditId: editCustomerId }),
    });
    setIsLoading(false);
    if (response && editCustomerId == null) {
      form.resetFields();
      setCustomerName('');
      setAadharNumber('');
      setBranch(branchOptions[0].value);
      setPhoneNumber('');
      setDrivingLicense('');
      setMail('');
      setNomineeName('');
      setAddress('');
      setDatePickerDate(null);
      setPhoto('');
      setProofPhoto('');
      setPermanentAddress('');
      navigate(`/customer?customerId=${response}`);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    form.setFieldsValue({ dob: dateString });
    setDatePickerDate(date);
  };

  const disabledDate = (current: Dayjs) => current > dayjs().startOf('day');

  return (
    <Form
      form={form}
      name="customer_form"
      initialValues={customer}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Spin spinning={isLoading} fullscreen />
      <div className="customers__form">
        <div className="customers__form__input">
          <Form.Item
            name="photo"
            rules={[
              { required: true, message: 'Please upload customer photo!' },
            ]}
          >
            <div className="customers__photo__container">
              <p className="customers__input__label">Profile Image</p>
              <p className="customers__photo__sub__title">
                Image format .jpg .jpeg .png. Minimum size 300px x 300px
              </p>
              <ImageUpload
                onImage={(image: string) => {
                  form.setFieldsValue({ photo: image });
                  setPhoto(image);
                  setShowWebCam(false);
                }}
                src={photo}
              />
              {!showWebCam && (
                <p className="customers__button" onClick={handleWebCam}>
                  Start Webcam
                </p>
              )}
              {showWebCam && (
                <WebcamImage
                  name="photo"
                  onChange={(e: {
                    target: { name: string; value: string };
                  }) => {
                    form.setFieldsValue({ photo: e?.target?.value });
                    setPhoto(e?.target?.value);
                  }}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="proofPhoto"
            rules={[{ required: true, message: 'Please upload proof photo!' }]}
          >
            <div className="customers__photo__container">
              <p className="customers__input__label">Proof Image</p>
              <p className="customers__photo__sub__title">
                Image format .jpg .jpeg .png. Minimum size 300px x 300px
              </p>
              <ImageUpload
                onImage={(image: string) => {
                  form.setFieldsValue({ proofPhoto: image });
                  setProofPhoto(image);
                  setShowWebCam1(false);
                }}
                src={proofPhoto}
              />
              {!showWebCam1 && (
                <p className="customers__button" onClick={handleWebCam1}>
                  Start Webcam
                </p>
              )}
              {showWebCam1 && (
                <WebcamImage
                  name="photo"
                  onChange={(e: {
                    target: { name: string; value: string };
                  }) => {
                    form.setFieldsValue({ proofPhoto: e?.target?.value });
                    setProofPhoto(e?.target?.value);
                  }}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please input customer name!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Customer Name</p>
              <Input
                value={customerName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({ name: e?.target?.value });
                  setCustomerName(e?.target?.value);
                }}
                className="customers__input"
                placeholder="Enter Customer Name"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="dob"
            rules={[
              { required: true, message: 'Please select date of birth!' },
            ]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Date of Birth</p>
              <DatePicker
                value={datePickerDate}
                disabledDate={disabledDate}
                className="customers__input"
                onChange={onChangeDate}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="aadhar"
            rules={[
              {
                required: customValidator(),
                message: 'Aadhar or Driving License required!',
              },
            ]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Aadhar Number</p>
              <Input
                value={aadharNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({ aadhar: e?.target?.value });
                  setAadharNumber(e?.target?.value);
                }}
                maxLength={12}
                className="customers__input"
                placeholder="Enter Aadhar Number"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="drivingLicenseNumber"
            rules={[
              {
                required: customValidator(),
                message: 'Aadhar or Driving License required!',
              },
            ]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Driving License</p>
              <Input
                value={drivingLicense}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    drivingLicenseNumber: e?.target?.value,
                  });
                  setDrivingLicense(e?.target?.value);
                }}
                maxLength={15}
                className="customers__input"
                placeholder="Enter Driving License"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Please input phone number!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Phone Number</p>
              <Input
                value={phoneNumber}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    phoneNumber: e?.target?.value,
                  });
                  setPhoneNumber(e?.target?.value);
                }}
                maxLength={10}
                className="customers__input"
                placeholder="Enter Phone Number"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="alternatePhoneNumber"
            rules={[{ required: true, message: 'Please input phone number!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Alternate Phone Number</p>
              <Input
                value={alternatePhoneNumber ?? ''}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    alternatePhoneNumber: e?.target?.value,
                  });
                  setAlternatePhoneNumber(e?.target?.value);
                }}
                maxLength={10}
                className="customers__input"
                placeholder="Enter Phone Number"
              />
            </div>
          </Form.Item>
          <Form.Item name="mail">
            <div className="customers__input__container">
              <p className="customers__input__label">Email</p>
              <Input
                value={mail}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    mail: e?.target?.value,
                  });
                  setMail(e?.target?.value);
                }}
                type="email"
                className="customers__input"
                placeholder="Enter email"
              />
            </div>
          </Form.Item>
          {!editCustomerId && (
            <Form.Item
              name="branch"
              rules={[{ required: true, message: 'Please select branch!' }]}
            >
              <div className="customers__input__container">
                <p className="customers__input__label">Branch</p>
                <Select
                  style={{ width: 311 }}
                  placeholder="Select branch"
                  value={branch}
                  onChange={(value: string): void => {
                    form.setFieldsValue({ branch: value });
                    setBranch(value);
                  }}
                  options={branchOptions}
                  className="customers__select"
                />
              </div>
            </Form.Item>
          )}
          <Form.Item
            name="nominee"
            rules={[{ required: true, message: 'Please input nominee name!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Nominee Name</p>
              <Input
                value={nomineeName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    nominee: e?.target?.value,
                  });
                  setNomineeName(e?.target?.value);
                }}
                className="customers__input"
                placeholder="Enter Nominee Name"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="fatherName"
            rules={[{ required: true, message: 'Please input father name!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Father Name</p>
              <Input
                value={fatherName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    fatherName: e?.target?.value,
                  });
                  setFatherName(e?.target?.value);
                }}
                className="customers__input"
                placeholder="Enter Father Name"
              />
            </div>
          </Form.Item>
          <Form.Item name="area">
            <div className="customers__input__container">
              <p className="customers__input__label">Area</p>
              <Input
                value={area}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    area: e?.target?.value,
                  });
                  setArea(e?.target?.value);
                }}
                className="customers__input"
                placeholder="Enter Area"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="pincode"
            rules={[{ required: true, message: 'Please input nominee name!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Pincode</p>
              <Input
                value={pincode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    pincode: e?.target?.value,
                  });
                  setPincode(e?.target?.value);
                }}
                className="customers__input"
                placeholder="Enter Pincode"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="address"
            rules={[{ required: true, message: 'Please input address!' }]}
          >
            <div className="customers__input__container">
              <p className="customers__input__label">Current Address</p>
              <Input
                value={address}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    address: e?.target?.value,
                  });
                  setAddress(e?.target?.value);
                }}
                className="customers__input__address"
                placeholder="Enter current address"
              />
            </div>
          </Form.Item>
          <div>
            <Form.Item
              name="permanentAddress"
              rules={[
                { required: true, message: 'Please input permanent address!' },
              ]}
            >
              <div className="customers__input__container">
                <p className="customers__input__label">Permanent Address</p>
                <Input
                  value={permanentAddress}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    form.setFieldsValue({
                      permanentAddress: e?.target?.value,
                    });
                    setPermanentAddress(e?.target?.value);
                  }}
                  className="customers__input__address"
                  placeholder="Enter permanent address"
                />
              </div>
            </Form.Item>
            <Checkbox
              checked={address !== '' && address === permanentAddress}
              style={{ marginTop: 10 }}
              onChange={onChangeCheckbox}
            >
              Same as current address
            </Checkbox>
          </div>
        </div>
        <div className="customers__actions__section">
          <Button htmlType="submit" className="customers__save__button">
            <span>{customer != null ? 'Save' : 'Save New Customer'}</span>
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
              setPhoto('');
            }}
            className="customers__cancel__button"
          >
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
