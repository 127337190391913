/* eslint-disable react/react-in-jsx-scope */
import overViewIcon from '../../../assets/icons/over-view.svg';
import bankIcon from '../../../assets/icons/bank-pledge.svg';
import loanIcon from '../../../assets/icons/rupee.svg';
import customerIcon from '../../../assets/icons/user.svg';
import customerIconActive from '../../../assets/icons/user-active.svg';

const sideBarMenu = [
  {
    label: 'Overview',
    to: '/',
    icons: {
      inActive: <img src={overViewIcon} alt="overViewIcon" />,
      active: <img src={overViewIcon} alt="overViewIcon" />,
    },
  },
  {
    label: 'Bank Pledge',
    to: '/pledge',
    icons: {
      inActive: <img src={bankIcon} alt="bankIcon" />,
      active: <img src={bankIcon} alt="bankIcon" />,
    },
  },
  {
    label: 'Loan',
    to: '/loan',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
  {
    label: 'Customer',
    to: '/customer',
    icons: {
      inActive: <img src={customerIcon} alt="customerIcon" />,
      active: <img src={customerIconActive} alt="customerIconActive" />,
    },
  },
  {
    label: 'Transactions',
    to: '/transaction',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
  {
    label: 'Deposits',
    to: '/deposit',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
  {
    label: 'Investment',
    to: '/investment',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
  {
    label: 'Interest Scheme',
    to: '/interest-scheme',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
  {
    label: 'Users',
    to: '/users',
    icons: {
      inActive: <img src={customerIcon} alt="customerIcon" />,
      active: <img src={customerIconActive} alt="customerIconActive" />,
    },
  },
  {
    label: 'Recovery',
    to: '/recovery',
    icons: {
      inActive: <img src={loanIcon} alt="loanIcon" />,
      active: <img src={loanIcon} alt="loanIcon" />,
    },
  },
];

export default sideBarMenu;
