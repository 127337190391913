/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useState, useEffect } from 'react';
import { Input, Button, Spin, DatePicker, Select, Table } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { type PledgeResponse } from '../store/pledgeTypes';
import { closePledgeAction } from '../store/pledgeActions';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import type { DatePickerProps } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';

// const { TextArea } = Input;

interface Props {
  pledge: PledgeResponse;
  closeModal: () => void;
}

export default function PaymentModal({
  pledge,
  closeModal,
}: Props): JSX.Element {
  // const [offlinePrincipal, setOfflinePrincipal] = useState<string>('');
  // const [offlineInterest, setOfflineInterest] = useState<string>('');
  const [onlinePrincipal, setOnlinePrincipal] = useState<number>(0);
  const [onlineInterest, setOnlineInterest] = useState<number>(0);
  // const [amountPaid, setAmountPaid] = useState<string>('');
  // const [cashier, setCashier] = useState<string>('');
  // const [notes, setNotes] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  // const [paymentType, setPaymentType] = useState<string>('');
  // const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());
  const [transactionDateString, setTransactionDateString] = useState<string>(
    dayjs().toISOString()
  );
  // const [cashier, setCashier] = useState<string>('');
  // const [otherTransactionAmount, setOtherTransactionAmount] =
  //   useState<string>('');
  // const [otherTransactionDescription, setOtherTransactionDescription] =
  //   useState<string>('');

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    setDatePickerDate(date);
    setTransactionDateString(dateString);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [extra, setExtra] = useState<number>(0);
  const [reducingField, setReducingField] = useState<number>(0);
  const [closureType, setClosureType] = useState<any>({});

  const handleClosureType = (jewelId: string, value: string) => {
    setClosureType((prevState: any) => ({
      ...prevState,
      [jewelId]: value,
    }));
  };

  const jewelColumns = [
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: any, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (_: any, record: any) => <p>{record.itemType}</p>,
    },
    {
      title: 'Closure Type',
      dataIndex: 'closureType',
      key: 'closureType',
      render: (_: any, record: any, index: number) => {
        return (
          <Select
            placeholder="Select Closure Type"
            options={[
              { value: 'Action', label: 'Action' },
              { value: 'Sales', label: 'Sales' },
              {
                value: 'Customer',
                label: 'Customer',
              },
              {
                value: 'Repledge',
                label: 'Repledge',
              },
            ]}
            onChange={(value: string): void => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              handleClosureType(record._id, value);
            }}
            className="payment__select"
          />
        );
      },
    },
  ];

  const dispatch = useDispatch<AppDispatch>();

  const makePledgePayment = async () => {
    setIsLoading(true);
    await closePledgeAction(dispatch, {
      pledgeNumber: pledge.pledgeNumber,
      // Cashier: cashier,
      transactionDate: transactionDateString,
      description,
      // Notes: notes,
      interestAmount: Number(onlineInterest),
      principal: Number(onlinePrincipal),
      // cashier,
      branch: pledge.branch._id,
      closureType,
      extra:
        Number(onlineInterest) + Number(reducingField) >
        Number(pledge.pendingInterest)
          ? Number(onlineInterest) - Number(pledge.pendingInterest)
          : 0,
      reducingField,
      // OtherTransaction: {
      //   Amount: Number(otherTransactionAmount),
      //   Description: otherTransactionDescription,
      // },
    });
    setIsLoading(false);
    closeModal();
  };

  useEffect(() => {
    setOnlinePrincipal(pledge.remainingPrincipal);
    setOnlineInterest(pledge.pendingInterest);
  }, []);

  return (
    <div className="pledge__payment__modal">
      <div className="divider" />
      <div className="payment__form">
        <div>
          <h4>Principal</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={onlinePrincipal}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOnlinePrincipal(Number(e.target.value));
            }}
            className="payment__input"
            placeholder="Enter principal"
          />
        </div>
        {/* <div>
          <h4>Offline Principal</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={offlinePrincipal}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOfflinePrincipal(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter offline principal"
          />
        </div> */}
        <div>
          <h4>Pending Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={pledge.pendingInterest}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   setOnlineInterest(Number(e.target.value));
            // }}
            className="payment__input"
            placeholder="Enter interest"
          />
        </div>
        <div>
          <h4>Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={onlineInterest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOnlineInterest(Number(e.target.value));
            }}
            className="payment__input"
            placeholder="Enter interest"
          />
          {Number(onlineInterest) + Number(reducingField) >
            Number(pledge?.pendingInterest) && (
            <p>
              Receiving an extra amount of{' '}
              {Number(onlineInterest) +
                Number(reducingField) -
                Number(pledge?.pendingInterest)}{' '}
              rupees
            </p>
          )}
        </div>
        <div>
          <h4>Reducing Field</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={reducingField}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setReducingField(Number(e.target.value));
            }}
            className="payment__input"
            placeholder="Enter Reducing Field"
          />
        </div>
        {/* <div>
          <h4>Extra</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={extra}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setExtra(Number(e.target.value));
            }}
            className="payment__input"
            placeholder="Enter Extra"
          />
        </div> */}
        {/* <div>
          <h4>Offline Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={offlineInterest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOfflineInterest(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter offline interest"
          />
        </div> */}
        {/* <div>
          <h4>Cashier</h4>
          <Input
            value={cashier}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCashier(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter cashier"
          />
        </div> */}
        <div>
          <h4>Transaction Date</h4>
          <DatePicker
            value={datePickerDate}
            // disabledDate={disabledDate}
            className="payment__input"
            onChange={onChangeDate}
          />
        </div>
        {/* <div>
          <h4>Closure Type</h4>
          <Select
            defaultValue={closureType}
            placeholder="Select Closure Type"
            options={[
              { value: 'Action', label: 'Action' },
              { value: 'Sales', label: 'Sales' },
              {
                value: 'Customer',
                label: 'Customer',
              },
              {
                value: 'Repledge',
                label: 'Repledge',
              },
            ]}
            onChange={(value: string): void => {
              setClosureType(value);
            }}
            className="payment__select"
          />
        </div> */}
        <div style={{ width: '100%' }}>
          <h2>Jewels</h2>
          <Table
            dataSource={pledge?.jewels}
            columns={jewelColumns}
            pagination={false}
          ></Table>
        </div>
        {/* <div>
          <h4>Cashier</h4>
          <Input
            value={cashier}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCashier(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter cashier"
          />
        </div> */}
        {/* <div>
          <h4>Amount Paid</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={amountPaid}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAmountPaid(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div> */}
        {/* <div>
          <h4>Payment Method</h4>
          <Select
            style={{ width: 280, height: 56 }}
            placeholder="Select payment method"
            value={paymentMethod}
            onChange={(value: string): void => {
              setPaymentMethod(value);
            }}
            options={[
              { label: 'Cash', value: 'Cash' },
              { label: 'Card', value: 'Card' },
              { label: 'Online', value: 'Online' },
              { label: 'Other', value: 'Other' },
            ]}
            className="payment__select"
          />
        </div> */}
        {/* <div>
          <h4>Payment Type</h4>
          <Select
            style={{ width: 280, height: 56 }}
            placeholder="Select payment type"
            value={paymentType}
            onChange={(value: string): void => {
              setPaymentType(value);
            }}
            options={[
              { label: 'full', value: 'full' },
              { label: 'partial', value: 'partial' },
            ]}
            className="payment__select"
          />
        </div> */}
        <div>
          <h4>Description</h4>
          <Input
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
            className="payment__longinput"
            placeholder="Enter description"
          />
        </div>
        {/* <div>
          <h4>Notes</h4>
          <TextArea
            value={notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setNotes(e.target.value);
            }}
            className="payment__textarea"
            placeholder="Enter notes"
          />
        </div> */}
        {/* <div>
          <h4>Other Transaction Amount</h4>
          <Input
            value={otherTransactionAmount}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setOtherTransactionAmount(e.target.value);
            }}
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            className="payment__longinput"
            placeholder="Enter other transaction amount"
          />
        </div>
        <div>
          <h4>Other Transaction Description</h4>
          <TextArea
            value={otherTransactionDescription}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setOtherTransactionDescription(e.target.value);
            }}
            className="payment__textarea"
            placeholder="Enter other transaction description"
          />
        </div> */}
      </div>
      <div className="payment__actions__section">
        {/* <Button
          onClick={() => {
            // setTemp(!temp);
          }}
          className="payment__cancel__button"
        >
          Cancel
        </Button> */}
        <Button
          onClick={makePledgePayment}
          disabled={
            Number(onlinePrincipal) !== Number(pledge.remainingPrincipal) ||
            Number(onlineInterest) + Number(reducingField) <
              Number(pledge.pendingInterest)
          }
          className="payment__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}
