import React from 'react';
import { Menu, Dropdown, Avatar } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';

const HeaderDropdown = ({
  username,
  logout,
}: {
  username: string;
  logout: () => void;
}) => {
  const menu = (
    <Menu>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={logout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div className="avatar-dropdown">
        <Avatar
          size="large"
          style={{ backgroundColor: '#DD1E26' }}
          // icon={<UserOutlined />}
        >
          {username.charAt(0).toUpperCase()}
        </Avatar>
        <span className="username">
          {username.charAt(0).toUpperCase() + username.slice(1)}
        </span>
      </div>
    </Dropdown>
  );
};

export default HeaderDropdown;
