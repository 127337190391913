import { Button } from 'antd';
import React, { type ReactNode } from 'react';
interface Props {
  onClick: (event: any) => void;
  label: string | ReactNode;
  fullWidth?: any;
}
export default function OutlinedButton({ onClick, label }: Props): JSX.Element {
  return (
    <Button className="outlined__button" onClick={onClick}>
      {label}
    </Button>
  );
}
