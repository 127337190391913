import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL, TOKEN } from '../constant';

const request = axios.create({
  baseURL: BASE_URL,
});

// set token on request headers
request.interceptors.request.use((config: any) => {
  // const token = Cookies.get(TOKEN);
  return {
    ...config,
    headers: {
      ...config.headers,
      // Authorization: token != null ? `Bearer ${token}` : '',
      'Ocp-Apim-Subscription-Key': 'e794cc519bc84769b13e98385fd65b98',
    },
  };
});

// handle 401 and logout
request.interceptors.response.use(
  (response) => response,
  (err) => {
    
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (err?.response?.data && err.response.data.message === "jwt expired") {
      console.log('Inside token expired');
      window.localStorage.clear();
      window.location.href = '/';
    }
    if (err.response.status === 401) {
      // remove cookies here and refresh
      Cookies.remove(TOKEN);
      window.location.reload();
    }
    throw err;
  }
);

export default request;
