import React, { useState } from 'react';
import TabComponent from '../../components/TabComponent';
import ExpenseIndex from './Expense';
import DailyTransactionsIndex from './DailyTransactions';
import OrganizationTransactionsIndex from './OrganizationTransactions';
import SuspenseTransactionsIndex from './SuspenseTransactions';

export default function TransactionsIndex() {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabOptions = [
    {
      to: '',
      label: 'Expense',
      index: 0,
    },
    {
      to: '',
      label: 'Daily Transaction',
      index: 1,
    },
    {
      to: '',
      label: 'Organization Transactions',
      index: 2,
    },
    {
      to: '',
      label: 'Suspense Transactions',
      index: 3,
    },
  ];

  return (
    <div className="transactions__page">
      <h3>Transactions</h3>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 1 && <DailyTransactionsIndex />}
        {selectedIndex === 0 && <ExpenseIndex />}
        {selectedIndex === 2 && <OrganizationTransactionsIndex />}
        {selectedIndex === 3 && <SuspenseTransactionsIndex />}
      </div>
    </div>
  );
}
