import React, { useState } from 'react';
import TabComponent from '../../components/TabComponent';
import { UnpledgedLoansIndex } from './UnpledgedLoans';
import CashDisbursementsIndex from './CashDisbursement';
import LoanApprovalsIndex from './LoanApproval';
// import { DocumentChargeIndex } from './DocumentCharge';
import LoanIndex from '../Loan';
import LoanPaymentsIndex from './LoanPayments';
import { LoanRecoveryIndex } from './LoanRecovery';

export const LoanAllIndex = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const role: string | null = localStorage.getItem('role');
  let tabOptions = [
    {
      to: '',
      label: 'Loan Form',
      index: 0,
    },
    {
      to: '',
      label: 'Unpledged loans',
      index: 1,
    },
  ];

  // if (role === 'superadmin' || role === 'manager') {
  //   tabOptions.push({
  //     to: '',
  //     label: 'Document charge approvals',
  //     index: 2,
  //   });
  // }
  if (role === 'cashier') {
    tabOptions = [
      {
        to: '',
        label: 'Cash Disbursements',
        index: 0,
      },
      {
        to: '',
        label: 'Loan Payments',
        index: 1,
      },
    ];
  }

  if (role === 'manager' || role === 'superadmin') {
    tabOptions.push({
      to: '',
      label: 'Loan Approvals',
      index: 2,
    });
  }

  return (
    <div className="loan__all__page">
      <div className="title">
        <h1>Loan</h1>
      </div>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 0 &&
          (role === 'cashier' ? <CashDisbursementsIndex /> : <LoanIndex />)}
        {/* // : role === 'manager' || role === 'superadmin' ? (
          //   <DocumentChargeIndex />
          // ) : (
          //   <></>
          // ))} */}
        {selectedIndex === 1 &&
          (role === 'cashier' ? (
            <LoanPaymentsIndex />
          ) : (
            <UnpledgedLoansIndex />
          ))}

        {selectedIndex === 2 && <LoanApprovalsIndex />}
        {selectedIndex === 3 && <LoanRecoveryIndex />}
      </div>
    </div>
  );
};
