import React, { useState, useEffect } from 'react';
import TabComponent from '../../components/TabComponent';
import GoldRate from './GoldRate';
import OurReport from './OurReport';
import LedgerIndex from './Ledger';

export default function OverviewIndex(): JSX.Element {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const role: string | null = localStorage.getItem('role');
  const tabOptions = [
    {
      to: '',
      label: 'Our Report',
      index: 0,
    },
  ];

  if (role === 'superadmin' || role === 'auditor') {
    tabOptions.push({
      to: '',
      label: 'Ledger',
      index: 1,
    });
    tabOptions.push({
      to: '',
      label: 'Gold Rate',
      index: 2,
    });
  }

  if (role === 'manager') {
    tabOptions.push({
      to: '',
      label: 'Ledger',
      index: 1,
    });
  }

  useEffect(() => {
    if (role === 'superadmin' || role === 'auditor') {
      setSelectedIndex(2);
    }
  }, []);

  return (
    <div className="transactions__page">
      <h1>Overview</h1>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 2 && <GoldRate />}
        {selectedIndex === 0 && <OurReport />}
        {selectedIndex === 1 && <LedgerIndex />}
      </div>
    </div>
  );
}
