import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import { getDeposit, updateDeposit, payDeposit } from './depositMiddleware';
import { type DepositPayment, type Deposit } from './depositTypes';

export const updateDepositAction = async (
  dispatch: AppDispatch,
  payload: Deposit
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(updateDeposit(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Deposit updated Successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error updating deposit'));
    return false;
  }
};

export const getDepositAction = async (
  dispatch: AppDispatch,
  payload: { branch?: string, phoneNumber?: string }
) => {
  const res = await dispatch(getDeposit(payload));
  console.log(res);
};

export const depositPaymentAction = async (
  dispatch: AppDispatch,
  payload: DepositPayment
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(payDeposit(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Deposit payment Successful'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error on deposit payment'));
    return false;
  }
};
