import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const addExpense = createAsyncThunk(
  'expense/add',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.EXPENSES, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTransactions = createAsyncThunk(
  'transactions/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.EXPENSES, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOrgTransactions = createAsyncThunk(
  'orgtransactions/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.TRANSACTIONS, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSuspenseTransactions = createAsyncThunk(
  'suspensetransactions/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(`${API.EXPENSES}/list/suspense`, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteSuspense = createAsyncThunk(
  'suspense/delete',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.EXPENSES}/suspense/${payload.id}`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

