import React, { useState } from 'react';
import { Form, Input, Button, Spin, Table, InputNumber } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { createInterestSchemeAction } from '../store/interestSchemeActions';
import {
  type LoanOption,
  type LoanInterest,
} from '../store/interestSchemeTypes';
import PlusFilled from '../../../assets/icons/plus-filled.svg';

export default function InterestFormIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const [items, setItems] = useState<LoanInterest[]>([]);

  const onFinish = async (values: LoanOption) => {
    setIsLoading(true);
    const action = createInterestSchemeAction;
    const response = await action(dispatch, {
      ...values,
      interestRates: items,
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
      setItems([]);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  // const [dataSource, setDataSource] = useState([
  //   { sno: 1, startDate: 0, endDate: 31, interest: '' },
  // ]);

  const handleFieldChange = (
    e: number | null,
    record: LoanInterest,
    fieldName: keyof LoanInterest,
    index: number
  ) => {
    const newData = [...items] as LoanInterest[];
    // const index = newData.findIndex((item) => record.key === item.key);

    if (index !== -1) {
      (newData[index] as any)[fieldName] = e;
      setItems(newData);
    }
  };

  const handleDelete = (index: number) => {
    const newData = items.filter((element, i) => i !== index);
    setItems(newData);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: LoanInterest, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      editable: true,
      render: (_: any, record: LoanInterest, index: number) => (
        <InputNumber
          type="number"
          value={record.startDate}
          onChange={(e) => {
            handleFieldChange(e, record, 'startDate', index);
          }}
          className="loan__item__input"
        />
      ),
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      editable: true,
      render: (_: any, record: LoanInterest, index: number) => (
        <InputNumber
          type="number"
          value={record.endDate}
          onChange={(e) => {
            handleFieldChange(e, record, 'endDate', index);
          }}
          className="loan__item__input"
        />
      ),
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      editable: true,
      render: (text: string, record: LoanInterest, index: number) => (
        <InputNumber
          type="number"
          value={record.interest}
          onChange={(e) => {
            handleFieldChange(e, record, 'interest', index);
          }}
          className="loan__item__input"
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: LoanInterest, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleDelete(index);
          }}
        >
          Delete
        </p>
      ),
    },
  ];

  // const updateData = (key, field, value) => {
  //   const newData = [...dataSource];
  //   const index = newData.findIndex((item) => item.sno === key);
  //   if (index > -1) {
  //     newData[index][field] = value;
  //     setDataSource(newData);
  //   }
  // };

  // const [editingKey, setEditingKey] = useState('');

  // const editRow = (record) => {
  //   setEditingKey(record.sno);
  // };

  const handleAddNewItem = () => {
    const newItem: LoanInterest = {
      startDate:
        items.length > 0
          ? Number(items[items.length - 1].endDate) + Number(1)
          : 0,
      endDate: 0,
      interest: 0,
    };
    const newData = [...items, newItem];
    setItems(newData);
  };

  return (
    <div className="interestscheme__form__page">
      <h1>Interest Scheme Form</h1>
      <Form
        form={form}
        name="interestscheme_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="deposit__form">
          <Form.Item
            name="type"
            rules={[{ required: true, message: 'Please enter Interest Type' }]}
          >
            <div className="customers__input__container">
              <h4>Interest Type</h4>
              <Input
                className="deposit__input"
                placeholder="Enter Interest type"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="loanPercentage"
            rules={[
              { required: true, message: 'Please enter loan percentage' },
            ]}
          >
            <div>
              <h4>Loan percentage</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="deposit__input"
                placeholder="Enter loan percentage"
              />
            </div>
          </Form.Item>
        </div>
        <div className="loan__items__table">
          <Table dataSource={items} columns={columns} />
          <div className="add__item__button">
            <img src={PlusFilled} className="plus__filled__icon" />
            <p className="add__new__item__text" onClick={handleAddNewItem}>
              Add New Item
            </p>
          </div>
        </div>

        <Button
          disabled={items.length < 1}
          htmlType="submit"
          className="deposit__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Create Interest</span>
          )}
        </Button>
      </Form>
    </div>
  );
}
