import { createSlice } from '@reduxjs/toolkit';
import login from './signinMiddleware';
import Cookies from 'js-cookie';
import { TOKEN } from '../../../constant';

const siginReducer = createSlice({
  name: 'signin',
  initialState: {
    isAuthenticated: false,
    token: null,
    user: {},
  },
  reducers: {
    login: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (state, { payload }: { payload: { token: string } }) => {
        state.user = payload;
        Cookies.set(TOKEN, (payload?.token).length > 0 ? payload.token : '');
      }
    );
  },
});

export default siginReducer.reducer;
