import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getPriceAction, updatePriceAction } from '../store/overviewActions';

export default function GoldRate(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const { goldPrice, silverPrice }: any = useSelector<any>(
    (state) => state.overview
  );

  const onFinish = async (values: { gold: string; silver: string }) => {
    setIsLoading(true);
    const action = updatePriceAction;
    await action(dispatch, {
      gold: goldPriceOnline,
      silver: silverPriceOnline,
    });
    form.resetFields();
    setIsLoading(false);
    void getPrices();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const getPrices = async (): Promise<void> => {
    await getPriceAction(dispatch);
  };

  const [goldPriceOnline, setGoldPriceOnline] = useState(0);
  const [silverPriceOnline, setSilverPriceOnline] = useState(0);

  useEffect(() => {
    void getPrices();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setGoldPriceOnline(goldPrice);
  }, [goldPrice]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setSilverPriceOnline(silverPrice);
  }, [silverPrice]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <Form
      form={form}
      name="loan_form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Spin spinning={isLoading} fullscreen />
      <div className="overview__index__page">
        <div className="loan__form__input">
          <Form.Item
            name="Gold"
            // rules={[{ required: true, message: 'Gold price required!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Gold</p>
              <Input
                value={goldPriceOnline}
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setGoldPriceOnline(Number(e.target.value));
                }}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="loan__input"
                placeholder="Enter Gold Price"
              />
              {goldPriceOnline === 0 && (
                <p style={{ width: 'max-content' }}>
                  Gold Price needs to be updated
                </p>
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="Silver"
            // rules={[{ required: true, message: 'Silver price required!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Silver</p>
              <Input
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSilverPriceOnline(Number(e.target.value));
                }}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={silverPriceOnline}
                className="loan__input"
                placeholder="Enter Silver Price"
              />
              {silverPriceOnline === 0 && (
                <p style={{ width: 'max-content' }}>
                  Silver Price needs to be updated
                </p>
              )}
            </div>
          </Form.Item>
        </div>

        <div className="loan__actions__section">
          <Button htmlType="submit" className="loan__save__button">
            <span>Update</span>
          </Button>
        </div>
      </div>
    </Form>
  );
}
