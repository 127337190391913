import { createSlice } from '@reduxjs/toolkit';
import { getUsers } from './usersMiddleware';
import { type UserResponse } from './usersTypes';

interface InitialState {
  users: UserResponse[]
}

const initialState: InitialState = {
  users: []
};

const interestSchemeReducer = createSlice({
  name: 'usersReducer',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.users = action.payload;
    });
  },
});

export default interestSchemeReducer.reducer;
