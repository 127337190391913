import React, { useState } from 'react';
import TabComponent from '../../components/TabComponent';
import UsersFormIndex from './UsersForm';
import UsersTableIndex from './UsersTable';

export const UsersIndex = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabOptions = [
    {
      to: '',
      label: 'Create User',
      index: 0,
    },
    {
      to: '',
      label: 'Users list',
      index: 1,
    },
  ];

  return (
    <div className="users__page">
      <h3>Users</h3>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 1 && <UsersTableIndex />}
        {selectedIndex === 0 && <UsersFormIndex />}
      </div>
    </div>
  );
};
