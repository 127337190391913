import React, { useState, useEffect } from 'react';
import { Form, Input, DatePicker, Button, Select, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import type { DatePickerProps } from 'antd';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { addExpenseAction } from '../store/expenseActions';
import dayjs, { type Dayjs } from 'dayjs';
import { type Expense } from '../store/expenseTypes';

export default function ExpenseIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
      form.setFieldsValue({
        branch: parsedBranches[0].id,
        date: dayjs().format('YYYY-MM-DD'),
      });
    }
  }, []);

  const onChange: DatePickerProps['onChange'] = (date, dateString): void => {
    setDatePickerDate(date);
    form.setFieldsValue({ date: dateString });
  };

  // const handleChange = (value: string): void => {
  //   setPaymentMethod(value);
  // };

  // const [paymentMethod, setPaymentMethod] = useState<string>('cash');

  // form.setFieldsValue({
  //   branch: branchOptions[0].value,
  //   // PaymentMethod: 'cash',
  // });

  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());
  const [expenseType, setExpenseType] = useState('');

  const onFinish = async (values: Expense) => {
    setIsLoading(true);
    const action = addExpenseAction;
    const response = await action(dispatch, {
      ...values,
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
      // setPaymentMethod('cash');
      setBranch(branchOptions[0].value);
      setExpenseType('');
      setDatePickerDate(null);
      form.setFieldsValue({
        branch: branchOptions[0].value,
      });
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="expense__page">
      <h1>Expense</h1>
      <Form
        form={form}
        name="expense_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="expense__form">
          <Form.Item
            name="branch"
            rules={[{ required: true, message: 'Please select branch!' }]}
          >
            <div>
              <h4>Branch</h4>
              <Select
                value={branch}
                onChange={(value: string): void => {
                  form.setFieldsValue({ branch: value });
                  setBranch(value);
                }}
                style={{ width: 311 }}
                placeholder="Select branch"
                options={branchOptions}
                className="expense__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please enter amount' }]}
          >
            <div>
              <h4>Amount</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="expense__input"
                placeholder="Enter amount"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="date"
            rules={[{ required: true, message: 'Please enter expense date' }]}
          >
            <div>
              <h4>Expense Date</h4>
              <DatePicker
                value={datePickerDate}
                className="expense__input"
                onChange={onChange}
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="PaymentMethod"
            rules={[{ required: true, message: 'Please enter payment method' }]}
          >
            <div>
              <h4>Payment Method</h4>
              <Select
                style={{ width: 311 }}
                value={paymentMethod}
                onChange={handleChange}
                options={[
                  { value: 'cash', label: 'Cash' },
                  { value: 'online', label: 'Online' },
                ]}
                className="expense__select"
              />
            </div>
          </Form.Item> */}
          <Form.Item
            name="description"
            rules={[{ required: true, message: 'Please enter description' }]}
          >
            <div>
              <h4>Description</h4>
              <Input
                className="expense__input"
                placeholder="Enter description"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="type"
            rules={[{ required: true, message: 'Please select expense type' }]}
          >
            <div>
              <h4>Expense Type</h4>
              <Select
                style={{ width: 311 }}
                defaultValue={expenseType}
                placeholder="Select Expense Type"
                options={[
                  {
                    value: 'Management Expenses',
                    label: 'Management Expenses',
                  },
                  { value: 'Rent Expenses', label: 'Rent Expenses' },
                  { value: 'Salary', label: 'Salary' },
                  {
                    value: 'Advertisement Expenses',
                    label: 'Advertisement Expenses',
                  },
                  {
                    value: 'Repair and Maintenance Expenses',
                    label: 'Repair and Maintenance Expenses',
                  },
                  { value: 'Office Expenses', label: 'Office Expenses' },
                  { value: 'Bank Charges', label: 'Bank Charges' },
                  { value: 'Suspense', label: 'Suspense' },
                  { value: 'Others', label: 'Others' },
                ]}
                onChange={(value: string): void => {
                  form.setFieldsValue({ type: value });
                  setExpenseType(value);
                }}
                className="expense__input"
              />
            </div>
          </Form.Item>
        </div>
        <Button htmlType="submit" className="expense__save__button">
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Add expense</span>
          )}
        </Button>
      </Form>
    </div>
  );
}
