import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  Spin,
  DatePicker,
  Modal,
  Cascader,
} from 'antd';
import type { DatePickerProps, GetProp, CascaderProps } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { type RootState, type AppDispatch } from '../../redux/store';
import {
  type Metal,
  type InterestType,
  type LoanItem,
  type CreateLoanRequest,
} from './store/loanTypes';
import ItemDescription from './ItemDescription';
import {
  createNewLoanAction,
  getLoanTypesAction,
  searchClosedLoanNumbersAction,
} from './store/loanActions';
// import { type RangePickerProps } from 'antd/es/date-picker';
import ImageUpload from '../../components/ImageUpload';
import WebcamImage from '../../components/ImageUpload/webCamUpload';
import PrintableContent from './PrintableContent';
import { getCustomerByIdAction } from '../Customer/store/customerActions';
import { type Interest } from '../Pledge/store/pledgeTypes';

const { TextArea } = Input;

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

type DefaultOptionType = GetProp<CascaderProps, 'options'>[number];

export default function LoanIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [approvalType, setApprovalType] = useState('');

  const [printLoanDetails, setPrintLoanDetails] = useState<any>();
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [selectedLoanPercentage, setSelectedLoanPercentage] = useState(100);
  const [invalidAmount, setInvalidAmount] = useState(false);

  const [principal, setPrincipal] = useState(0);
  // const [offlinePayment, setOfflinePayment] = useState(0);

  const [searchParams] = useSearchParams();

  const [form] = Form.useForm();

  const [defaultMetalValue, setDefaultMetalValue] = useState('');

  form.setFieldsValue({
    customer: searchParams.get('customerId'),
  });
  form.setFieldsValue({ startDate: dayjs() });

  const getLoanTypes = async (): Promise<void> => {
    await getLoanTypesAction(dispatch);
    if (customerId !== '') {
      await getCustomerByIdAction(dispatch, { customerId });
    }
  };

  useEffect(() => {
    void getLoanTypes();
    form.setFieldsValue({
      customerType: 'direct',
    });
  }, []);

  const { metal }: any = useSelector<any>((state) => state.loan, shallowEqual);
  const { type }: any = useSelector<any>((state) => state.loan, shallowEqual);

  const { customer }: any = useSelector<RootState>(
    (rootState) => rootState.customer
  );

  useEffect(() => {
    if (metal.length > 0) {
      const metalList: Array<{ label: string; value: string }> = [];
      for (const x of metal) {
        if (x.price > 0) {
          metalList.push({
            label: x.name,
            value: x.name,
          });
        }
      }
      setMetalOptions(metalList);
      if (metalList.length > 0) {
        form.setFieldsValue({ metal: metalList[0].value });
        setDefaultMetalValue(metalList[0].value);
        handleChangeMetal(metalList[0].value);
      }
    }

    if (type.length > 0) {
      const interestOptions: Array<{ label: string; value: string }> = type.map(
        (x: InterestType) => {
          return {
            label: `${x.type} - ${x.loanPercentage}%`,
            value: x.type,
          };
        }
      );
      setInterestTypeOptions(interestOptions);
    }
  }, [metal, type]);

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<string>('');
  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (customer?.branch.length && customerId) {
        const customerBranch = branchOptionTemp.filter(
          (x) => x.label === customerId.substring(0, 3)
        )[0].value;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        setBranch(customerBranch);
        form.setFieldsValue({
          branch: customerBranch,
        });
      } else {
        setBranch(branchOptionTemp[0]?.value);
        form.setFieldsValue({
          branch: branchOptionTemp[0]?.value,
        });
      }
    }
  }, []);

  const [additionalAmount, setAdditionalAmount] = useState<number>(0);

  const onFinish = async (values: CreateLoanRequest) => {
    setIsLoading(true);
    const action = createNewLoanAction;
    const response = await action(dispatch, {
      ...values,
      branch,
      jewels: modifyItemsToJewels(items),
      photo,
      startDate: dayjs(datePickerDate).toISOString(),
      principal: Number(principal),
      additionalAmount,
      oldLoanNumber,
    });
    setIsLoading(false);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.loanNumber) {
      setPrintLoanDetails(response);

      if (
        response.status === 'admin_approval_pending' ||
        response.status === 'manager_approval_pending'
      ) {
        navigate(`/customer?customerId=${customerId}`);
      } else {
        setIsPrintModalOpen(true);
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  interface SelectOption {
    label: string;
    value: string;
  }

  const [showWebCam, setShowWebCam] = useState(false);
  const handleWebCam = (): void => {
    setShowWebCam(!showWebCam);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [interestTypeOptions, setInterestTypeOptions] = useState<
    SelectOption[]
  >([]);
  const [interestRateOptions, setInterestRateOptions] = useState<
    SelectOption[]
  >([]);
  const [metalOptions, setMetalOptions] = useState<SelectOption[]>([]);
  const [purityOptions, setPurityOptions] = useState<SelectOption[]>([]);
  const [metalType, setMetalType] = useState<SelectOption[]>([]);

  useEffect(() => {}, [
    purityOptions,
    metalType,
    metalOptions,
    interestTypeOptions,
    interestRateOptions,
  ]);

  const [customerId, setCustomerId] = useState<string>(
    searchParams.get('customerId') ?? ''
  );
  const [documentCharges, setDocumentCharges] = useState<number>();
  const [amountPayableToCustomer, setAmountPayableToCustomer] =
    useState<string>('');
  const [items, setItems] = useState<LoanItem[]>([]);
  const [photo, setPhoto] = useState<string>('');

  const handleChange = (value: string): void => {
    const typeFilter = type.filter((x: InterestType) => x.type === value)[0];
    const rateOptions: Array<{ label: string; value: string }> =
      typeFilter.interestRates.map((x: Interest) => {
        return {
          label: x.interest,
          value: x.interest,
        };
      });
    setInterestRateOptions(rateOptions);
    form.setFieldsValue({ interestPercentage: rateOptions[0]?.value });
    setSelectedLoanPercentage(typeFilter.loanPercentage as number);
  };

  useEffect(() => {
    handleUpdateItems(items);
  }, [selectedLoanPercentage]);

  const handleChangeMetal = (value: string): void => {
    const metalInfo: Metal = metal.filter((x: Metal) => x.name === value)[0];
    setDefaultMetalValue(value);
    form.setFieldsValue({ metal: value });

    const purityOptionsMap: SelectOption[] = metalInfo?.purityQuality.map(
      (x, index) => {
        return {
          label: String(`${x.purityName}`),
          value: String(x.purityPercentage),
        };
      }
    );
    setPurityOptions(purityOptionsMap);

    const metalTypesMap: SelectOption[] = metalInfo?.types.map((x) => {
      return {
        label: x,
        value: x,
      };
    });
    setMetalType(metalTypesMap);
  };

  const [customerType, setCustomerType] = useState('direct');

  const handleChangeCustomerType = (value: string): void => {
    setCustomerType(value);
    form.setFieldsValue({
      customerType: value,
    });
    const calculatedDocumentCharges =
      value === 'direct' || value === 'repledge'
        ? (Number(principal) * 0.1) / 100
        : (Number(principal) * 1) / 100;
    setDocumentCharges(calculatedDocumentCharges);
    form.setFieldsValue({
      documentCharge: calculatedDocumentCharges,
    });
    setApprovalType('');
  };

  const modifyItemsToJewels = (items: LoanItem[]) => {
    return items.map((item) => {
      return {
        itemType: item.itemName,
        purity: purityOptions.filter(
          (x) => Number(x.value) === Number(item.purity)
        )[0].label,
        grossWeight: parseFloat(Number(item.grossWt).toFixed(2)),
        unwantedWeight: parseFloat(Number(item.stoneWt).toFixed(2)),
        netWeight: parseFloat(Number(item.netWt).toFixed(2)),
        quantity: item.quantity,
        pledgeNumber: item.pledgeNumber,
        _id: item._id,
        isPledged: Boolean(item.pledgeNumber),
      };
    });
  };

  // eslint-disable-next-line arrow-body-style
  // const disabledDate: RangePickerProps['disabledDate'] = (
  //   current: any
  // ): boolean => {
  //   // Can not select days before after today
  //   return current < dayjs().startOf('day');
  // };

  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false);

  const handlePrintClose = () => {
    setIsPrintModalOpen(false);
    navigate(`/customer?customerId=${customerId}`);
  };

  // const setTotal = ({
  //   updatedItems,
  //   documentCharges,
  // }: {
  //   updatedItems: LoanItem[];
  //   documentCharges: string;
  // }) => {
  //   let total = 0;
  //   updatedItems.forEach((x) => {
  //     total = Number(total) + Number(x.finalAmt);
  //   });
  //   total = Number(total) - Number(documentCharges);
  //   setAmountPayableToCustomer(String(Math.floor(total)));
  // };

  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());

  const handleUpdateItems = (updatedItems: LoanItem[]) => {
    let maxPrice = 0;
    updatedItems = updatedItems.map((x: LoanItem) => {
      x.netWt = String(Number(x.grossWt) - Number(x.stoneWt));
      const selectedMetal: Metal = metal.filter(
        (x: Metal) => x.name === form.getFieldValue('metal')
      )[0];
      x.finalAmt = Number(selectedMetal.price) * (Number(x.purity ?? 0) / 100);
      x.finalAmt = Number(x.finalAmt) * (selectedLoanPercentage / 100);
      x.finalAmt = Number(x.netWt ?? 0) * x.finalAmt;
      x.finalAmt = Math.floor(x.finalAmt);
      maxPrice = Number(maxPrice) + Number(x.finalAmt);
      return x;
    });
    setMaxAmount(Math.floor(maxPrice));
    setAmountPayableToCustomer(String(Math.floor(maxPrice)));
    setItems(updatedItems);
  };
  const [loanData, setLoanData] = useState<any>();
  const [cascaderOptions, setCascaderOptions] = useState<Option[]>();

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some((option) =>
      (option.label as string).toLowerCase().includes(inputValue.toLowerCase())
    );

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    form.setFieldsValue({ startDate: dateString });
    // setDob(dateString);
    setDatePickerDate(date);
  };

  const onSearchLoanNumber = async (value: string) => {
    if (value !== '' && value.length === 10) {
      const data = await searchClosedLoanNumbersAction(dispatch, value);
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (data) {
        setLoanData(data[0]);
        // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
        if (data[0]) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          handleChangeMetal(data[0]?.metal);
        }
        setCascaderOptions(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          data.map((x: any) => {
            return {
              label: x.loanNumber,
              value: x.loanNumber,
            };
          })
        );
      }
    } else {
      setCascaderOptions([]);
    }
  };

  const [oldLoanNumber, setOldLoanNumber] = useState<string>('');

  const onChange = async (value: string[]) => {
    setOldLoanNumber(value[0]);
    const tempLoanItems: LoanItem[] = loanData.jewels.map(
      (x: any, index: number) => {
        return {
          _id: x._id,
          key: index,
          grossWt: x.grossWeight,
          stoneWt: x.unwantedWeight,
          netWt: x.netWeight,
          quantity: x.quantity,
          itemName: x.itemType,
          purity: purityOptions.find((y) => y.label === x.purity)?.value,
          pledgeNumber: x.pledgeNumber,
          disabled: true,
        };
      }
    );

    form.setFieldsValue({ photo: loanData.photo });
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setPhoto(loanData.photo);

    handleUpdateItems(tempLoanItems);
  };

  return (
    <Form
      form={form}
      name="loan_form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <Spin spinning={isLoading} fullscreen />
      <div className="loan__index__page">
        <div className="loan__form__input">
          <Form.Item
            name="customer"
            rules={[{ required: true, message: 'CustomerID required!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Customer ID</p>
              <Input
                disabled={Boolean(searchParams.get('customerId'))}
                value={customerId}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({ customer: e?.target?.value });
                  setCustomerId(e?.target?.value);
                }}
                className="loan__input"
                placeholder="Enter Customer ID"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="branch"
            rules={[{ required: true, message: 'Please select branch!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Branch</p>
              <Select
                style={{ width: 311 }}
                placeholder="Select branch"
                value={branch}
                onChange={(value: string): void => {
                  form.setFieldsValue({ branch: value });
                  setBranch(value);
                }}
                options={branchOptions}
                className="loan__select"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="customerType"
            rules={[{ required: true, message: 'Customer Type Required!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Customer Type</p>
              <Select
                value={customerType}
                style={{ width: 311 }}
                placeholder="Select Customer Type"
                onChange={(value: string): void => {
                  handleChangeCustomerType(value);
                }}
                options={[
                  { label: 'Direct', value: 'direct' },
                  { label: 'Takeover', value: 'takeover' },
                  { label: 'Repledge', value: 'repledge' },
                ]}
                className="loan__select"
              />
            </div>
          </Form.Item>
          {customerType === 'repledge' && (
            <div className="loan__input__container">
              <p className="loan__input__label">Loan Number</p>
              <Cascader
                style={{ width: 311, height: 56 }}
                options={cascaderOptions}
                onChange={(value: any) => {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  void onChange(value);
                }}
                autoClearSearchValue
                maxTagCount={1}
                showSearch={{ filter }}
                onSearch={(value) => {
                  void onSearchLoanNumber(value);
                }}
              />
            </div>
          )}
          <Form.Item
            name="interestType"
            rules={[{ required: true, message: 'Select Interest Type!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Interest Type</p>
              <Select
                style={{ width: 311 }}
                placeholder="Select interest type"
                onChange={(value: string): void => {
                  form.setFieldsValue({ interestType: value });
                  handleChange(value);
                }}
                options={interestTypeOptions}
                className="loan__select"
              />
            </div>
          </Form.Item>

          {/* <div className="loan__input__container">
          <p className="loan__input__label">Loan Amount</p>
          <Input
            value={loanAmount}
            type="number"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLoanAmount(e.target.value);
            }}
            className="loan__input"
            placeholder="Enter Loan Amount"
          />
        </div> */}
          <Form.Item
            name="interestPercentage"
            rules={[{ required: true, message: 'Select Interest!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Interest</p>
              <Select
                disabled
                value={interestRateOptions[0]?.value}
                style={{ width: 311 }}
                placeholder="Select interest"
                options={interestRateOptions}
                onChange={(value: string): void => {
                  form.setFieldsValue({ interestPercentage: value });
                }}
                className="loan__select"
                suffixIcon={<p>%</p>}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="metal"
            rules={[{ required: true, message: 'Item group required!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Item Group</p>
              <Select
                value={defaultMetalValue}
                style={{ width: 311 }}
                placeholder="Select Item Group"
                onChange={(value: string): void => {
                  form.setFieldsValue({ metal: value });
                  handleChangeMetal(value);
                }}
                options={metalOptions}
                className="loan__select"
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="Advance"
            rules={[{ required: true, message: 'Please enter advance!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Advance</p>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    Advance: e.target.value,
                  });
                  setAdvance(e.target.value);
                  setTotal({
                    updatedItems: items,
                    documentCharges,
                    advance: e.target.value,
                  });
                }}
                className="loan__input"
                placeholder="Enter advance"
              />
            </div>
          </Form.Item> */}

          <Form.Item
            name="startDate"
            rules={[{ required: true, message: 'Please select start date!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Start Date</p>
              <DatePicker
                value={datePickerDate}
                className="loan__input"
                onChange={onChangeDate}
              />
            </div>
          </Form.Item>
          <Form.Item
            name="photo"
            rules={[{ required: true, message: 'Loan Image is required' }]}
          >
            <div className="loan__photo__container">
              <p className="loan__input__label">Image</p>
              <p className="loan__photo__sub__title">
                Image format .jpg .jpeg .png
              </p>
              <p className="loan__photo__sub__title">
                Minimum size 300px x 300px
              </p>
              <ImageUpload
                onImage={(image: string) => {
                  form.setFieldsValue({ photo: image });
                  setPhoto(image);
                  setShowWebCam(false);
                }}
                src={photo}
              />
              {!showWebCam && (
                <p className="loan__button" onClick={handleWebCam}>
                  Start Webcam
                </p>
              )}
              {showWebCam && (
                <WebcamImage
                  name="photo"
                  onChange={(e: {
                    target: { name: string; value: string };
                  }) => {
                    form.setFieldsValue({ photo: e?.target?.value });
                    setPhoto(e?.target?.value);
                  }}
                />
              )}
            </div>
          </Form.Item>
          <Form.Item name="remarks">
            <div className="loan__input__container">
              <p className="loan__input__label">Loan remarks</p>
              <TextArea
                rows={3}
                style={{ width: 311 }}
                className="loan__textarea"
                placeholder="Enter remarks"
              />
            </div>
          </Form.Item>

          {/* <Form.Item
            name="paymentMethod"
            rules={[{ required: true, message: 'Select Payment method' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Payment Method</p>
              <Select
                style={{ width: 311 }}
                placeholder="Select payment method"
                onChange={(value: string): void => {
                  form.setFieldsValue({ paymentMethod: value });
                }}
                options={[
                  { label: 'Cash', value: 'cash' },
                  { label: 'Online', value: 'online' },
                ]}
                className="loan__select"
              />
            </div>
          </Form.Item> */}
        </div>
        <div className="loan__items__section">
          <p className="loan__items__title">Item Description</p>
          <ItemDescription
            items={items}
            purityDetails={purityOptions}
            metalType={metalType}
            updateItems={(updatedItems: LoanItem[]) => {
              handleUpdateItems(updatedItems);
            }}
          />
        </div>

        <div className="loan__form__input">
          <div className="loan__input__container">
            <p className="loan__input__label">Amount Payable to Customer</p>
            <InputNumber
              type="number"
              disabled
              value={Number(amountPayableToCustomer)}
              onChange={(value: number | null) => {
                setAmountPayableToCustomer(String(value));
              }}
              controls={false}
              className="loan__input"
              placeholder="Enter amount payable to customer"
            />
            {Number(amountPayableToCustomer) > Number(maxAmount) && (
              <p className="error__message">
                Amount cannot be greater than {maxAmount}
              </p>
            )}
          </div>

          <Form.Item
            name="principal"
            rules={[{ required: true, message: 'Please enter principal!' }]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">Principal</p>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    principal: e.target.value,
                  });
                  setPrincipal(Number(e.target.value));
                  const totalPrincipal =
                    Number(additionalAmount) + Number(e.target.value);
                  const calculatedDocumentCharges =
                    customerType === 'direct' || customerType === 'repledge'
                      ? (Number(totalPrincipal) * 0.1) / 100
                      : (Number(totalPrincipal) * 1) / 100;
                  setDocumentCharges(
                    parseFloat(calculatedDocumentCharges.toFixed(3))
                  );
                  form.setFieldsValue({
                    documentCharge: parseFloat(
                      calculatedDocumentCharges.toFixed(3)
                    ),
                  });
                  setApprovalType('');

                  if (
                    Number(e.target.value) > Number(amountPayableToCustomer)
                  ) {
                    setInvalidAmount(true);
                  } else {
                    setInvalidAmount(false);
                  }
                }}
                className="loan__input"
                placeholder="Enter principal"
              />
              {invalidAmount && (
                <p className="error__message">Invalid amount</p>
              )}
            </div>
          </Form.Item>
          <Form.Item
            name="documentCharge"
            rules={[
              { required: true, message: 'Please enter document charges!' },
            ]}
          >
            <div className="loan__input__container">
              <p className="loan__input__label">
                {customerType === 'takeover'
                  ? 'Takeover Charges'
                  : 'Document Charges'}
              </p>
              <Input
                value={documentCharges}
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    documentCharge: e.target.value,
                  });
                  setDocumentCharges(Number(e.target.value));
                  const calculatedDocumentCharges =
                    customerType === 'direct' || customerType === 'repledge'
                      ? (Number(principal) * 0.1) / 100
                      : (Number(principal) * 1) / 100;
                  if (
                    customerType === 'direct' ||
                    customerType === 'repledge'
                  ) {
                    if (Number(e.target.value) < calculatedDocumentCharges) {
                      setApprovalType('admin');
                    }
                  }
                  if (customerType === 'takeover') {
                    if (
                      Number(e.target.value) <
                      calculatedDocumentCharges / 2
                    ) {
                      setApprovalType('admin');
                    } else if (
                      Number(e.target.value) >= calculatedDocumentCharges / 2 &&
                      Number(e.target.value) < calculatedDocumentCharges
                    ) {
                      setApprovalType('manager');
                    } else {
                      setApprovalType('');
                    }
                  }
                  // setTotal({
                  //   updatedItems: items,
                  //   documentCharges: e.target.value,
                  // });
                }}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="loan__input"
                placeholder="Enter document charges"
              />
              {approvalType === 'admin' && <p>Needs Admin approval</p>}
              {approvalType === 'manager' && <p>Needs Manager approval</p>}
            </div>
          </Form.Item>
          <Form.Item name="additionalAmount">
            <div className="loan__input__container">
              <p className="loan__input__label">Additional amount</p>
              <Input
                value={additionalAmount}
                type="number"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  form.setFieldsValue({
                    additionalAmount: e.target.value,
                  });
                  setAdditionalAmount(Number(e.target.value));

                  const totalPrincipal =
                    Number(principal) + Number(e.target.value);
                  const calculatedDocumentCharges =
                    customerType === 'direct' || customerType === 'repledge'
                      ? (Number(totalPrincipal) * 0.1) / 100
                      : (Number(totalPrincipal) * 1) / 100;
                  setDocumentCharges(
                    parseFloat(calculatedDocumentCharges.toFixed(3))
                  );
                  form.setFieldsValue({
                    documentCharge: parseFloat(
                      calculatedDocumentCharges.toFixed(3)
                    ),
                  });
                  setApprovalType('');
                }}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="loan__input"
                placeholder="Enter document charges"
              />
              {additionalAmount > 0 && <p>Needs Admin approval</p>}
            </div>
          </Form.Item>
        </div>

        <div className="loan__actions__section">
          <Button
            onClick={() => {
              form.resetFields();
              setPhoto('');
            }}
            className="loan__cancel__button"
          >
            Cancel
          </Button>
          <Button
            disabled={
              Number(amountPayableToCustomer) > Number(maxAmount) ||
              invalidAmount
            }
            htmlType="submit"
            className="loan__save__button"
          >
            <span>Create New Loan</span>
          </Button>
        </div>
      </div>
      <Modal
        width={1020}
        title={
          <div style={{ display: 'flex', flexDirection: 'row', gap: 24 }}>
            <span>Loan summary</span>
          </div>
        }
        open={isPrintModalOpen && printLoanDetails?.loanNumber}
        onCancel={handlePrintClose}
        footer={() => <></>}
      >
        <PrintableContent loanDetails={printLoanDetails} customer={customer} />
      </Modal>
    </Form>
  );
}
