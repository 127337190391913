import React, { useState, useEffect } from 'react';
import { Spin, Table, DatePicker, Select, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getTransactionsAction } from '../store/expenseActions';
import { type ExpenseResponse } from '../store/expenseTypes';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

export default function DailyTransactionsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { transactions }: any = useSelector<any>((state) => state?.expense);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dateFormat = 'YYYY-MM-DD';

  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (branch) {
      void getTransactions();
    }
  }, [branch]);

  const onChangeDate = (values: any, formatString: [string, string]) => {
    setStartDate(formatString[0]);
    setEndDate(formatString[1]);
  };

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    await getTransactionsAction(dispatch, {
      branch,
      startDate,
      endDate,
    });
    setIsLoading(false);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: ExpenseResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.description}</p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.amount}</p>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.type}</p>
      ),
    },
    {
      title: 'Transaction Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: ExpenseResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.date as string).format('DD-MM-YYYY')}</p>
      ),
    },
  ];

  return (
    <div className="daily_transactions__page">
      <Spin spinning={isLoading} fullscreen />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 14,
          alignItems: 'center',
        }}
      >
        <RangePicker
          className="rangepicker"
          onChange={onChangeDate}
          defaultValue={[dayjs(startDate), dayjs(endDate)]}
          format={dateFormat}
        />
        <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="expense__input"
        />
        <Button onClick={getTransactions} className="search__button">
          <span>Search</span>
        </Button>
      </div>
      {startDate !== '' && endDate !== '' && Boolean(transactions.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={transactions}
            columns={columns}
            rowKey={(record: ExpenseResponse) => record._id}
          />
        </div>
      )}
      {startDate !== '' && endDate !== '' && transactions.length === 0 && (
        <div style={{ width: '100%' }}>
          <p>No transactions available for the selected date</p>
        </div>
      )}
    </div>
  );
}
