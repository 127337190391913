import { createSlice } from '@reduxjs/toolkit';
import { getPledgeById, getPledgeTypes, getRenewalPledge, getUnclosedPledge } from './pledgeMiddleware';
import { type Metal, type BankScheme, type PledgeResponse } from './pledgeTypes';

interface InitialState {
  pledgeMetal: Metal[];
  bankScheme: BankScheme[];
  pledgeResponse: PledgeResponse;
  unclosedPledge: string[];
  renewalPledge: string[];
}

const initialState: InitialState = {
  unclosedPledge: [],
  renewalPledge: [],
  pledgeMetal: [{
            "metalName": "Gold",
            "purityQuality": [],
            "metalType": [
                "chain",
                "bangle"
            ],
            "minPrice": 0,
            "maxPrice": 0
        },
        {
            "metalName": "Silver",
            "purityQuality": [],
            "metalType": [
                "bracelet"
            ],
            "minPrice": 0,
            "maxPrice": 0
        }],
  bankScheme: [{
            "bankName": "TBF",
            "account_holder_name": [
                "Mani"
            ],
            "interestType": [
                {
                    "interest": 14.5,
                    "startDate": 1,
                    "endDate": 365
                },
                {
                    "interest": 16.0,
                    "startDate": 365,
                    "endDate": -1
                }
            ],
            "type": "SI",
            "location": "Karur",
            "totalMonths": 12
        },
        {
            "bankName": "SBI",
            "account_holder_name": [
                "Mani"
            ],
            "interestType": [
                {
                    "interest": 16.0,
                    "startDate": 1,
                    "endDate": 185
                },
                {
                    "interest": 16.0,
                    "startDate": 185,
                    "endDate": -1
                }
            ],
            "type": "SI",
            "location": "Karur",
            "totalMonths": 6
        }],
    pledgeResponse: {
      interestCalc: [],
        transactions: [],
        pledgeNumber: "",
        status: "", 
        pendingInterest: 0,
        principal: 0,
        startDate: "",
        branch: "",
        createdDate: "",
        photo: "",
        jewelryMetal: "",
        bank: "",
        jewels: [],
        remarks: "",
        documentCharge: 0,
        createdBy: "",
        remainingPrincipal: 0,
        totalPaidInterest: 0,
        dueDate: "",
        lastPaymentDate: "",
        totalNumberOfJewels: 0,
        loanNumber: [],
        obName: "",
        obNumber: "",
        goldPacketInBranch: false,
  }
  
};

const pledgeReducer = createSlice({
  name: 'pledgeReducer',
  reducers: {
    resetPledgeState(state) {
      state.pledgeResponse = initialState.pledgeResponse;
    },
    resetUnclosedPledge(state) {
      state.unclosedPledge = initialState.unclosedPledge;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getPledgeTypes.fulfilled, (state, action) => {
      // state.pledgeMetal = action.payload.metal;
      state.bankScheme = action.payload;
    });
    builder.addCase(getPledgeById.fulfilled, (state, action) => {
      state.pledgeResponse = action.payload;
    });
    builder.addCase(getUnclosedPledge.fulfilled, (state, action) => {
      state.unclosedPledge = action.payload;
    });
    builder.addCase(getRenewalPledge.fulfilled, (state, action) => {
      state.renewalPledge = action.payload;
    });
  },
});

export const { resetPledgeState, resetUnclosedPledge } = pledgeReducer.actions;
export default pledgeReducer.reducer;
