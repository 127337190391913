import { createSlice } from '@reduxjs/toolkit';
import { getInterestScheme, getBankInterestScheme, getBank } from './interestSchemeMiddleware';
import { type BankDetails, type LoanOption, type BankName } from './interestSchemeTypes';

interface SelectOption {
  label: string;
  value: string;
}

interface InitialState {
  interestSchemes: LoanOption[];
  bankInterestSchemes: BankDetails[];
  branches: SelectOption[];
  banks: BankName[];
}

const initialState: InitialState = {
  interestSchemes: [],
  bankInterestSchemes: [],
  branches: [],
  banks: []
};

const interestSchemeReducer = createSlice({
  name: 'interestSchemeReducer',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getInterestScheme.fulfilled, (state, action) => {
      state.interestSchemes = action.payload;
    });
    builder.addCase(getBankInterestScheme.fulfilled, (state, action) => {
      state.bankInterestSchemes = action.payload;
    });
    builder.addCase(getBank.fulfilled, (state, action) => {
      state.banks = action.payload;
    });
    // builder.addCase(getBranch.fulfilled, (state, action) => {
    //   const role = window.localStorage.getItem('roleName');
    //   const branch = window.localStorage.getItem('branches');
    //   if (role === 'Admin') {
    //     state.branches = action.payload.map((x: Branch) => {
    //     return {
    //       label: x.branch,
    //       value: x.branch,
    //     };
    //   });
    //   } else {
    //     state.branches = action.payload.filter((x: any) => { return x.branch === branch }).map((x: Branch) => {
    //     return {
    //       label: x.branch,
    //       value: x.branch,
    //     };
    //   });
    //   }
      
    // });
  },
});

export default interestSchemeReducer.reducer;
