const API = {
  // New APIs
  USER_LOGIN: '/users/login',
  USERS: '/users',
  BRANCHES: '/branches',
  INVESTMENTS: '/investments',
  DEPOSITS: '/deposits',
  EXPENSES: '/expenses',
  METAL: '/metals',
  BANK_INTEREST: '/bankInterestTypes',
  LOAN_INTEREST: '/loanInterestTypes',
  BANKS: '/banks',
  BANK_ACCOUNT_NAMES: '/bank-account-names',
  CUSTOMERS: '/customers',
  METALS: '/metals',
  LOANS: '/loans',
  LOAN_PAYMENTS: '/loan-payments',
  PLEDGES: '/pledges',
  PLEDGE_PAYMENTS: '/pledge-payments',
  REPORTS: '/reports',
  TRANSACTIONS: '/transactions',


  // Old APIs
  CUSTOMER: '/kgfcustomer/customer',
  LOGIN: '/kgfuser/login',
  DEPOSIT: '/kgfdeposit-expense/deposit',
  SEARCH_DEPOSIT: 'kgfdeposit-expense/searchdeposit',
  DEPOSIT_PAYMENT: 'kgfdeposit-expense/payment',
  EXPENSE: '/kgfdeposit-expense/expense',
  LOAN: '/kgfloan/loan',
  SEARCH_LOAN: 'kgfloan/searchloan',
  LOAN_PAYMENT: '/kgfloan/payment',
  PLEDGE: '/kgfpledge/pledge',
  SEARCH_PLEDGE: '/kgfpledge/search-pledge',
  CLOSE_PLEDGE: 'kgfpledge/close-pledge',
  PLEDGE_PAYMENT: '/kgfpledge/payment',
  INTEREST_SCHEME: '/kgfloan/interest',
  GET_BANK: '/kgfpledge/bankscheme',
  GET_BRANCH: '/kgfpledge/kgfbranch',
  BANK_SCHEME: '/kgfpledge/bank-scheme',
  CREATE_USER: '/kgfuser/create',
  USER: '/kgfuser/user',
  BANK_ACCOUNT: '/kgfpledge/bank-account',
  GOLD_PRICE: 'kgfloan/price',
  EXPENSE_TRANSACTION: 'kgfdeposit-expense/transaction',
  OUR_REPORT: 'kgfdeposit-expense/overview',
  INVESTMENT: 'kgfdeposit-expense/ownerdeposit',
  UNCLOSED_PLEDGE: 'kgfloan/unclosedPledge',
};

export default API;
