import React, { useEffect } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  getUnsettledPaymentsAction,
  updateUnsettledPaymentsAction,
} from '../../Loan/store/loanActions';

interface LoanPayment {
  loanNumber: string | undefined;
  cashier: string;
  transactionDate: string;
  description: string;
  notes?: string;
  branch: string;
  paymentType: string;
  _id: string;
  interestAmount: number;
  principal: number | undefined;
  OtherTransaction?: {
    Amount: number;
    Description: string;
  };
  paymentMethod?: string;
  customerId?: string;
  reducingInterest: number;
}

export default function LoanPaymentsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { unsettledPayments }: any = useSelector<any>((state) => state?.loan);

  const getLoanPayments = async (): Promise<void> => {
    await getUnsettledPaymentsAction(dispatch, {});
  };

  useEffect(() => {
    void getLoanPayments();
  }, []);

  const handleUpdate = async (loanPayment: LoanPayment, status: string) => {
    await updateUnsettledPaymentsAction(dispatch, {
      id: loanPayment._id,
      status,
    });
    void getLoanPayments();
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: LoanPayment, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.principal}</p>
      ),
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: LoanPayment, index: number) => (
        <p>{record.interestAmount}</p>
      ),
    },
    {
      title: 'Accept',
      dataIndex: 'actions',
      width: 200,
      render: (_: any, record: LoanPayment, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            void handleUpdate(record, 'cash_received');
          }}
        >
          Settle Payment
        </p>
      ),
    },
    {
      title: 'Reject',
      dataIndex: 'actions2',
      width: 200,
      render: (_: any, record: LoanPayment, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            void handleUpdate(record, 'rejected');
          }}
        >
          Reject payment
        </p>
      ),
    },
  ];

  return (
    <div className="users__table__page">
      {!(unsettledPayments.length > 0) && <Spin spinning={false} fullscreen />}
      <div style={{ width: '100%' }}>
        <Table dataSource={unsettledPayments} columns={columns} />
      </div>
    </div>
  );
}
