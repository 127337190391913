/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useState } from 'react';
import { Input, Button, Spin, DatePicker } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { type Loan, type Customer } from '../store/customerTypes';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { loanPaymentAction } from '../store/customerActions';
import type { DatePickerProps } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';

// const { TextArea } = Input;

interface Props {
  loanDetail?: Loan;
  customer?: Customer;
  closeModal: (
    receiptNumber: string,
    total: number,
    paymentType: string,
    interestDays: number,
    transactionDate: string
  ) => void;
  closeModalNOOP: () => void;
}

export default function LoanPaymentModal({
  loanDetail,
  customer,
  closeModal,
  closeModalNOOP,
}: Props): JSX.Element {
  // const [principal, setPrincipal] = useState<string>('');
  // const [interest, setInterest] = useState<string>('');
  // const [amountPaid, setAmountPaid] = useState<string>('');
  // const [partPayment, setPartPayment] = useState<string>('');
  const [interest, setInterest] = useState<string>('');
  // const [offlineInterest, setOfflineInterest] = useState<string>('');
  const [onlinePrincipal, setOnlinePrincipal] = useState<string>('0');
  // const [offlinePrincipal, setOfflinePrincipal] = useState<string>('');
  // const [cashier, setCashier] = useState<string>('');
  // const [notes, setNotes] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>(
    loanDetail?.pendingInterest === 0 ? 'full' : 'part'
  );
  // const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [isTransactionDate, setIsTransactionDate] = useState(false);

  const [reducingField, setReducingField] = useState<number>(0);

  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());

  const [finalInterestDays, setFinalInterestDays] = useState<any>(
    loanDetail?.interestDays
  );

  // const disabledDate = (current: Dayjs) => current < dayjs().startOf('day');

  // const disabledFutureDate = (current: Dayjs) =>
  //   current > dayjs().startOf('day');

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    // setDob(dateString);
    setDatePickerDate(date);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const tillDate = new Date(loanDetail?.tillDate);
    tillDate.setHours(0, 0, 0, 0);

    const currentDate = new Date(dateString);
    currentDate.setHours(0, 0, 0, 0);

    let daysDifference = Math.floor(
      (currentDate.getTime() - tillDate.getTime()) / (1000 * 60 * 60 * 24)
    );

    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!loanDetail?.fullPaymentTransactions) {
      daysDifference = daysDifference + 1;
    }

    setFinalInterestDays(daysDifference);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const makePledgePayment = async () => {
    setIsLoading(true);
    const response = await loanPaymentAction(dispatch, {
      loanNumber: loanDetail?.loanNumber,
      cashier: 'admin',
      transactionDate:
        (isTransactionDate || reducingField > 0) && datePickerDate != null
          ? datePickerDate?.toISOString()
          : dayjs().toISOString(),
      description,
      // Notes: notes,
      interestAmount: Number(interest),
      paymentType,
      reducingInterest: reducingField,
      principal: Number(onlinePrincipal),
      branch: loanDetail?.branch._id,
      interestDays: finalInterestDays,
      extra:
        Number(interest) + Number(reducingField) >
        Number(loanDetail?.pendingInterest)
          ? Number(interest) +
            Number(reducingField) -
            Number(loanDetail?.pendingInterest)
          : 0,
      // Interest: {
      //   Amount: Number(interest),
      //   PaymentType: paymentType,
      //   Online: Number(onlineInterest),
      //   offline: Number(offlineInterest),
      // },

      // PrincipalOnline: Number(onlinePrincipal),
      // PrincipalOffline: Number(offlinePrincipal),
      // OtherTransaction: { Amount: 0, Description: 'Document fee' },
      // paymentMethod,
      // CustomerID: customer?.customerID,
    });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.loanNumber) {
      closeModal(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        `${response.loanNumber}-${response._id.slice(0, 6)}`,
        Number(onlinePrincipal) + Number(interest),
        paymentType,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        finalInterestDays,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        response.transactionDate
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <div className="loan__payment__modal">
      <div className="divider" />
      <div className="payment__form">
        <div>
          <h4>Pending Interest ({loanDetail?.interestDays} days)</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={loanDetail?.pendingInterest}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   setPrincipal(e.target.value);
            // }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div>
        <div>
          <h4>Remaining Principal</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={loanDetail?.remainingPrincipal}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   setPrincipal(e.target.value);
            // }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div>
        {/* <div>
          <h4>Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={interest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInterest(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div> */}
        {/* <div>
          <h4>Part Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={partPayment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPartPayment(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div> */}
        {/* <div>
          <h4>Payment Type</h4>
          <Select
            disabled
            style={{ width: 280, height: 56 }}
            placeholder="Select payment type"
            value={paymentType}
            onChange={(value: string): void => {
              setPaymentType(value);
            }}
            options={[
              { label: 'full', value: 'full' },
              { label: 'partial', value: 'part' },
            ]}
            className="payment__select"
          />
        </div> */}

        {/* <div>
          <h4>Cashier</h4>
          <Input
            value={cashier}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCashier(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter cashier"
          />
        </div> */}

        {loanDetail != null && loanDetail.pendingInterest > 0 && (
          <>
            <div>
              <h4>Interest</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={interest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInterest(e.target.value);
                  if (
                    Number(e.target.value) + Number(reducingField) <
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('part');
                  }
                  if (
                    Number(e.target.value) + Number(reducingField) >=
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('full');
                  }
                  if (
                    Number(e.target.value) + Number(reducingField) >
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setIsTransactionDate(true);
                  } else {
                    setIsTransactionDate(false);
                  }
                }}
                className="payment__input"
                placeholder="Enter Online Interest"
              />
              {Number(interest) + Number(reducingField) >
                Number(loanDetail?.pendingInterest) && (
                <p>
                  Receiving an extra amount of{' '}
                  {Number(interest) +
                    Number(reducingField) -
                    Number(loanDetail?.pendingInterest)}{' '}
                  rupees
                </p>
              )}
            </div>
            {/* <div>
              <h4>Offline Interest</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={offlineInterest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOfflineInterest(e.target.value);
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) <
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('part');
                  }
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) ===
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('full');
                  }
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) >
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setIsTransactionDate(true);
                  } else {
                    setIsTransactionDate(false);
                  }
                }}
                className="payment__input"
                placeholder="Enter Offline Interest"
              />
            </div> */}
          </>
        )}

        {loanDetail != null && loanDetail.pendingInterest > 0 ? (
          <div>
            <h4>Reducing Field</h4>
            <Input
              type="number"
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              min={0}
              value={reducingField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setReducingField(Number(e.target.value));
                if (
                  Number(e.target.value) + Number(interest) <
                  Number(loanDetail?.pendingInterest)
                ) {
                  setPaymentType('part');
                }
                if (
                  Number(e.target.value) + Number(interest) >=
                  Number(loanDetail?.pendingInterest)
                ) {
                  setPaymentType('full');
                }
                if (
                  Number(e.target.value) + Number(interest) >
                  Number(loanDetail?.pendingInterest)
                ) {
                  setIsTransactionDate(true);
                } else {
                  setIsTransactionDate(false);
                }
              }}
              className="payment__input"
              placeholder="Enter amount"
            />
          </div>
        ) : (
          <></>
        )}
        {paymentType === 'full' && (
          <>
            <div>
              <h4>Principal</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={onlinePrincipal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOnlinePrincipal(e.target.value);
                }}
                className="payment__input"
                placeholder="Enter Principal"
              />
            </div>
            {/* <div>
              <h4>Offline Principal</h4>
              <Input
                type="number"
                disabled={isPrincipalDisabled}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={offlinePrincipal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOfflinePrincipal(e.target.value);
                }}
                className="payment__input"
                placeholder="Enter Offline Principal"
              />
            </div> */}
          </>
        )}
        {/* <div>
          <h4>Payment Method</h4>
          <Select
            style={{ width: 280, height: 56 }}
            placeholder="Select payment method"
            value={paymentMethod}
            onChange={(value: string): void => {
              setPaymentMethod(value);
            }}
            options={[
              { label: 'Cash', value: 'Cash' },
              { label: 'Online', value: 'Online' },
            ]}
            className="payment__select"
          />
        </div> */}

        {(isTransactionDate || reducingField > 0) && (
          <div className="loan__input__container">
            <h4>Start Date</h4>
            <DatePicker
              style={{ height: 56, width: 280 }}
              value={datePickerDate}
              disabledDate={(currentDate) =>
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                currentDate && currentDate.isBefore(loanDetail?.tillDate)
              }
              // disabledDate={
              //   reducingField > 0 ? disabledFutureDate : disabledDate
              // }
              className="loan__input"
              onChange={onChangeDate}
            />
          </div>
        )}
        <div>
          <h4>Description</h4>
          <Input
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
            className="payment__longinput"
            placeholder="Enter amount"
          />
        </div>
        {/* <div>
          <h4>Notes</h4>
          <TextArea
            value={notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setNotes(e.target.value);
            }}
            className="payment__textarea"
            placeholder="Enter amount"
          />
        </div> */}
      </div>
      <div className="payment__actions__section">
        <Button
          onClick={() => {
            closeModalNOOP();
          }}
          className="payment__cancel__button"
        >
          Cancel
        </Button>
        <Button
          disabled={
            Number(onlinePrincipal) > Number(loanDetail?.remainingPrincipal) ||
            (reducingField > 0 &&
              !(
                Number(loanDetail?.pendingInterest) ===
                Number(interest) + Number(reducingField)
              ))
          }
          onClick={makePledgePayment}
          className="payment__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}
