import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest, deleteRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const createUser = createAsyncThunk(
  'users/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.USERS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  'users/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.USERS, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  'users/delete',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await deleteRequest(`${API.USERS}/${payload}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);