import React, { useState, useEffect } from 'react';
import { Input, Select, Spin } from 'antd';
import OutlinedButton from '../../components/ButtonComponent/OutlinedButton';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { type AppDispatch, type RootState } from '../../redux/store';
import { getCustomerByIdAction } from './store/customerActions';
import SearchSvg from '../../assets/icons/Search.svg';
import TabComponent from '../../components/TabComponent';
import LoanDetailsIndex from './LoanDetails';
import CustomerFormIndex from './CustomerForm';

import { resetCustomerState } from './store/customerReducer';

export default function CustomerIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [searchParams] = useSearchParams();

  const [customerId, setCustomerId] = useState<string>(
    searchParams.get('customerId') ?? ''
  );

  const [loanNumber, setLoanNumber] = useState<string>(
    searchParams.get('loanNumber') ?? ''
  );

  useEffect(() => {
    void fetchCustomerById();
  }, [customerId]);

  useEffect(() => {
    setCustomerId(searchParams.get('customerId') ?? '');
    setLoanNumber(searchParams.get('loanNumber') ?? '');
  }, [searchParams]);

  const fetchCustomerById = async () => {
    if (customerId.length > 0) {
      await getCustomerByIdAction(dispatch, { customerId });
    } else if (loanNumber.length > 0) {
      await getCustomerByIdAction(dispatch, { loanNumber });
    } else {
      dispatch(resetCustomerState());
    }
  };

  const refetchCustomer = async (customerId: string) => {
    setCustomerId(customerId);
    await fetchCustomerById();
  };

  const tabOptions = [
    {
      to: '',
      label: 'Loan',
      index: 0,
    },
    {
      to: '',
      label: 'Profile',
      index: 1,
    },
  ];

  // const optionsMap: Record<string, string> = {
  //   customerId: 'Customer ID',
  //   phoneNumber: 'Phone number',
  //   aadhar: 'Aadhar number',
  //   // loanNumber: 'Loan number',
  //   drivingLicenseNumber: 'Driving License Number',
  // };

  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedType, setSelectedType] = useState<string>('customerId');
  const { customer }: any = useSelector<RootState>(
    (rootState) => rootState.customer
  );

  useEffect(() => {}, [customer]);

  const { loan }: any = useSelector<RootState>(
    (rootState) => rootState.customer
  );

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const onChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
    if (selectedType === 'customerId') {
      setSearchText(String(e.target.value).toUpperCase());
    }
  };

  const searchCustomer = async () => {
    setIsLoading(true);
    await getCustomerByIdAction(dispatch, { [selectedType]: searchText });
    setIsLoading(false);
  };

  const handleSearchType = (value: string): void => {
    setSelectedType(value);
  };

  return (
    <div className="customers__new__index__page">
      <Spin spinning={isLoading} fullscreen />
      <div className="title">
        <h1>Customers</h1>
        <div className="right">
          <div className="search__container">
            <Select
              style={{
                width: 151,
                height: 48,
                backgroundColor: '#F8FAFC',
                border: '12px 0px 0px 12px',
              }}
              onChange={handleSearchType}
              className="dropdown__select"
              value={selectedType}
              options={[
                { label: 'Customer ID', value: 'customerId' },
                { label: 'Phone Number', value: 'phoneNumber' },
                { label: 'Aadhar Number', value: 'aadhar' },
                { label: 'Loan Number', value: 'loanNumber' },
                { label: 'Drivers License', value: 'drivingLicenseNumber' },
              ]}
            />

            <Input
              placeholder={`Enter search text`}
              value={searchText}
              onChange={onChangeText}
              className="search__input"
              prefix={<img src={SearchSvg} className="search__icon" />}
            />
          </div>

          <OutlinedButton label={'Search'} onClick={searchCustomer} />
        </div>
      </div>

      {customer?.name === '' && (
        <div>
          <div className="divider" />
          <CustomerFormIndex />
        </div>
      )}
      {customer?.name !== '' && (
        <div className="">
          <div className="">
            <TabComponent
              taboptions={tabOptions}
              selectedTab={selectedIndex}
              setSelectedTab={setSelectedIndex}
            />
          </div>
          <div className="tab__container">
            {selectedIndex === 0 && (
              <LoanDetailsIndex
                refetchCustomer={refetchCustomer}
                loan={loan}
                customer={customer}
              />
            )}
            {selectedIndex === 1 && <CustomerFormIndex customer={customer} />}
          </div>
        </div>
      )}
    </div>
  );
}
