/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';
import dayjs from 'dayjs';
import { type Customer } from '../../Customer/store/customerTypes';
import { type Jewel, type Loan } from './printTypes';
// import KgfImg from '../../../assets/icons/kgflogo.svg';
import { numberToWords } from 'amount-to-words';

interface Props {
  loanDetails: Loan;
  customer: Customer;
}

const PrintableContent: React.FC<Props> = ({ loanDetails, customer }) => {
  const [selectedType, setSelectedType] = useState<string>('Office');
  const componentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const KgfImg = localStorage.getItem('organizationLogo') ?? undefined;
  const orgName = localStorage.getItem('organizationName') ?? '';

  const totals = {
    quantity: loanDetails.jewels.reduce(
      (acc, row: Jewel) => acc + row.quantity,
      0
    ),
    totalWeight: loanDetails.jewels.reduce(
      (acc, row: Jewel) => acc + row.grossWeight,
      0
    ),
    netWeight: loanDetails.jewels.reduce(
      (acc, row: Jewel) => acc + (row.grossWeight - row.unwantedWeight),
      0
    ),
  };

  // function groupJewels(jewels: Jewel[]) {
  //   const groupedJewels: Jewel[] = [];

  //   jewels.forEach((jewel: Jewel) => {
  //     const existing = groupedJewels.find(
  //       (item) =>
  //         item.itemType === jewel.itemType && item.purity === jewel.purity
  //     );

  //     // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  //     if (existing) {
  //       existing.quantity = Number(existing.quantity) + Number(jewel.quantity);
  //       existing.grossWeight =
  //         Number(existing.grossWeight) + Number(jewel.grossWeight);
  //       existing.unwantedWeight =
  //         Number(existing.unwantedWeight) + Number(jewel.unwantedWeight);
  //     } else {
  //       // If not found, add the jewel to the grouped list
  //       groupedJewels.push({ ...jewel });
  //     }
  //   });

  //   return groupedJewels;
  // }

  return (
    <div>
      <Button
        onClick={() => {
          selectedType === 'Office'
            ? handlePrint('')
            : setSelectedType('Office');
        }}
        className="print__button"
      >
        <span>{selectedType === 'Office' ? 'Print' : 'View'} Office copy</span>
      </Button>
      <Button
        onClick={() => {
          selectedType === 'Customer'
            ? handlePrint('')
            : setSelectedType('Customer');
        }}
        className="print__button"
      >
        <span>
          {selectedType === 'Customer' ? 'Print' : 'View'} Customer copy
        </span>
      </Button>
      <div ref={componentRef}>
        <div style={{ textAlign: 'center' }}>
          <h4>{selectedType} Copy</h4>
        </div>
        <div className="loan__copy">
          <div className="details">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <img
                style={{ objectFit: 'contain', height: 120, width: 120 }}
                src={KgfImg}
              />
              <div className="company__details">
                <h3 style={{ margin: 0 }}>{orgName}</h3>
                <p style={{ margin: 0, padding: 0 }}>
                  {loanDetails.branch?.name}
                </p>
                <p style={{ margin: 0, padding: 0, textAlign: 'left' }}>
                  {loanDetails.branch?.address}
                </p>
                <p>
                  Contact No: {loanDetails.branch?.phoneNumber1},{' '}
                  {loanDetails.branch?.phoneNumber2}
                </p>
              </div>
            </div>
            <div className="customer__details">
              <h3 style={{ margin: 0 }}>Customer Info</h3>
              <p>Name: {customer.name}</p>
              <p>S/O {customer.fatherName}</p>
              <p>Address: {customer.address.replace(/,([^ ])/g, ', $1')}</p>
              <p>Pincode: {customer.pincode} </p>
              <p>Contact: {customer.phoneNumber}</p>
            </div>
          </div>
          <div className="loan__summary">
            <div className="loan__header">
              <p>CUSTOMER ID: {customer.customerId}</p>
              <p>AADHAR NO: {customer.aadhar}</p>
            </div>
            <div className="loan__summary__detail">
              <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                <thead style={{ borderCollapse: 'collapse' }}>
                  <tr>
                    <th
                      style={{
                        borderCollapse: 'collapse',
                        border: '1px solid black',
                        borderLeft: 'none',
                        padding: '8px',
                      }}
                    >
                      LOAN DATE
                    </th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>
                      LOAN NO
                    </th>
                    <th style={{ border: '1px solid black', padding: '8px' }}>
                      LOAN AMOUNT
                    </th>
                    <th
                      style={{
                        border: '1px solid black',
                        padding: '8px',
                        borderRight: 'none',
                      }}
                    >
                      SCHEME
                    </th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: 'center' }}>
                  <tr>
                    <td
                      style={{ padding: '8px', borderRight: '1px solid black' }}
                    >
                      {dayjs(loanDetails.startDate).format('DD-MM-YYYY')}
                    </td>
                    <td
                      style={{ padding: '8px', borderRight: '1px solid black' }}
                    >
                      {loanDetails.loanNumber}
                    </td>
                    <td
                      style={{ padding: '8px', borderRight: '1px solid black' }}
                    >
                      {loanDetails.principal}
                    </td>
                    <td style={{ padding: '8px' }}>
                      {loanDetails.interestType}{' '}
                      {loanDetails.interestTypePercentage}%
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="item__details">
            <div className="item__header">
              <p>
                In Words ({numberToWords(loanDetails.principal)} Rupees Only)
              </p>
            </div>
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead>
                <tr>
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Jewel Details
                  </th>
                  {selectedType === 'Office' && (
                    <th style={{ border: '1px solid black', padding: '8px' }}>
                      Quality
                    </th>
                  )}
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Quantity
                  </th>
                  {selectedType === 'Office' && (
                    <th style={{ border: '1px solid black', padding: '8px' }}>
                      Total Weight
                    </th>
                  )}
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Net Weight
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }}>
                {loanDetails.jewels.map((row: Jewel, index: number) => (
                  <tr key={index}>
                    <td style={{ padding: '8px', border: '1px solid black' }}>
                      {row.itemType}
                    </td>
                    {selectedType === 'Office' && (
                      <td style={{ padding: '8px', border: '1px solid black' }}>
                        {row.purity}
                      </td>
                    )}
                    <td style={{ padding: '8px', border: '1px solid black' }}>
                      {row.quantity}
                    </td>
                    {selectedType === 'Office' && (
                      <td style={{ padding: '8px', border: '1px solid black' }}>
                        {row.grossWeight.toFixed(2)}
                      </td>
                    )}
                    <td style={{ padding: '8px', border: '1px solid black' }}>
                      {(row.grossWeight - row.unwantedWeight).toFixed(2)}
                    </td>
                  </tr>
                ))}
                <tr>
                  {selectedType === 'Office' && <td></td>}
                  <td style={{ padding: '8px', border: '1px solid black' }}>
                    Total
                  </td>
                  <td style={{ padding: '8px', border: '1px solid black' }}>
                    {totals.quantity}
                  </td>
                  {selectedType === 'Office' && (
                    <td style={{ padding: '8px', border: '1px solid black' }}>
                      {totals.totalWeight.toFixed(2)}
                    </td>
                  )}
                  <td style={{ padding: '8px', border: '1px solid black' }}>
                    {totals.netWeight.toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="guidelines" style={{ fontSize: 16 }}>
            <ul style={{ listStyle: 'none' }}>
              <li>
                1. மாதந்தோறும் வட்டி செலுத்த வேண்டும். தவறும் பட்சத்தில் வட்டி
                விகிதம் மாறிவிடும்.
              </li>
              <li>
                2. நகையை திருப்பும் பட்சத்தில் முழு தொகையை 1.00 மணிக்குள்
                செலுத்தி பிற்பகல் 3.00 மணிக்கு மேல் நகையை திரும்ப பெற்றுக் கொள்ள
                சம்மதிக்கிறேன்.
              </li>
              <li>
                3. நான் அடகு வைக்கும் நகைகள் அனைத்தும் எனக்கு சொந்தமானது என்றும்
                கையாடல், போலி நகைகள் இல்லை எனவும் உறுதியளிக்கிறேன்.
              </li>
            </ul>
          </div>
          <div className="photo__details">
            <table style={{ borderCollapse: 'collapse', width: '100%' }}>
              <thead style={{ borderCollapse: 'collapse' }}>
                <tr>
                  <th
                    style={{
                      borderCollapse: 'collapse',
                      border: '1px solid black',
                      padding: '8px',
                    }}
                  >
                    Customer Photo
                  </th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Jewel Photo
                  </th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Loan Renewal Date
                  </th>
                  <th style={{ border: '1px solid black', padding: '8px' }}>
                    Customer Sign
                  </th>
                </tr>
              </thead>
              <tbody style={{ textAlign: 'center' }}>
                <tr>
                  <td
                    style={{
                      padding: '8px',
                      height: '150px',
                      border: '1px solid black',
                    }}
                  >
                    <img
                      src={customer.photo}
                      style={{ width: 130, height: 130, objectFit: 'contain' }}
                    />
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      height: '150px',
                      border: '1px solid black',
                    }}
                  >
                    <img
                      src={loanDetails.photo}
                      style={{ width: 130, height: 130, objectFit: 'contain' }}
                    />
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      height: '150px',
                      border: '1px solid black',
                    }}
                  >
                    {/* {dayjs(loanDetails.startDate).format('DD-MM-YYYY')} */}
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      height: '150px',
                      border: '1px solid black',
                    }}
                  ></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="signature__section">
            <h6>MANAGER</h6>
            <h6>JEWEL VALIDATOR</h6>
            <h6>CASHIER</h6>
            <h6>JEWELLERY CHECKED</h6>
          </div>
        </div>
        {/* <div style={{ padding: 64, fontSize: 18 }}>
          <h5>விதிமுறைகள் மற்றும் நிபந்தனைகள்</h5>
          <p>
            1. நீங்கள் ஈடாக வைத்திருக்கும் பொருளுக்கு கூடுதல் கடன் மதிப்பீட்டை
            வைத்து உங்கள் வட்டி நிர்ணயிக்கப்படுகிறது.
          </p>
          <p>
            2. அடகு வைத்த தேதியிலிருந்து மாதத்திற்க்குள் நகைகளை திருப்பவோ அல்லது
            மாற்றி புதுப்பித்து கொள்ளவோ தவறும் பட்சத்தில் அடுத்து வரும்
            நாட்களில் உங்கள் நகைகளை பகிரங்க ஏலத்தில் விடப்படும்.
          </p>
          <p>
            3. அடகு கடன் பெற்றவர்களுக்கு அனுப்பப்படும் தபால்கள், இதர செலவுகள்
            அனைத்தும் தங்களின் கடன் கணக்கிலேயே சேரும்.
          </p>
          <p>
            4. நான் கடனாக வாங்கிய தொகை நேர்மையான தேவைக்கு மட்டுமே
            பயன்படுத்துவேன் அல்லாமல் நேரடியாகவோ, மறைமுகமாகவோ சட்டவிரோத
            செயல்களுக்கு இந்த பணம் பயன்படுத்த மாட்டேன் என நிச்சயமாக உறுதி
            கூறுகிறேன்.
          </p>
          <p>
            5. நான் கடன் பெற்ற இந்த நாள் வரை என் மீது எந்த ஒரு திவாலா
            நோட்டீசும், கடன் தீர்க்க வகையற்றவர் என்ற புகாரும், இது தொடர்பாக எந்த
            ஒரு நீதிமன்ற நடவடிக்கையும் இது நாள் வரை என் மீது இல்லை என்று உறுதி
            கூறுகிறேன்.
          </p>
          <p>
            6. நகைக்கடன் சம்பந்தமாக அதன் விதிமுறைகளைக் கூட்டவோ, மாற்றவோ, நீக்கவோ
            நிறுவனத்திற்கு முழு உரிமை உண்டு.
          </p>

          <p>
            7. அலுவலக நாட்களில் நகையை திருப்ப திங்கள் முதல் வெள்ளி வரை மதியம்
            1.00 மணிக்குள் பணம் செலுத்தி பிற்பகல் 3.00 மணிக்கு மேல் அடகு நகையை
            திரும்ப பெற்றுக் கொள்ள சம்மதிக்கிறேன். சனி, ஞாயிறு மற்றும் அரசு,
            உள்ளூர் விடுமுறை நாட்களிலும் நகை திருப்பித்தர இயலாது.
          </p>
          <p>
            8. தொகைக்கு நிகரான தங்க நகைகள் அடகு வைத்த தொகை வாங்குவதிலும் அல்லது
            எழுத்து வடிவில் ஈடு செயயவும் நிறுவனத்திற்கு அதிகாரப்படுத்துகிறேன்.
          </p>
          <p>
            9. நான் அடமானமாக வைக்க தங்கள் நிறுவனத்திற்கு கொண்டு வந்து இருக்கும்
            நகைகள் அனைத்தும் என்னுடைய சொந்த நகைகள் தான் என்பதை உறுதியுடன்
            தெரிவித்துக் கொள்கிறேன்.
          </p>
          <p>
            10. தங்களது முகவரி மற்றும் அலைபேசி எதுவும் மாற்றம் இருந்தால் அதை
            உடனடியாக நிர்வாகத்திற்கு தெரியப்படுத்த வேண்டும் தவறும் பட்சத்தில்
            நிர்வாகம் பொறுப்பேற்காது.
          </p>
        </div>
        <b style={{ marginTop: 20, padding: 64 }}>பணம் பெறுவோரின் கையொப்பம்.</b> */}
      </div>
    </div>
  );
};

export default PrintableContent;
