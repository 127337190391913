import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  type DatePickerProps,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { updateDepositAction } from '../store/depositActions';
import { type Deposit } from '../store/depositTypes';
import { type Dayjs } from 'dayjs';

export default function DepositIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const { deposit }: any = useSelector<any>((state) => state?.deposit);

  // const [onlineAmount, setOnlineAmount] = useState<number>(0);
  // const [offlineAmount, setOfflineAmount] = useState<number>(0);
  // const [investedBy, setInvestedBy] = useState<string>('');

  const [form] = Form.useForm();
  const [interest, setInterest] = useState('15');

  form.setFieldsValue({ branch, interest: '15' });

  // const getDepositValues = async (): Promise<void> => {
  //   await getDepositAction(dispatch, { branch: 'Krr' });
  // };

  // useEffect(() => {
  //   void getDepositValues();
  // }, []);

  const onFinish = async (values: Deposit) => {
    setIsLoading(true);
    const action = updateDepositAction;
    const response = await action(dispatch, {
      ...values,
      branch,
      interest,
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
      setDatePickerDate(null);
      setInterest('15');
      setBranch(branchOptions[0].value);
      form.setFieldsValue({
        interest: '15',
        branch: branchOptions[0].value,
      });

      // setOfflineAmount(0);
      // setOnlineAmount(0);
      // setInvestedBy('');
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(null);

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    form.setFieldsValue({ investedDate: dateString });
    // setDob(dateString);
    setDatePickerDate(date);
  };

  return (
    <div className="deposit__page">
      <h1>Deposit Form</h1>
      <Form
        form={form}
        name="deposit_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="deposit__form">
          <Form.Item
            name="branch"
            rules={[{ required: true, message: 'Please select branch!' }]}
          >
            <div className="customers__input__container">
              <h4>Branch</h4>
              <Select
                value={branch}
                onChange={(value: string): void => {
                  form.setFieldsValue({ branch: value });
                  setBranch(value);
                }}
                style={{ width: 311 }}
                placeholder="Select branch"
                options={branchOptions}
                className="deposit__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please enter amount' }]}
          >
            <div>
              <h4>Amount</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="deposit__input"
                placeholder="Enter amount"
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="offlineAmount"
            rules={[{ required: true, message: 'Please enter offline amount' }]}
          >
            <div>
              <h4>Offline amount</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="deposit__input"
                placeholder="Enter offline amount"
              />
            </div>
          </Form.Item> */}
          <Form.Item
            name="interest"
            rules={[{ required: true, message: 'Please select interest' }]}
          >
            <div>
              <h4>Interest</h4>
              <Select
                style={{ width: 311 }}
                defaultValue={interest}
                placeholder="Select interest"
                options={[
                  { value: '15', label: '15%' },
                  { value: '18', label: '18%' },
                ]}
                onChange={(value: string): void => {
                  form.setFieldsValue({ interest: value });
                  setInterest(value);
                }}
                className="deposit__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="investedBy"
            rules={[{ required: true, message: 'Please enter name!' }]}
          >
            <div>
              <h4>Invested By</h4>
              <Input className="deposit__input" placeholder="Invested By" />
            </div>
          </Form.Item>
          <Form.Item
            name="nominee"
            rules={[{ required: true, message: 'Please enter nominee' }]}
          >
            <div>
              <h4>Nominee</h4>
              <Input className="deposit__input" placeholder="Enter nominee" />
            </div>
          </Form.Item>
          <Form.Item
            name="investedDate"
            rules={[{ required: true, message: 'Please select invested date' }]}
          >
            <div>
              <h4>Invested Date</h4>
              <DatePicker
                value={datePickerDate}
                onChange={onChangeDate}
                className="deposit__input"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="phoneNumber1"
            rules={[{ required: true, message: 'Please enter phone number!' }]}
          >
            <div>
              <h4>Phone number 1</h4>
              <Input
                maxLength={10}
                className="deposit__input"
                placeholder="Phonenumber 1"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="phoneNumber2"
            rules={[{ required: true, message: 'Please enter phone number!' }]}
          >
            <div>
              <h4>Phone number 2</h4>
              <Input
                maxLength={10}
                className="deposit__input"
                placeholder="Phonenumber 2"
              />
            </div>
          </Form.Item>
        </div>

        <Button htmlType="submit" className="deposit__save__button">
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Add Deposit</span>
          )}
        </Button>
      </Form>
    </div>
  );
}
