import React, { useState } from 'react';
import TabComponent from '../../components/TabComponent';
import InvestmentTableIndex from './InvestmentTable';
import InvestmentIndex from './Investment';

export const InvestmentFormIndex = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabOptions = [
    {
      to: '',
      label: 'Investment Form',
      index: 0,
    },
    {
      to: '',
      label: 'Investment Table',
      index: 1,
    },
  ];

  return (
    <div className="transactions__page">
      <h3>Investment</h3>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 1 && <InvestmentTableIndex />}
        {selectedIndex === 0 && <InvestmentIndex />}
      </div>
    </div>
  );
};
