/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react';
import { Table, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import PlusFilled from '../../../assets/icons/plus-filled.svg';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { splitJewelAction } from '../store/pledgeActions';

interface Props {
  selectedJewel: any;
  closeModal: () => void;
}

interface LoanItem {
  key: number;
  itemName: string;
  quantity: string;
  grossWt: string;
  stoneWt: string;
  netWt: string;
  purity: string;
  finalAmt: number;
  disabled?: boolean;
}

export default function JewelList({
  selectedJewel,
  closeModal,
}: Props): JSX.Element {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getIsDisabled = () => {
    const totalQuantity = items.reduce(
      (acc, curr) => acc + Number(curr.quantity || 0),
      0
    );
    const totalGrossWeight = items.reduce(
      (acc, curr) => acc + Number(curr.grossWt || 0),
      0
    );
    const totalNetWeight = items.reduce(
      (acc, curr) => acc + Number(curr.stoneWt || 0),
      0
    );

    return !(
      totalQuantity === Number(selectedJewel.quantity) &&
      totalGrossWeight === Number(selectedJewel.grossWeight) &&
      totalNetWeight === Number(selectedJewel.unwantedWeight)
    );
  };

  const dispatch = useDispatch<AppDispatch>();

  const splitJewels = async () => {
    // setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const response = await splitJewelAction(dispatch, {
      loanNumber: selectedJewel.loanNumber,
      jewelId: selectedJewel._id,
      jewels: items,
    });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response) {
      setIsLoading(false);
      closeModal();
    }
  };

  const [items, updateItems] = useState<LoanItem[]>([
    {
      key: 1,
      itemName: selectedJewel.itemType,
      quantity: '',
      grossWt: '',
      stoneWt: '',
      netWt: '',
      purity: selectedJewel.purity,
      finalAmt: 0,
    },
  ]);
  const [count, setCount] = useState<number>(1);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: LoanItem, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width: 120,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          value={record.itemName}
          disabled
          style={{ width: 150 }}
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      width: 90,
      render: (_: any, record: LoanItem) => (
        <Input
          type="number"
          className="loan__item__input"
          value={record.quantity}
          onChange={(e) => {
            handleFieldChange(e, record, 'quantity');
          }}
        />
      ),
    },
    {
      title: 'Gross Wt.',
      dataIndex: 'grossWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          type="number"
          className="loan__item__input"
          value={record.grossWt}
          onChange={(e) => {
            handleFieldChange(e, record, 'grossWt');
          }}
        />
      ),
    },
    {
      title: 'Stone Wt.',
      dataIndex: 'stoneWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          type="number"
          className="loan__item__input"
          value={record.stoneWt}
          onChange={(e) => {
            handleFieldChange(e, record, 'stoneWt');
          }}
        />
      ),
    },
    {
      title: 'Net Wt.',
      dataIndex: 'netWt',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <Input
          type="number"
          className="loan__item__input"
          value={(Number(record.grossWt) - Number(record.stoneWt)).toFixed(2)}
          disabled
        />
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      width: 120,
      render: (_: any, record: LoanItem) => (
        <Input
          className="loan__item__input"
          value={record.purity}
          style={{ width: 120, height: 56 }}
          disabled
        />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: LoanItem) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            handleDelete(record);
          }}
        >
          Delete
        </p>
      ),
    },
  ];

  const totalColumns = [
    {
      title: 'Total Qty',
      dataIndex: 'totalQty',
      width: 90,
      render: () => (
        <p>
          {items.reduce((acc, curr) => acc + Number(curr.quantity || 0), 0)} (
          {selectedJewel.quantity})
        </p>
      ),
    },
    {
      title: 'Total Gross Wt.',
      dataIndex: 'totalGrossWt',
      width: 100,
      render: () => (
        <p>
          {items.reduce((acc, curr) => acc + Number(curr.grossWt || 0), 0)} (
          {selectedJewel.grossWeight})
        </p>
      ),
    },
    {
      title: 'Total Stone Wt.',
      dataIndex: 'totalStoneWt',
      width: 100,
      render: () => (
        <p>
          {items.reduce((acc, curr) => acc + Number(curr.stoneWt || 0), 0)} (
          {selectedJewel.unwantedWeight})
        </p>
      ),
    },
    {
      title: 'Total Net Wt.',
      dataIndex: 'totalNetWt',
      width: 100,
      render: () => (
        <p>
          {items.reduce(
            (acc, curr) =>
              acc + (Number(curr.grossWt || 0) - Number(curr.stoneWt || 0)),
            0
          )}{' '}
          ({selectedJewel.netWeight})
        </p>
      ),
    },
  ];

  const handleFieldChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    record: LoanItem,
    fieldName: keyof LoanItem
  ) => {
    const newData = [...items] as LoanItem[];
    const index = newData.findIndex((item) => record.key === item.key);

    if (index !== -1) {
      (newData[index] as any)[fieldName] = e.target.value;
      updateItems(newData);
    }
  };

  const handleDelete = (record: LoanItem) => {
    const newData = items.filter((item) => item.key !== record.key);
    updateItems(newData);
  };

  const handleAddNewItem = () => {
    const newItem: LoanItem = {
      key: count + 1,
      itemName: selectedJewel.itemType,
      quantity: '',
      grossWt: '',
      stoneWt: '',
      netWt: '',
      purity: selectedJewel.purity,
      finalAmt: 0,
    };
    setCount(count + 1);
    updateItems([...items, newItem]);
  };

  return (
    <div className="split__items__section">
      <Table
        className="loan__items__table"
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey="key"
      />
      <div className="add__item__button" style={{ marginTop: 16 }}>
        <img
          className="plus__filled__icon"
          src={PlusFilled}
          onClick={handleAddNewItem}
        />
        <p className="add__new__item__text" onClick={handleAddNewItem}>
          Add New Item
        </p>
      </div>
      <Table
        style={{ marginTop: 32 }}
        dataSource={[{ key: 'total' }]}
        columns={totalColumns}
        pagination={false}
        rowKey="key"
      />
      <div className="split__actions__section">
        <Button
          disabled={getIsDisabled()}
          onClick={splitJewels}
          className="loan__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}
