import { createSlice } from '@reduxjs/toolkit';
import { getCustomerById } from './customerMiddleware';
import { type Customer, type LoanDetails } from './customerTypes';

interface InitialState {
  loading: boolean;
  customers: Customer[];
  customer: Customer;
  loan: LoanDetails;
}

const initialState: InitialState = {
  loading: false,
  customers: [],
  customer: {
    name: '',
    dob: new Date(),
    aadhar: '',
    nominee: '',
    drivingLicenseNumber: '',
    address: '',
    branch: '',
    phoneNumber: '',
    alternatePhoneNumber: '',
    photo: '',
    mail: '',
    permanentAddress: '',
    proofPhoto: '',
    area: '',
    pincode: '',
    fatherName: ''
  },
  loan: {
    activeLoan: [],
    closedLoan: [],
    cashPending: [],
    approvals: [],
    rejected: []
  }
};

const customerReducer = createSlice({
  name: 'counter',
  reducers: {
    resetCustomerState(state) {
      state.customer = initialState.customer;
      state.loan = initialState.loan;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCustomerById.fulfilled, (state, action) => {
      state.customer = action.payload;
      
      state.loan.activeLoan = action.payload.loans.filter((x: any) => x.status === 'active');
      state.loan.closedLoan = action.payload.loans.filter((x: any) => x.status === 'closed');
      state.loan.cashPending = action.payload.loans.filter((x: any) => x.status === 'cash_disbursement');
      state.loan.approvals = action.payload.loans.filter((x: any) => (x.status === 'admin_approval_pending') || (x.status === 'manager_approval_pending'));
      state.loan.rejected = action.payload.loans.filter((x: any) => x.status === 'rejected');
    });
  },
});

export const { resetCustomerState } = customerReducer.actions;
export default customerReducer.reducer;
