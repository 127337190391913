/* eslint-disable @typescript-eslint/no-unsafe-argument */
import { DatePicker, Spin, Button, Card, Row, Col } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOurReportAction,
  getDashboardAction,
} from '../store/overviewActions';
import { type AppDispatch } from '../../../redux/store';
import { type ourReport } from '../store/overviewTypes';
import fmt from 'indian-number-format';
import {
  BarChartOutlined,
  DollarOutlined,
  GoldOutlined,
  BankOutlined,
} from '@ant-design/icons';

const { RangePicker } = DatePicker;

export default function OurReport(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dateFormat = 'YYYY-MM-DD';

  const [dataFetched, setDataFetched] = useState<boolean>(false);

  const { ourReport, dashboard }: any = useSelector<any>(
    (state) => state.overview
  );

  const [startDate, setStartDate] = useState<string>(
    dayjs().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  // const [branchOptions, setBranchOptions] = useState<
  //   Array<{
  //     label: string;
  //     value: string;
  //   }>
  // >([]);

  // const [branch, setBranch] = useState<any>('');

  // useEffect(() => {
  //   const branches = localStorage.getItem('branches');
  //   if (branches != null) {
  //     const parsedBranches = JSON.parse(branches);
  //     const branchOptionTemp: Array<{ label: string; value: string }> =
  //       parsedBranches.map((branch: { code: string; id: string }) => ({
  //         label: branch.code,
  //         value: branch.id,
  //       }));
  //     setBranchOptions(branchOptionTemp);
  //     setBranch(parsedBranches[0].id);
  //   }
  // }, []);

  const onChangeDate = (values: any, formatString: [string, string]) => {
    setStartDate(formatString[0]);
    setEndDate(formatString[1]);
  };

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    await getOurReportAction(dispatch, {
      startDate,
      endDate,
    });
    setIsLoading(false);
    setDataFetched(true);
  };

  const getDashboard = async (): Promise<void> => {
    setIsLoading(true);
    await getDashboardAction(dispatch, {
      branch: selectedBranch,
      startDate,
      endDate,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    void getTransactions();
  }, []);

  const [items, setItems] = useState<any[]>([]);
  const [todayItems, setTodayItems] = useState<any[]>([]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (Object.keys(dashboard).length > 0) {
      setItems([
        {
          label: 'Loans Disbursed',
          value: dashboard.loansDisbursedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Pledges',
          value: dashboard.loansPledgedToday,
          icon: <GoldOutlined />,
        },
        {
          label: 'RePledges',
          value: dashboard.repledgesToday,
          icon: <GoldOutlined />,
        },
        {
          label: 'Lock Amount',
          value: dashboard.lockAmountToday,
          icon: <BankOutlined />,
        },
        {
          label: 'Loans Repledged',
          value: dashboard.loansRepledgedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Loans Released',
          value: dashboard.loansReleasedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Net Loan growth',
          value: dashboard.netLoanToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Loan Gold',
          value: `${dashboard.todayLoanGold}g`,
          icon: <GoldOutlined />,
        },
        {
          label: 'Pledge Gold',
          value: `${dashboard.todayPledgeGold}g`,
          icon: <GoldOutlined />,
        },
        {
          label: 'Expenses Today',
          value: dashboard.expenseToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Part Interest',
          value: dashboard.partInterestToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Full Interest',
          value: dashboard.fullInterestToday,
          icon: <DollarOutlined />,
        },

        {
          label: 'Doc Charges Received',
          value: dashboard.documentChargesReceivedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Doc Charges Paid',
          value: dashboard.documentChargesPaidToday,
          icon: <DollarOutlined />,
        },
      ]);
      setTodayItems([
        {
          label: 'Amount In Hand',
          value: dashboard.amountInHand,
          icon: <DollarOutlined />,
        },
        {
          label: 'Loans Disbursed',
          value: dashboard.newLoansDisbursedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Repledged Loans Disbursed',
          value: dashboard.newRepledgedLoansDisbursedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'New Pledges',
          value: dashboard.newLoansPledgedToday,
          icon: <GoldOutlined />,
        },
        {
          label: 'Repledges',
          value: dashboard.newRePledgesToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Loan Gold',
          value: `${dashboard.newTodayLoanGold}g`,
          icon: <GoldOutlined />,
        },
        {
          label: 'Pledge Gold',
          value: `${dashboard.newTodayPledgeGold}g`,
          icon: <GoldOutlined />,
        },
        {
          label: 'Part Interest',
          value: dashboard.newPartInterestToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Full Interest',
          value: dashboard.newFullInterestToday,
          icon: <DollarOutlined />,
        },

        {
          label: 'Doc Charges Received',
          value: dashboard.newDocumentChargesReceivedToday,
          icon: <DollarOutlined />,
        },
        {
          label: 'Doc Charges Paid',
          value: dashboard.newDocumentChargesPaidToday,
          icon: <DollarOutlined />,
        },
      ]);
    }
  }, [dashboard]);

  const [selectedBranch, setSelectedBranch] = useState<string>('');
  const handleViewDashboard = (branchId: string) => {
    setSelectedBranch(branchId);
  };

  useEffect(() => {
    void getDashboard();
  }, [selectedBranch]);

  return (
    <div className="our_report__page">
      <Spin spinning={isLoading} fullscreen />
      {selectedBranch === '' && (
        <div className="our_report__search__part">
          <RangePicker
            className="rangepicker"
            onChange={onChangeDate}
            defaultValue={[dayjs(startDate), dayjs(endDate)]}
            format={dateFormat}
          />
          {/* <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="report__input"
        /> */}
          <Button
            onClick={async () => {
              await getTransactions();
            }}
            className="report__button"
          >
            <span>GetReport</span>
          </Button>
        </div>
      )}
      {dataFetched && selectedBranch === '' && (
        <div className="all-interest">
          {ourReport.map((x: ourReport) => {
            return (
              <div key={x.branch} className="container">
                <div className="interest">
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 16,
                    }}
                  >
                    <p style={{ fontSize: 16, textDecoration: 'underline' }}>
                      {x.branch}
                    </p>
                    <BarChartOutlined
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleViewDashboard(x.branchId);
                      }}
                    />
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Active Loans:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.activeLoans)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Active Loans Count:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.activeLoansCount)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Loan Weight:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(parseFloat(Number(x.loanGold).toFixed(2)))}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Closed Loans:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {x.closedLoans}
                    </p>
                  </div> */}
                  {/* <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Closed Loans Count:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {x.closedLoansCount}
                    </p>
                  </div> */}
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Active Pledges:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.activePledges)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Active Pledges Count:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.activePledgesCount)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Pledge Weight:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(parseFloat(Number(x.pledgeGold).toFixed(2)))}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Closed Pledges:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {x.closedPledges}
                    </p>
                  </div> */}
                  {/* <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Closed Pledges Count:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {x.closedPledgesCount}
                    </p>
                  </div> */}
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Deposit Amount:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.depositAmount)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Expense Amount:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.expenseAmount)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Invested Amount:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.investedAmount)}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Loan Interest Received:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.loanInterestReceived)}{' '}
                      {`(${x.loanInterestCount})`}
                    </p>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Pledge Interest Given:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {fmt.format(x.pledgeInterestGiven)}
                    </p>
                  </div>
                  {/* <div
                    style={{
                      fontWeight: 700,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 16,
                    }}
                  >
                    Total Amount:
                    <p style={{ fontSize: 14, color: '#DD1E26' }}>
                      {x.totalAmount}
                    </p>
                  </div> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {selectedBranch !== '' && (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 32 }}>
          <Button
            style={{ width: 200 }}
            onClick={async () => {
              setSelectedBranch('');
            }}
            className="report__button"
          >
            <span>Back to reports</span>
          </Button>
          <Row gutter={[16, 16]}>
            {items.map((item: any, index: number) => (
              <Col xs={24} sm={12} md={8} key={index}>
                <Card>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: '24px', marginRight: '10px' }}>
                      {item.icon}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                          color: parseFloat(item.value) > 0 ? 'green' : 'red',
                        }}
                      >
                        {fmt.format(parseFloat(item.value).toFixed(2))}
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          <h3>Today Reports</h3>
          <Row gutter={[16, 16]}>
            {todayItems.map((item: any, index: number) => (
              <Col xs={24} sm={12} md={8} key={index}>
                <Card>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ fontSize: '24px', marginRight: '10px' }}>
                      {item.icon}
                    </div>
                    <div style={{ flexGrow: 1 }}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {item.label}
                      </div>
                      <div
                        style={{
                          fontSize: '18px',
                          fontWeight: 'bold',
                          color: parseFloat(item.value) > 0 ? 'green' : 'red',
                        }}
                      >
                        {fmt.format(parseFloat(item.value).toFixed(2))}
                      </div>
                    </div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
}
