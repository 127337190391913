/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import reducers from './mainReducers';

const resetStoreActionType = 'main/resetStore';

const combinedReducer = combineReducers(reducers);
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const rootReducer = (state: any, action: any) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === resetStoreActionType) {
    // eslint-disable-next-line
    state = undefined;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const resetStore = () => {
  store.dispatch({ type: resetStoreActionType });
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = typeof store;
export default store;
