/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState } from 'react';
import { Table, Input, Select, Checkbox, Modal } from 'antd';
import { type PledgeItems } from '../store/pledgeTypes';
import JewelList from './JewelList';

interface Props {
  items: PledgeItems[];
  updateItems: (updatedItems: PledgeItems[]) => void;
  refreshTable: (loanNumber: string | undefined) => void;
}

export default function PledgeItemDescription({
  items,
  updateItems,
  refreshTable,
}: Props): JSX.Element {
  // const [count, setCount] = useState<number>(1);

  const handleCheckbox = (index: number) => {
    const newItems = [...items];
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    newItems[index].isChecked = !newItems[index].isChecked;
    updateItems(newItems);
  };

  const [selectedJewel, setSelectedJewel] = useState<PledgeItems>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: 'Select',
      dataIndex: 'isPledge',
      render: (_: any, record: PledgeItems, index: number) => (
        <Checkbox
          disabled={
            record.isPledged ||
            record.closureType === 'Action' ||
            record.closureType === 'Sales' ||
            record.closureType === 'Customer' ||
            record.loanClosureType === 'Melting' ||
            record.loanClosureType === 'Sales'
          }
          checked={record.isChecked}
          onChange={() => {
            handleCheckbox(index);
          }}
        />
      ),
      width: 50,
    },
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: PledgeItems, index: number) => index + 1,
      width: 30,
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      width: 150,
      render: (_: any, record: PledgeItems) => (
        <Input
          className="loan__item__input"
          value={record.loanNumber}
          disabled
        />
      ),
    },
    {
      title: 'Item Name',
      dataIndex: 'itemName',
      width: 120,
      render: (_: any, record: PledgeItems) => (
        <Select
          style={{ width: 120, height: 56, borderRadius: 12 }}
          placeholder="Item Name"
          disabled
          value={record.itemType}
          className="loan__select"
        />
      ),
    },
    {
      title: 'Qty',
      dataIndex: 'qty',
      width: 80,
      render: (_: any, record: PledgeItems) => (
        <Input disabled className="loan__item__input" value={record.quantity} />
      ),
    },
    {
      title: 'Gross Wt.',
      dataIndex: 'grossWt',
      width: 80,
      render: (_: any, record: PledgeItems) => (
        <Input
          className="loan__item__input"
          value={record.grossWeight}
          disabled
        />
      ),
    },
    {
      title: 'Unwanted Wt.',
      dataIndex: 'stoneWt',
      width: 80,
      render: (_: any, record: PledgeItems) => (
        <Input
          className="loan__item__input"
          value={record.unwantedWeight}
          disabled
        />
      ),
    },
    {
      title: 'Net Wt.',
      dataIndex: 'netWt',
      width: 80,
      render: (_: any, record: PledgeItems) => (
        <Input
          disabled
          className="loan__item__input"
          value={Number(record.grossWeight) - Number(record.unwantedWeight)}
        />
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      width: 120,
      render: (_: any, record: PledgeItems) => (
        <Select
          style={{ width: 120, height: 56, borderRadius: 12 }}
          placeholder="Purity"
          disabled
          value={record.purity}
          // options={purityDetails}
          className="loan__select"
        />
      ),
    },
    {
      title: 'Split',
      dataIndex: 'actions',
      width: 120,
      render: (_: any, record: PledgeItems) =>
        record.isPledged || record.quantity < 2 ? (
          <></>
        ) : (
          <p
            style={{
              color: '#DD1E2D',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={async () => {
              setSelectedJewel(record);
              showModal();
            }}
          >
            Split
          </p>
        ),
    },
  ];

  const handleCloseModal = () => {
    setIsModalOpen(false);
    refreshTable(selectedJewel?.loanNumber);
  };

  return (
    <div className="loan__items__table">
      <Modal
        width={1129}
        title="Split Jewel"
        open={isModalOpen}
        onCancel={handleClose}
        footer={() => <></>}
        destroyOnClose={true}
      >
        <JewelList
          selectedJewel={selectedJewel}
          closeModal={handleCloseModal}
        />
      </Modal>
      <Table
        dataSource={items}
        columns={columns}
        pagination={false}
        rowKey={(record: PledgeItems) => `${record._id}-${record.itemType}`}
      />
    </div>
  );
}
