import { createSlice } from '@reduxjs/toolkit';
import { getTransactions, getOrgTransactions, getSuspenseTransactions } from './expenseMiddleware';
import { type ExpenseResponse, type Expense, type TransactionResponse } from './expenseTypes';

interface InitialState {
  expense: Expense;
  transactions: ExpenseResponse[];
  orgTransactions: TransactionResponse[];
  suspenseTransactions: ExpenseResponse[];
}

const initialState: InitialState = {
  expense: {
    description: '',
    amount: '',
    date: '',
    type: ''
  },
  transactions: [],
  orgTransactions: [],
  suspenseTransactions: []
};

const expenseReducer = createSlice({
  name: 'expenseReducer',
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.transactions = action.payload;
    });
    builder.addCase(getOrgTransactions.fulfilled, (state, action) => {
      state.orgTransactions = action.payload;
    });
    builder.addCase(getSuspenseTransactions.fulfilled, (state, action) => {
      state.suspenseTransactions = action.payload;
    });
  },
});

export default expenseReducer.reducer;
