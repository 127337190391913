/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import hamIcon from '../../assets/icons/hamburger.svg';
import sideBarMenu from './Sidebar/menu';
import SidebarComponent from './Sidebar';
import { getPriceAction } from '../Overview/store/overviewActions';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../redux/store';
import HeaderDropdown from './HeaderDropdown';
// import Cookies from 'js-cookie';
// import { TOKEN } from '../../constant';

export default function ProtectedLayout(): JSX.Element {
  const [collapsed, setCollapsed] = React.useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleSidebar = () => {
    setCollapsed((prev) => !prev);
  };

  const allowedRoles: any = {
    Overview: ['manager', 'auditor', 'superadmin', 'cashier', 'billing'],
    'Bank Pledge': ['manager', 'cashier', 'superadmin'],
    Loan: ['manager', 'billing', 'superadmin', 'cashier'],
    Customer: ['manager', 'billing', 'superadmin', 'cashier'],
    Transactions: ['manager', 'cashier', 'superadmin'],
    Deposits: ['manager', 'superadmin', 'cashier'],
    'Interest Scheme': ['superadmin'],
    Users: ['superadmin'],
    Investment: ['superadmin'],
    Recovery: ['superadmin', 'manager', 'billing'],
  };

  const [roleName, setRoleName] = useState<string>('auditor');

  const logout = () => {
    window.localStorage.clear();
    navigate('/sign-in');
  };

  const { goldPrice, silverPrice }: any = useSelector<any>(
    (state) => state.overview
  );

  const dispatch = useDispatch<AppDispatch>();

  const getPrices = async (): Promise<void> => {
    await getPriceAction(dispatch);
  };

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    if (token == null) {
      navigate('/sign-in');
    } else {
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      const role = window.localStorage.getItem('role') ?? 'Auditor';
      setRoleName(role);

      const pathLabel = sideBarMenu.filter((x) => x.to === location.pathname)[0]
        .label;

      const locationEnabledRoles = allowedRoles[pathLabel];
      if (locationEnabledRoles.includes(role) === false) {
        navigate('/sign-in');
      }
    }

    void getPrices();
  }, []);

  useEffect(() => {}, [goldPrice, silverPrice]);

  const userName: string | null = localStorage.getItem('username');

  return (
    <div className="protected__layout__container">
      <div className="sidebar">
        <SidebarComponent collapsed={collapsed} role={roleName} />
      </div>
      <div className="main__container">
        <div
          className={
            collapsed
              ? 'header__container sidebar__collapsed'
              : 'header__container sidebar__expanded logout__expanded'
          }
        >
          <img src={hamIcon} alt="hamIcon" onClick={toggleSidebar} />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: 64,
              alignItems: 'center',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: 10,
              }}
            >
              <p style={{ margin: 0, color: goldPrice === 0 ? 'red' : '' }}>
                Gold rate today: {goldPrice}
              </p>
              <p style={{ margin: 0, color: silverPrice === 0 ? 'red' : '' }}>
                Silver rate today: {silverPrice}
              </p>
            </div>
            <HeaderDropdown username={userName ?? ''} logout={logout} />
          </div>
        </div>

        <div className={collapsed ? 'sidebar__collapsed' : 'sidebar__expanded'}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
