import React, { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import loginAction from './store/signinActions';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../redux/store';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import CoverImage from '../../assets/images/Cover.jpg';
import './index.scss';

const SignInPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = async (values: { userName: string; password: string }) => {
    setIsLoading(true);
    const action = loginAction;
    // eslint-disable-next-line @typescript-eslint/no-confusing-void-expression
    const response: {
      branch: any;
      role: string;
      token: string;
      username: string;
      branches: any;
      organizationName: string;
      organizationLogo: string;
    } = await action(dispatch, values);
    if (response?.token !== undefined) {
      window.localStorage.setItem('token', response.token);
      window.localStorage.setItem('role', response.role);
      window.localStorage.setItem('branch', JSON.stringify(response.branch));
      window.localStorage.setItem(
        'branches',
        JSON.stringify(response.branches)
      );
      window.localStorage.setItem('username', response.username);
      window.localStorage.setItem(
        'organizationName',
        response.organizationName
      );
      window.localStorage.setItem(
        'organizationLogo',
        response.organizationLogo
      );
      switch (response.role) {
        case 'superadmin':
          navigate('/');
          break;
        case 'cashier':
          navigate('/transaction');
          break;
        case 'billing':
          navigate('/loan');
          break;
        case 'auditor':
          navigate('/');
          break;
        case 'manager':
          navigate('/');
          break;
      }
    }
    setIsLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="sign-in-container">
      <Spin spinning={isLoading} fullscreen />
      <div
        className="cover-image"
        style={{
          backgroundImage: `url(${CoverImage})`,
          width: '50%',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '100vh',
        }}
      ></div>
      <div className="form-container">
        <p className="title">Sign In to your Account</p>
        <p className="subtitle">Welcome back! please enter your details</p>
        <Form
          form={form}
          name="signin_form"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name="userName"
            rules={[{ required: true, message: 'Please input your username!' }]}
          >
            <Input
              className="signin__input"
              placeholder="Username"
              prefix={
                <MailOutlined style={{ color: '#64748B', marginRight: 8 }} />
              }
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password
              className="signin__input"
              placeholder="Password"
              prefix={
                <LockOutlined style={{ color: '#64748B', marginRight: 8 }} />
              }
            />
          </Form.Item>

          <Form.Item>
            <Button className="signin__button" htmlType="submit">
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SignInPage;
