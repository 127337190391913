import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import { createUser, getUsers, deleteUser } from './usersMiddleware';
import { type User } from './usersTypes';

export const createUserAction = async (
  dispatch: AppDispatch,
  payload: User
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createUser(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('User created successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating user'));
    return false;
  }
};

export const getUsersAction = async (
  dispatch: AppDispatch,
  payload: { branch?: string }
) => {
  const res = await dispatch(getUsers(payload));
  console.log(res);
};

export const deleteUserAction = async (
  dispatch: AppDispatch,
  payload: {userId: string}
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(deleteUser(data.userId));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('User deleted successfully'));
    void dispatch(getUsers({}));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error deleting user'));
    return false;
  }
};