import React, { useState, useEffect } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  getSuspenseTransactionsAction,
  deleteSuspenseAction,
} from '../store/expenseActions';
import { type ExpenseResponse } from '../store/expenseTypes';
import dayjs from 'dayjs';

export default function SuspenseTransactionsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { suspenseTransactions }: any = useSelector<any>(
    (state) => state?.expense
  );

  const handleDelete = async (expense: ExpenseResponse) => {
    await deleteSuspenseAction(dispatch, { id: expense._id });
    void getTransactions();
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTransactions = async (): Promise<void> => {
    setIsLoading(true);
    await getSuspenseTransactionsAction(dispatch, {});
    setIsLoading(false);
  };

  useEffect(() => {
    void getTransactions();
  }, []);

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: ExpenseResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Branch',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.branch.name}</p>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.description}</p>
      ),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.amount}</p>
      ),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p>{record.type}</p>
      ),
    },
    {
      title: 'Transaction Date',
      dataIndex: 'date',
      key: 'date',
      render: (_: any, record: ExpenseResponse, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        <p>{dayjs(record.date as string).format('DD-MM-YYYY')}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 200,
      render: (_: any, record: ExpenseResponse, index: number) => (
        <p
          style={{
            color: 'red',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            void handleDelete(record);
          }}
        >
          Delete
        </p>
      ),
    },
  ];

  return (
    <div className="suspense_transactions__page">
      <Spin spinning={isLoading} fullscreen />

      {/* <Button onClick={getTransactions} className="search__button">
        <span>Search</span>
      </Button> */}
      {Boolean(suspenseTransactions.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={suspenseTransactions}
            columns={columns}
            rowKey={(record: ExpenseResponse) => record._id}
          />
        </div>
      )}
      {suspenseTransactions.length === 0 && (
        <div style={{ width: '100%' }}>
          <p>No transactions available</p>
        </div>
      )}
    </div>
  );
}
