/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getBankInterestSchemeAction } from '../store/interestSchemeActions';
import {
  type BankInterest,
  // type InterestRate,
} from '../store/interestSchemeTypes';
import OutlinedButton from '../../../components/ButtonComponent/OutlinedButton';
import { type InterestRate } from '../../Pledge/store/pledgeTypes';

export default function BankTableIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  // const { bankInterestSchemes }: any = useSelector<any>(
  //   (state) => state?.interestScheme
  // );

  const [bankInterestSchemes, setBankInterestSchemes] = useState<any>({});

  const getInterestSchemes = async (): Promise<void> => {
    setBankInterestSchemes({});
    const data = await getBankInterestSchemeAction(dispatch, { branch });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (data) {
      setBankInterestSchemes(data);
    }
  };

  const [branchOptions, setBranchOptions] = useState<
    Array<{ label: string; value: string }>
  >([]);

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
    }
  }, []);

  useEffect(() => {
    if (branchOptions.length > 0) {
      setBranch(branchOptions[0].value);
    }
  }, [branchOptions]);

  useEffect(() => {}, [bankInterestSchemes]);

  // useEffect(() => {
  //   // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  //   if (branch) {
  //     void getInterestSchemes();
  //   }
  // }, [branch]);

  return (
    <div className="pledge__table__page">
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h1>Bank schemes</h1>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Form.Item
            name="branch"
            rules={[{ required: true, message: 'Please select a branch' }]}
            style={{ margin: 'auto', paddingRight: '60px' }}
          >
            <Select
              value={branchOptions[0]?.value}
              onChange={(value: string): void => {
                setBranch(value);
              }}
              style={{ width: 311, height: 56 }}
              placeholder="Select branch"
              options={branchOptions}
              className="pledge__input"
            />
          </Form.Item>
          <OutlinedButton
            label="Search"
            onClick={() => {
              void getInterestSchemes();
            }}
          />
        </div>
      </div>

      {/* {!(Object.keys(bankInterestSchemes).length > 0) && (
        <Spin spinning={true} fullscreen />
      )} */}
      {Object.keys(bankInterestSchemes).map((bank: string) => (
        <div key={bank} className="all-interest">
          <div className="container">
            <div className="interest">
              <div
                style={{
                  fontWeight: 700,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 16,
                }}
              >
                Bank Name:{' '}
                <p style={{ fontSize: 14, color: '#DD1E26' }}>{bank}</p>
              </div>
              {bankInterestSchemes[bank].map((bankInterest: BankInterest) => (
                <div key={bankInterest.schemeLimit}>
                  <h3>
                    {bankInterest.totalMonths} month scheme&nbsp;-&nbsp;
                    {bankInterest.schemeLimit}
                  </h3>
                  {bankInterest.interestRates.map(
                    (interestType: InterestRate) => (
                      <div key={interestType.startDate}>
                        <p>
                          {interestType.startDate}-{interestType.endDate}:{' '}
                          {interestType.interest}%
                        </p>
                      </div>
                    )
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
