import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from '../../redux/store';
import PledgeFormIndex from './PledgeForm';
import PledgeDetailsIndex from './PledgeDetails';

export default function PledgeIndex(): JSX.Element {
  const { pledgeResponse }: any = useSelector<RootState>(
    (rootState) => rootState.pledge
  );

  useEffect(() => {}, [pledgeResponse]);

  return (
    <div className="pledge__new__index__page">
      {pledgeResponse?.pledgeNumber === '' && (
        <div>
          {/* <div className="divider" /> */}
          <PledgeFormIndex />
        </div>
      )}
      {pledgeResponse?.pledgeNumber !== '' && (
        // <div className="">
        //   <div className="">
        //     <TabComponent
        //       taboptions={tabOptions}
        //       selectedTab={selectedIndex}
        //       setSelectedTab={setSelectedIndex}
        //     />
        //   </div>
        //   <div className="tab__container">
        //     {selectedIndex === 0 && (
        <PledgeDetailsIndex pledge={pledgeResponse} />
        //     )}
        //     {/* {selectedIndex === 1 && <CustomerFormIndex customer={customer} />} */}
        //   </div>
        // </div>
      )}
    </div>
  );
}
