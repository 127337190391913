import React, { useState, useEffect } from 'react';
import { Spin, Button, Select } from 'antd';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getLedgerAction } from '../store/overviewActions';

export default function LedgerIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getLedger = async (): Promise<void> => {
    setIsLoading(true);
    await getLedgerAction(dispatch, {
      branch,
    });
    setIsLoading(false);
  };

  return (
    <div className="ledger__table__page">
      <Spin spinning={isLoading} fullscreen />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 14,
          alignItems: 'center',
        }}
      >
        <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="expense__input"
        />
        <Button onClick={getLedger} className="search__button">
          <span>Download Ledger</span>
        </Button>
      </div>
    </div>
  );
}
