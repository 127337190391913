import React, { useState } from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { createBankAction } from '../store/interestSchemeActions';
import { type BankAccount } from '../store/interestSchemeTypes';

export default function BankIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const onFinish = async (values: BankAccount) => {
    setIsLoading(true);
    const action = createBankAction;
    const response = await action(dispatch, {
      ...values,
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="bank__page">
      <h1>Bank Creation</h1>
      <Form
        form={form}
        name="interestscheme_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="deposit__form">
          <Form.Item
            name="name"
            rules={[{ required: true, message: 'Please enter bank name' }]}
          >
            <div>
              <h4>Bank Name</h4>
              <Input className="deposit__input" placeholder="Enter bank name" />
            </div>
          </Form.Item>
        </div>

        <Button htmlType="submit" className="deposit__save__button">
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Create Bank</span>
          )}
        </Button>
      </Form>
    </div>
  );
}
