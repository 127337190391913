import React from 'react';
import { TabView, TabPanel } from 'primereact/tabview';

interface TabOptions {
  label: string;
  to: string;
  index: number | string;
}

interface Tabprops {
  taboptions: TabOptions[];
  selectedTab: number | undefined;
  setSelectedTab: (e: any) => void;
}

// interface SelectedEventProp {
//   to: string;
//   index: number | string;
//   label: string;
// }

export default function TabComponent({
  taboptions,
  selectedTab = 0,
  setSelectedTab,
}: Tabprops) {
  return (
    <div className="tabs__container">
      <TabView
        activeIndex={selectedTab}
        onTabChange={(e) => {
          setSelectedTab(e.index);
        }}
      >
        {taboptions.map((tab) => (
          <TabPanel header={tab.label} key={tab.label} />
        ))}
      </TabView>
    </div>
  );
}
