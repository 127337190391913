/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useState } from 'react';
import { Input, Button, Spin, DatePicker, Table, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { type Loan, type Customer } from '../store/customerTypes';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { loanPaymentAction } from '../store/customerActions';
import type { DatePickerProps } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';

// const { TextArea } = Input;

interface Props {
  loanDetail?: Loan;
  customer?: Customer;
  closeModal: () => void;
  closeModalNOOP: () => void;
}

export default function LoanActionModal({
  loanDetail,
  customer,
  closeModal,
  closeModalNOOP,
}: Props): JSX.Element {
  // const [principal, setPrincipal] = useState<string>('');
  // const [interest, setInterest] = useState<string>('');
  // const [amountPaid, setAmountPaid] = useState<string>('');
  // const [partPayment, setPartPayment] = useState<string>('');
  const [interest, setInterest] = useState<string>('');
  // const [offlineInterest, setOfflineInterest] = useState<string>('');
  const [onlinePrincipal, setOnlinePrincipal] = useState<string>('0');
  // const [offlinePrincipal, setOfflinePrincipal] = useState<string>('');
  // const [cashier, setCashier] = useState<string>('');
  // const [notes, setNotes] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [paymentType, setPaymentType] = useState<string>(
    loanDetail?.pendingInterest === 0 ? 'full' : 'part'
  );
  // const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [isTransactionDate, setIsTransactionDate] = useState(false);

  const [reducingField, setReducingField] = useState<number>(0);

  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());

  // const disabledDate = (current: Dayjs) => current < dayjs().startOf('day');

  // const disabledFutureDate = (current: Dayjs) =>
  //   current > dayjs().startOf('day');

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    // setDob(dateString);
    setDatePickerDate(date);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const tableJewels = loanDetail?.jewels.filter(
    (x) =>
      x.closureType === 'Action' ||
      x.closureType === 'Sales' ||
      x.closureType === 'Customer'
  );

  const dispatch = useDispatch<AppDispatch>();

  const makePledgePayment = async () => {
    setIsLoading(true);
    const response = await loanPaymentAction(dispatch, {
      loanNumber: loanDetail?.loanNumber,
      cashier: 'admin',
      transactionDate:
        (isTransactionDate || reducingField > 0) && datePickerDate != null
          ? datePickerDate?.toISOString()
          : dayjs().toISOString(),
      description,
      // Notes: notes,
      interestAmount: Number(interest),
      paymentType,
      reducingInterest: reducingField,
      principal: Number(onlinePrincipal),
      branch: loanDetail?.branch._id,
      loanClosureType,
      // Interest: {
      //   Amount: Number(interest),
      //   PaymentType: paymentType,
      //   Online: Number(onlineInterest),
      //   offline: Number(offlineInterest),
      // },

      // PrincipalOnline: Number(onlinePrincipal),
      // PrincipalOffline: Number(offlinePrincipal),
      // OtherTransaction: { Amount: 0, Description: 'Document fee' },
      // paymentMethod,
      // CustomerID: customer?.customerID,
    });
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (response.loanNumber) {
      closeModal();
    } else {
      setIsLoading(false);
    }
  };

  const [loanClosureType, setLoanClosureType] = useState<any>({});

  const handleClosureType = (jewelId: string, value: string) => {
    setLoanClosureType((prevState: any) => ({
      ...prevState,
      [jewelId]: value,
    }));
  };

  const jewelColumns = [
    {
      title: 'S.No',
      dataIndex: '_id',
      key: 'index',
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (_: any, record: any) => <p>{record.itemType}</p>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: any, index: number) => <p>{record.quantity}</p>,
    },
    {
      title: 'Gross Weight',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      render: (_: any, record: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <p>{parseFloat(record.grossWeight.toFixed(2))}</p>
      ),
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (_: any, record: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <p>{parseFloat(record.netWeight.toFixed(2))}</p>
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      key: 'purity',
      render: (_: any, record: any, index: number) => <p>{record.purity}</p>,
    },
    {
      title: 'Loan Closure Type',
      dataIndex: 'closureType',
      key: 'closureType',
      render: (_: any, record: any, index: number) => {
        return (
          <Select
            style={{ width: 120 }}
            placeholder="Closure Type"
            disabled={record.loanClosureType}
            options={[
              { value: 'Melting', label: 'Melting' },
              { value: 'Sales', label: 'Sales' },
            ]}
            onChange={(value: string): void => {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              handleClosureType(record._id, value);
            }}
            className="payment__select"
          />
        );
      },
    },
  ];

  return (
    <div className="action__payment__modal">
      <div className="divider" />
      <div className="payment__form">
        <div>
          <h4>Pending Interest ({loanDetail?.interestDays} days)</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={loanDetail?.pendingInterest}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   setPrincipal(e.target.value);
            // }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div>
        <div>
          <h4>Remaining Principal</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            disabled
            value={loanDetail?.remainingPrincipal}
            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            //   setPrincipal(e.target.value);
            // }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div>
        {/* <div>
          <h4>Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={interest}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInterest(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div> */}
        {/* <div>
          <h4>Part Interest</h4>
          <Input
            type="number"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            value={partPayment}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPartPayment(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter amount"
          />
        </div> */}
        {/* <div>
          <h4>Payment Type</h4>
          <Select
            disabled
            style={{ width: 280, height: 56 }}
            placeholder="Select payment type"
            value={paymentType}
            onChange={(value: string): void => {
              setPaymentType(value);
            }}
            options={[
              { label: 'full', value: 'full' },
              { label: 'partial', value: 'part' },
            ]}
            className="payment__select"
          />
        </div> */}

        {/* <div>
          <h4>Cashier</h4>
          <Input
            value={cashier}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCashier(e.target.value);
            }}
            className="payment__input"
            placeholder="Enter cashier"
          />
        </div> */}

        {loanDetail != null && loanDetail.pendingInterest > 0 && (
          <>
            <div>
              <h4>Interest</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={interest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInterest(e.target.value);
                  if (
                    Number(e.target.value) + Number(reducingField) <
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('part');
                  }
                  if (
                    Number(e.target.value) + Number(reducingField) >=
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('full');
                  }
                  // if (
                  //   Number(e.target.value) + Number(reducingField) >
                  //   Number(loanDetail?.pendingInterest)
                  // ) {
                  //   setIsTransactionDate(true);
                  //   setOnlinePrincipal('0');
                  //   setIsPrincipalDisabled(true);
                  // } else {
                  //   setIsTransactionDate(false);
                  //   setIsPrincipalDisabled(false);
                  // }
                }}
                className="payment__input"
                placeholder="Enter Online Interest"
              />
              {Number(interest) + Number(reducingField) >
                Number(loanDetail?.pendingInterest) && (
                <p>
                  Receiving an extra amount of{' '}
                  {Number(interest) +
                    Number(reducingField) -
                    Number(loanDetail?.pendingInterest)}{' '}
                  rupees
                </p>
              )}
            </div>
            {/* <div>
              <h4>Offline Interest</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={offlineInterest}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOfflineInterest(e.target.value);
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) <
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('part');
                  }
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) ===
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setPaymentType('full');
                  }
                  if (
                    Number(e.target.value) +
                      Number(onlineInterest) +
                      Number(reducingField) >
                    Number(loanDetail?.pendingInterest)
                  ) {
                    setIsTransactionDate(true);
                  } else {
                    setIsTransactionDate(false);
                  }
                }}
                className="payment__input"
                placeholder="Enter Offline Interest"
              />
            </div> */}
          </>
        )}

        {loanDetail != null && loanDetail.pendingInterest > 0 ? (
          <div>
            <h4>Reducing Field</h4>
            <Input
              type="number"
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              min={0}
              value={reducingField}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setReducingField(Number(e.target.value));
                if (
                  Number(e.target.value) + Number(interest) <
                  Number(loanDetail?.pendingInterest)
                ) {
                  setPaymentType('part');
                }
                if (
                  Number(e.target.value) + Number(interest) >=
                  Number(loanDetail?.pendingInterest)
                ) {
                  setPaymentType('full');
                }
                if (
                  Number(e.target.value) + Number(interest) >
                  Number(loanDetail?.pendingInterest)
                ) {
                  setIsTransactionDate(true);
                } else {
                  setIsTransactionDate(false);
                }
              }}
              className="payment__input"
              placeholder="Enter amount"
            />
          </div>
        ) : (
          <></>
        )}
        {paymentType === 'full' && (
          <>
            <div>
              <h4>Principal</h4>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={onlinePrincipal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOnlinePrincipal(e.target.value);
                }}
                className="payment__input"
                placeholder="Enter Principal"
              />
            </div>
            {/* <div>
              <h4>Offline Principal</h4>
              <Input
                type="number"
                disabled={isPrincipalDisabled}
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                value={offlinePrincipal}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setOfflinePrincipal(e.target.value);
                }}
                className="payment__input"
                placeholder="Enter Offline Principal"
              />
            </div> */}
          </>
        )}
        {/* <div>
          <h4>Payment Method</h4>
          <Select
            style={{ width: 280, height: 56 }}
            placeholder="Select payment method"
            value={paymentMethod}
            onChange={(value: string): void => {
              setPaymentMethod(value);
            }}
            options={[
              { label: 'Cash', value: 'Cash' },
              { label: 'Online', value: 'Online' },
            ]}
            className="payment__select"
          />
        </div> */}

        {(isTransactionDate || reducingField > 0) && (
          <div className="loan__input__container">
            <h4>Start Date</h4>
            <DatePicker
              style={{ height: 56, width: 280 }}
              value={datePickerDate}
              // disabledDate={
              //   reducingField > 0 ? disabledFutureDate : disabledDate
              // }
              className="loan__input"
              onChange={onChangeDate}
            />
          </div>
        )}
        <div style={{ width: '100%' }}>
          <h2>Jewels</h2>
          <Table
            dataSource={tableJewels}
            columns={jewelColumns}
            pagination={false}
          ></Table>
        </div>
        <div>
          <h4>Description</h4>
          <Input
            value={description}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDescription(e.target.value);
            }}
            className="payment__longinput"
            placeholder="Enter amount"
          />
        </div>
        {/* <div>
          <h4>Notes</h4>
          <TextArea
            value={notes}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
              setNotes(e.target.value);
            }}
            className="payment__textarea"
            placeholder="Enter amount"
          />
        </div> */}
      </div>
      <div className="payment__actions__section">
        <Button
          onClick={() => {
            closeModal();
          }}
          className="payment__cancel__button"
        >
          Cancel
        </Button>
        <Button
          disabled={
            Number(onlinePrincipal) > Number(loanDetail?.remainingPrincipal) ||
            (reducingField > 0 &&
              !(
                Number(loanDetail?.pendingInterest) ===
                Number(interest) + Number(reducingField)
              ))
          }
          onClick={makePledgePayment}
          className="payment__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Save</span>
          )}
        </Button>
      </div>
    </div>
  );
}
