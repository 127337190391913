import React, { useState } from 'react';
import TabComponent from '../../components/TabComponent';
import InterestFormIndex from './InterestForm';
import InterestTableIndex from './InterestTable';
import BankFormIndex from './BankForm';
import BankTableIndex from './BankTable';
import BankAccountIndex from './BankAccount';
import BankIndex from './Bank';

export const InterestSchemeIndex = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tabOptions = [
    {
      to: '',
      label: 'Our Interest Form',
      index: 0,
    },
    {
      to: '',
      label: 'Our Interest Table',
      index: 1,
    },
    {
      to: '',
      label: 'Bank Creation',
      index: 2,
    },
    {
      to: '',
      label: 'Bank Form',
      index: 3,
    },
    {
      to: '',
      label: 'Bank Table',
      index: 4,
    },
    {
      to: '',
      label: 'Bank Account Creation',
      index: 5,
    },
  ];

  return (
    <div className="interest__schemes__page">
      <h3>Interest Schemes</h3>
      <div className="">
        <TabComponent
          taboptions={tabOptions}
          selectedTab={selectedIndex}
          setSelectedTab={setSelectedIndex}
        />
      </div>
      <div className="main__container">
        {selectedIndex === 1 && <InterestTableIndex />}
        {selectedIndex === 0 && <InterestFormIndex />}
        {selectedIndex === 2 && <BankIndex />}
        {selectedIndex === 3 && <BankFormIndex />}
        {selectedIndex === 4 && <BankTableIndex />}
        {selectedIndex === 5 && <BankAccountIndex />}
      </div>
    </div>
  );
};
