import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import { createInterestScheme, getInterestScheme, getBankInterestScheme, createBank, getBank, getBranch, createBankInterestScheme, createBankAccount } from './interestSchemeMiddleware';
import { type BankAccount, type BankInterestScheme, type LoanOption } from './interestSchemeTypes';

export const createInterestSchemeAction = async (
  dispatch: AppDispatch,
  payload: LoanOption
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createInterestScheme(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Interest scheme created successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating interest scheme'));
    return false;
  }
};

export const createBankInterestSchemeAction = async (
  dispatch: AppDispatch,
  payload: BankInterestScheme
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createBankInterestScheme(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Bank Interest scheme created successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating bank interest scheme'));
    return false;
  }
};

export const createBankAccountAction = async (
  dispatch: AppDispatch,
  payload: BankAccount
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createBankAccount(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Bank account created successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating bank account'));
    return false;
  }
};

export const createBankAction = async (
  dispatch: AppDispatch,
  payload: {name: string}
) => {
  const data = {
    ...payload,
  };
  const res = await dispatch(createBank(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Bank created successfully'));
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating bank'));
    return false;
  }
};

export const getInterestSchemeAction = async (
  dispatch: AppDispatch,
  payload: { branch?: string }
) => {
  const res = await dispatch(getInterestScheme(payload));
  console.log(res);
};

export const getBankInterestSchemeAction = async (
  dispatch: AppDispatch,
  payload: {branch: string}
) => {
  const res = await dispatch(getBankInterestScheme(payload));
  return res.payload;
};

export const getBankNameAction = async (
  dispatch: AppDispatch,
  payload: { branch?: string }
) => {
  const res = await dispatch(getBank(payload));
  console.log(res);
};

export const getBranchAction = async (
  dispatch: AppDispatch,
  payload: any
) => {
  const res = await dispatch(getBranch(payload));
  console.log(res);
};