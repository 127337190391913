import React, { useEffect } from 'react';
// import { Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getInterestSchemeAction } from '../store/interestSchemeActions';
import {
  type LoanInterest,
  type LoanOption,
} from '../store/interestSchemeTypes';

export default function InterestTableIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { interestSchemes }: any = useSelector<any>(
    (state) => state?.interestScheme
  );

  const getInterestSchemes = async (): Promise<void> => {
    await getInterestSchemeAction(dispatch, {});
  };

  useEffect(() => {
    void getInterestSchemes();
  }, []);

  return (
    <div className="interest__table__page">
      <h1 style={{ width: '100%' }}>Interest schemes</h1>
      {/* {!(interestSchemes.length > 0) && <Spin spinning={true} fullscreen />} */}
      {interestSchemes.map((scheme: LoanOption) => {
        return (
          <div key={scheme.type} className="all-interest">
            <div className="container">
              <div className="interest">
                <div
                  style={{
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                  }}
                >
                  Interest Name:
                  <p style={{ fontSize: 14, color: '#DD1E26' }}>
                    {scheme.type}
                  </p>
                </div>
                <div
                  style={{
                    fontWeight: 700,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                  }}
                >
                  Interest Percentage:
                  <p style={{ fontSize: 14, color: '#DD1E26' }}>
                    {scheme.loanPercentage}%
                  </p>
                </div>
                {scheme.interestRates.map((interest: LoanInterest) => {
                  return (
                    <div key={interest.startDate}>
                      <p>
                        {interest.startDate}-{interest.endDate}:{' '}
                        {interest.interest}%
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
