import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest, getRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';
import { type PledgePayment } from './pledgeTypes';

export const createNewPledge = createAsyncThunk(
  'pledge/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.PLEDGES, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateObNumber = createAsyncThunk(
  'pledge/updateOb',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.PLEDGES}/${payload.id}/update-obnumber`, {obNumber: payload.obNumber});
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


export const getPledgeTypes = createAsyncThunk(
  'pledge/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(API.BANK_INTEREST, payload);
     
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPledgeById = createAsyncThunk(
  'pledge/pledgeById',
  async (payload: Record<string, string>, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.PLEDGES}/list/search`,payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const pledgeRepayment = createAsyncThunk(
  'pledge/close',
  async (payload: PledgePayment, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${API.PLEDGE_PAYMENTS}`, payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getUnclosedPledge = createAsyncThunk(
  'pledge/unclosed',
  async (payload: {branch: string}, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.PLEDGES}/list/unclosed`,payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getRenewalPledge = createAsyncThunk(
  'pledge/renewal',
  async (payload: {branch: string}, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(
        `${API.PLEDGES}/list/renewal`,payload
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const splitJewel = createAsyncThunk(
  'jewels/split',
  async (payload: { loanNumber: string, jewelId: string, jewels: any}, { rejectWithValue }) => {
    try {
      const { data } = await postRequest(
        `${API.LOANS}/jewel-split/${payload.loanNumber}/${payload.jewelId}`,{jewels: payload.jewels}
      )
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
