import React, { useEffect, useState } from 'react';
import { Upload, Button, Modal } from 'antd';
import cloudUpload from '../../assets/icons/cloud-upload.svg';

interface Props {
  src: string;
  onImage: (image: string) => void;
}

export default function ImageUpload({ src, onImage }: Props) {
  const handleUpload = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64Image = reader.result as string;
      onImage(base64Image);
    };
  };

  useEffect(() => {}, [src]);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const handleImageClick = (src: string) => {
    setImageSrc(src);
    setIsModalVisible(true);
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setImageSrc('');
  };

  return (
    <div className="image__upload__container">
      <img
        src={src?.length > 0 ? src : cloudUpload}
        alt="cloudUpload"
        className="image__upload__img"
        onClick={() => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          handleImageClick(src?.length > 0 ? src : cloudUpload);
        }}
      />
      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleClose}
        centered
      >
        <img
          src={imageSrc}
          alt="Full Size"
          style={{ width: '100%', height: '100%' }}
        />
      </Modal>
      <Upload
        accept="image/png, image/jpeg"
        showUploadList={false}
        beforeUpload={(file: any) => {
          // Check file size before upload
          const isLt2MB = file.size / 1024 / 1024 < 2;
          if (!isLt2MB) {
            console.error('Image must be smaller than 2MB.');
            return Upload.LIST_IGNORE;
          }
          return undefined;
        }}
        customRequest={(info: any) => {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          handleUpload(info.file);
        }}
      >
        <Button className="label">Upload Image</Button>
      </Upload>
    </div>
  );
}
