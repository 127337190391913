import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const updateDeposit = createAsyncThunk(
  'deposit/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.DEPOSITS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDeposit = createAsyncThunk(
  'deposit/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.DEPOSITS, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const payDeposit = createAsyncThunk(
  'deposit/pay',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.DEPOSIT_PAYMENT, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);