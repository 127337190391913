import React, { useCallback, useRef, useState } from 'react';
import Webcam from 'react-webcam';

interface Props {
  onChange?: (event: any) => void;
  name?: string;
}

function WebcamImage({ onChange, name = '' }: Props): JSX.Element {
  const [img, setImg] = useState<string | null>(null);
  const webcamRef = useRef<Webcam | null>(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: 'user',
  };

  const capture = useCallback(() => {
    if (webcamRef?.current !== null) {
      const imageSrc = webcamRef.current.getScreenshot();
      setImg(imageSrc);

      if (onChange !== undefined)
        onChange({ target: { name, value: imageSrc } });
    }
  }, []);

  return (
    <div className="Container">
      {img === null ? (
        <>
          <Webcam
            audio={false}
            mirrored={true}
            height={400}
            width={400}
            style={{ marginTop: 40 }}
            ref={(webcam) => {
              webcamRef.current = webcam;
            }}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
          <p className="webcam__button" onClick={capture}>
            Capture photo
          </p>
        </>
      ) : (
        <>
          <p
            className="webcam__button"
            onClick={() => {
              setImg(null);
            }}
          >
            Retake
          </p>
        </>
      )}
    </div>
  );
}

export default WebcamImage;
