import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, Select, Button } from 'antd';
import { type AppDispatch } from '../../../redux/store';
import { getRecoveryLoansAction } from '../../Loan/store/loanActions';

export const LoanRecoveryIndex = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [branch, setBranch] = useState<string>('');
  const [dateRange, setDateRange] = useState<string>('');

  const getRecoveryLoans = async (): Promise<void> => {
    setIsLoading(true);
    await getRecoveryLoansAction(dispatch, { branch, dateRange });
    setIsLoading(false);
  };

  return (
    <div className="loan__recovery__index__page">
      <Spin spinning={isLoading} fullscreen />
      <div className="title">
        <h1>Loan Recovery</h1>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 20 }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Branch</h4>
          <Select
            style={{ width: 311, height: 56 }}
            placeholder="Select branch"
            onChange={(selectedvalue: string): void => {
              setBranch(selectedvalue);
            }}
            options={branchOptions}
            // options={[{ value: 'krr', label: 'KRR' }]}
            className="deposit__select"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h4>Date Range</h4>
          <Select
            style={{ width: 311, height: 56 }}
            placeholder="Select Date Range"
            onChange={(selectedvalue: string): void => {
              setDateRange(selectedvalue);
            }}
            // options={branchOptions}
            options={[
              { value: '22-40', label: '22-40' },
              { value: '41-125', label: '41-125' },
              { value: '126-9999', label: '126-9999' },
            ]}
            className="deposit__select"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <Button
            disabled={branch === '' || dateRange === ''}
            onClick={getRecoveryLoans}
            className="generate__button"
          >
            <span>Generate Report</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
