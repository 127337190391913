import request from './interceptor';
import { type AxiosResponse } from 'axios';

export const getRequest = async (
  url: string,
  payload: any
): Promise<AxiosResponse<any, any>> => {
  
  const token = window.localStorage.getItem('token');
  return await request.get(url, {
    params: payload, headers: {
      'Authorization': `Bearer ${token}`
    }
  });
}
  
export const deleteRequest = async (
  url: string
): Promise<AxiosResponse<any, any>> => {
  const token = window.localStorage.getItem('token');
  return await request.delete(url, {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  })
};

export const postRequest = async (
  url: string,
  payload: any
): Promise<AxiosResponse<any, any>> => {
  const token = window.localStorage.getItem('token');
  return await request.post(url, payload, {headers:{
    'Authorization': `Bearer ${token}`
    } });
}

export const putRequest = async (
  url: string,
  payload: any
): Promise<AxiosResponse<any, any>> => {
  const token = window.localStorage.getItem('token');
  return await request.put(url, payload,{headers:{
    'Authorization': `Bearer ${token}`
    } });
}
