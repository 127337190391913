import React, { useState, useEffect } from 'react';
import { Spin, Table, Modal, Button, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getDepositAction } from '../store/depositActions';
import { type DepositResponse } from '../store/depositTypes';
import dayjs from 'dayjs';
import DepositPaymentModal from '../DepositPayment';
// import { resetDeposits } from '../store/depositReducer';

export default function DepositTableIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { deposits }: any = useSelector<any>((state) => state?.deposit);

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  const [branch, setBranch] = useState<any>('');

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(parsedBranches[0].id);
    }
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [phonenumber, setPhonenumber] = useState<string>('');

  const [payModal, setPayModal] = useState(false);
  const [selectedDeposit, setSelectedDeposit] = useState<DepositResponse>();

  const handlePayClose = () => {
    setPayModal(false);
  };

  const getDeposits = async (): Promise<void> => {
    setIsLoading(true);
    await getDepositAction(dispatch, {
      branch,
      // phoneNumber: phonenumber,
    });
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (phonenumber !== '' && phonenumber.length > 8) {
  //     void getDeposits();
  //   }
  // }, [phonenumber]);

  const handlePay = (record: DepositResponse) => {
    setSelectedDeposit(record);
    setPayModal(true);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: DepositResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.amount}</p>
      ),
    },
    {
      title: 'Invested By',
      dataIndex: 'investedBy',
      key: 'investedBy',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.investedBy}</p>
      ),
    },
    {
      title: 'Nominee',
      dataIndex: 'nominee',
      key: 'nominee',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.nominee}</p>
      ),
    },
    {
      title: 'Interest',
      dataIndex: 'interest',
      key: 'interest',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.interest}</p>
      ),
    },
    {
      title: 'Invested Date',
      dataIndex: 'investedDate',
      key: 'investedDate',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{dayjs(String(record.investedDate)).format('DD-MM-YYYY')}</p>
      ),
    },
    {
      title: 'Phonenumber 1',
      dataIndex: 'phoneNumber1',
      key: 'phoneNumber1',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.phoneNumber1}</p>
      ),
    },
    {
      title: 'Phonenumber 2',
      dataIndex: 'phoneNumber2',
      key: 'phoneNumber2',
      render: (_: any, record: DepositResponse, index: number) => (
        <p>{record.phoneNumber2}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: DepositResponse, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            handlePay(record);
          }}
        >
          Pay
        </p>
      ),
    },
  ];

  return (
    <div className="deposit__table__page">
      <Spin spinning={isLoading} fullscreen />
      {/* <h4>Enter phone number</h4> */}
      {/* <Input
        allowClear
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setPhonenumber(e.target.value);
          if (e.target.value === '') {
            dispatch(resetDeposits());
          }
        }}
        className="deposit__input"
        placeholder="Phonenumber"
      /> */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 14,
          alignItems: 'center',
        }}
      >
        <Select
          value={branch}
          onChange={(value: string): void => {
            setBranch(value);
          }}
          style={{ width: 311, height: 56 }}
          placeholder="Select branch"
          options={branchOptions}
          className="expense__input"
        />
        <Button onClick={getDeposits} className="search__button">
          <span>Search</span>
        </Button>
      </div>
      {Boolean(deposits.length) && (
        <div style={{ width: '100%' }}>
          <Table
            dataSource={deposits}
            columns={columns}
            // rowKey={(record: Deposit) => record?.id}
          />
        </div>
      )}
      {/* {phonenumber !== '' && deposits.length === 0 && (
        <div style={{ width: '100%' }}>
          <p>No deposits found for the entered phone number</p>
        </div>
      )} */}
      <Modal
        width={1129}
        title="Pay Deposit"
        open={payModal}
        onCancel={handlePayClose}
        footer={() => <></>}
      >
        {selectedDeposit !== undefined && (
          <DepositPaymentModal
            closeModalNOOP={handlePayClose}
            depositDetail={selectedDeposit}
          />
        )}
      </Modal>
    </div>
  );
}
