import React, { useEffect } from 'react';
import { Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { getUsersAction, deleteUserAction } from '../store/usersActions';
import { type UserResponse } from '../store/usersTypes';

export default function UsersTableIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { users }: any = useSelector<any>((state) => state?.users);

  const getUsers = async (): Promise<void> => {
    await getUsersAction(dispatch, {});
  };

  useEffect(() => {
    void getUsers();
  }, []);

  const handleDelete = async (user: UserResponse) => {
    const values = {
      userId: user._id,
    };

    await deleteUserAction(dispatch, values);
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: UserResponse, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (_: any, record: UserResponse, index: number) => (
        <p>{record.userName}</p>
      ),
    },
    // {
    //   title: 'Password',
    //   dataIndex: 'password',
    //   key: 'password',
    //   render: (_: any, record: UserResponse, index: number) => (
    //     <p>{record.password}</p>
    //   ),
    // },
    {
      title: 'Role',
      dataIndex: 'role',
      key: 'role',
      render: (_: any, record: UserResponse, index: number) => (
        <p>{record.role}</p>
      ),
    },
    {
      title: 'Branch',
      dataIndex: 'branch',
      key: 'branch',
      render: (_: any, record: UserResponse, index: number) => (
        <p>{record.branch?.code}</p>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: UserResponse, index: number) => (
        <p
          style={{
            color: '#ED4F9D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (record.role !== 'superadmin') void handleDelete(record);
          }}
        >
          {record.role === 'superadmin' ? '' : 'Delete'}
        </p>
      ),
    },
  ];

  return (
    <div className="users__table__page">
      {!(users.length > 0) && <Spin spinning={false} fullscreen />}
      <div style={{ width: '100%' }}>
        <Table dataSource={users} columns={columns} />
      </div>
    </div>
  );
}
