import { createSlice } from '@reduxjs/toolkit';
import { type LoanPayment } from '../../Customer/store/customerTypes';
import { getLoanTypes, getUndisbursedLoans, getUnpledgedLoans, getUnapprovedLoans, getUnsettledPayments, getRecoveryLoans } from './loanMiddleware';
import { type Metal, type InterestType } from './loanTypes';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'

const generatePDF = (loanArray: any, loanTitle: string) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF({ orientation: 'landscape' });

  doc.setFontSize(18);

  doc.text(loanTitle, 14, 22);

  const startY = 30;

  const headers = [['S.No', 'Loan Number', 'Customer', 'Phonenumber', 'Phonenumber 2',
    'Start Date', 'Interest', 'Loan Average', 'Till Date', 'Principal', 'Part Principal', 'Part Interest', 'Calculated Interest', 'Days Difference', 'Remarks']];

  const body = loanArray.map((loan: any) => [
    loan['S.No'],
    loan.loanNumber,
    loan.customerName,
    // loan.customerId,
    loan.customerPhone,
    loan.customerPhone2,
    loan.startDate,
    loan.interestType,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    loan.loanAverage !== null ? parseInt(loan.loanAverage) : 'N/A',
    loan.tillDate,
    loan.principal,
    loan.paidPrincipal,
    loan.paidInterest,
    loan.calculatedInterest,
    loan.dateDifference,
    ''
  ]);

  autoTable(doc, {
    startY,
    theme: 'grid',
    head: headers,
    body,
    columnStyles: {
      [headers[0].length - 1]: { cellWidth: 24 },
    },
    styles: {
      cellPadding: 3,
      fontSize: 6,
      valign: 'middle',
      halign: 'center',
    },
  });
  doc.save('loan-recovery.pdf');
};

interface InitialState {
  metal: Metal[];
  type: InterestType[];
  unpledgedLoans: string[];
  undisbursedLoans: string[];
  unapprovedLoans: string[];
  unsettledPayments: LoanPayment[];
}

const initialState: InitialState = {
  metal: [],
  type: [],
  unpledgedLoans: [],
  undisbursedLoans: [],
  unapprovedLoans: [],
  unsettledPayments: []
};

const loanReducer = createSlice({
  name: 'loanReducer',
  reducers: {
    resetUnpledgedLoans(state) {
      state.unpledgedLoans = initialState.unpledgedLoans;
    },
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getLoanTypes.fulfilled, (state, action) => {
      state.metal = action.payload.metals;
      state.type = action.payload.interest;
    });
    builder.addCase(getUnpledgedLoans.fulfilled, (state, action) => {
      state.unpledgedLoans = action.payload;
    });
    builder.addCase(getUndisbursedLoans.fulfilled, (state, action) => {
      state.undisbursedLoans = action.payload;
    });
    builder.addCase(getUnapprovedLoans.fulfilled, (state, action) => {
      state.unapprovedLoans = action.payload;
    });
    builder.addCase(getUnsettledPayments.fulfilled, (state, action) => {
      state.unsettledPayments = action.payload;
    });
    builder.addCase(getRecoveryLoans.fulfilled, (state, action) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      generatePDF(action.payload.loans, action.payload.title);
    });
  },
});

export const { resetUnpledgedLoans } = loanReducer.actions;
export default loanReducer.reducer;
