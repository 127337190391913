import { createAsyncThunk } from '@reduxjs/toolkit';
import { postRequest } from '../../../axios/requests';
import API from '../../../constant.api';

const login = createAsyncThunk(
  'signin/login',
  async (
    payload: { userName: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await postRequest(API.USER_LOGIN, payload);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export default login;
