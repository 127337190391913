import React, { useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useSelector } from 'react-redux';

export default function ToastComponent() {
  const toastRef = useRef<any>(null);
  const { toastMessage }: any = useSelector<any>((state) => state.toastReducer);
  useEffect(() => {
    console.log('toastMessage', toastMessage);
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    if (!toastMessage.detail) return;
    toastRef.current.show(toastMessage);
  }, [toastMessage]);

  return <Toast ref={toastRef} content="" />;
}
