import { type AppDispatch } from '../../../redux/store';
import login from './signinMiddleware';
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';

const loginAction = async (
  dispatch: AppDispatch,
  payload: { userName: string; password: string }
) => {
  const res = await dispatch(login(payload));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Login successful'));
    return res.payload;
  } else {
    dispatch(showErrorMessage('Login failed'));
    return false;
  }
};

export default loginAction;
