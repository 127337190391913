import { createSlice } from '@reduxjs/toolkit';

const toastReducer = createSlice({
  name: 'toastReducer',
  initialState: {
    toastMessage: {
      severity: 'success',
      summary: 'Success',
      detail: '',
      life: 3000,
    },
  },
  reducers: {
    showSuccessMessage: (state, action) => {
      state.toastMessage = {
        severity: 'success',
        summary: 'Success',
        detail: action.payload,
        life: 3000,
      };
    },
    showErrorMessage: (state, action) => {
      state.toastMessage = {
        severity: 'error',
        summary: 'Error',
        detail: action.payload,
        life: 3000,
      };
    },
  },
});

export const { showSuccessMessage, showErrorMessage } = toastReducer.actions;

export default toastReducer.reducer;
