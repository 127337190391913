import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import { createUserAction } from '../store/usersActions';
import { type User } from '../store/usersTypes';
// import { getBranchAction } from '../../InterestScheme/store/interestSchemeActions';
// import { type Branch } from '../../InterestScheme/store/interestSchemeTypes';

export default function UsersFormIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [form] = Form.useForm();

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
    }
  }, []);

  const onFinish = async (values: User) => {
    setIsLoading(true);
    const action = createUserAction;
    const response = await action(dispatch, {
      ...values,
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className="users__form__page">
      <h1>Create User Form</h1>
      <Form
        form={form}
        name="user_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="deposit__form">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please enter email' }]}
          >
            <div className="customers__input__container">
              <h4>Email</h4>
              <Input className="deposit__input" placeholder="Email" />
            </div>
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: 'Please enter phone number' }]}
          >
            <div className="customers__input__container">
              <h4>Phone Number</h4>
              <Input className="deposit__input" placeholder="Phonenumber" />
            </div>
          </Form.Item>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Please enter username' }]}
          >
            <div className="customers__input__container">
              <h4>First Name</h4>
              <Input className="deposit__input" placeholder="First Name" />
            </div>
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please enter lastName' }]}
          >
            <div className="customers__input__container">
              <h4>Last Name</h4>
              <Input className="deposit__input" placeholder="Last Name" />
            </div>
          </Form.Item>
          <Form.Item
            name="userName"
            rules={[{ required: true, message: 'Please enter username' }]}
          >
            <div className="customers__input__container">
              <h4>Username</h4>
              <Input className="deposit__input" placeholder="Username" />
            </div>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter password' }]}
          >
            <div className="customers__input__container">
              <h4>Password</h4>
              <Input className="deposit__input" placeholder="Password" />
            </div>
          </Form.Item>
          <Form.Item
            name="branch"
            rules={[{ required: true, message: 'Please select branch' }]}
          >
            <div className="customers__input__container">
              <h4>Branch</h4>
              <Select
                style={{ width: 311 }}
                placeholder="Select branch"
                onChange={(value: string): void => {
                  form.setFieldsValue({ branch: value });
                }}
                // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                options={branchOptions || []}
                // options={[{ value: 'krr', label: 'KRR' }]}
                className="deposit__select"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="role"
            rules={[{ required: true, message: 'Please select role' }]}
          >
            <div className="customers__input__container">
              <h4>Role</h4>
              <Select
                style={{ width: 311 }}
                placeholder="Select role"
                onChange={(value: string): void => {
                  form.setFieldsValue({ role: value });
                }}
                // options={roleOptions}
                options={[
                  { value: 'superadmin', label: 'Admin' },
                  { value: 'auditor', label: 'Auditor' },
                  { value: 'cashier', label: 'Cashier' },
                  { value: 'manager', label: 'Manager' },
                  { value: 'billing', label: 'Billing' },
                ]}
                className="deposit__select"
              />
            </div>
          </Form.Item>
        </div>

        <Button htmlType="submit" className="deposit__save__button">
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Create User</span>
          )}
        </Button>
      </Form>
    </div>
  );
}
