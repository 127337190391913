import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../components/ToastComponent/store/toastMiddleware';
import { type AppDispatch } from '../../../redux/store';
import {
  craeteNewCustomer,
  getCustomers,
  updateCustomer,
  getCustomerById,
  loanRepayment
} from './customerMiddleware';
import { type Customer, type LoanPayment } from './customerTypes';
// import moment from 'moment';

export const createNewCustomerAction = async (
  dispatch: AppDispatch,
  payload: Customer
) => {
  const data = {
    ...payload,
  };
  const res:any = await dispatch(craeteNewCustomer(data));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Customer Created Successfully'));
    return res.payload.customer.customerId;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error creating customer'));
    return false;
  }
};

export const getCustomersAction = async (
  dispatch: AppDispatch,
  payload: { customerId?: string }
) => {
  const res = await dispatch(getCustomers(payload));
  console.log(res);
};

export const getCustomerByIdAction = async (
  dispatch: AppDispatch,
  payload: Record<string, string>
) => {
  const res = await dispatch(getCustomerById(payload));
  console.log(res);
  if (res.meta.requestStatus !== 'fulfilled') {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error while fetching'));
    return false;
  }
};

export const updateCustomerAction = async (
  dispatch: AppDispatch,
  payload: Customer
) => {
  const res = await dispatch(updateCustomer(payload));

  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Customer edited Successfully'));
    return true;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error editing customer'));
    return false;
  }
};

export const loanPaymentAction = async (
  dispatch: AppDispatch,
  payload: LoanPayment
) => {
  const { customerId, ...restPayload } = payload;
  const res = await dispatch(loanRepayment(restPayload));
  if (res.meta.requestStatus === 'fulfilled') {
    dispatch(showSuccessMessage('Loan payment Successful'));
    if (customerId != null) await dispatch(getCustomerById({ customerId }));
    return res.payload;
  } else {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    dispatch(showErrorMessage(res.payload?.response?.data?.message || 'Error while making payment for loan'));
    return '';
  }
};
