import customerReducer from '../modules/Customer/store/customerReducer';
import toastReducer from '../components/ToastComponent/store/toastMiddleware';
import signinReducer from '../modules/SignIn/store/signinReducer';
import loanReducer from '../modules/Loan/store/loanReducer';
import depositReducer from '../modules/Deposit/store/depositReducer';
import expenseReducer from '../modules/Transactions/store/expenseReducer';
import pledgeReducer from '../modules/Pledge/store/pledgeReducer';
import interestSchemeReducer from '../modules/InterestScheme/store/interestSchemeReducer';
import usersReducer from '../modules/Users/store/usersReducer';
import overviewReducer from '../modules/Overview/store/overviewReducer';
import investmentReducer from '../modules/Investment/store/investmentReducer';

const reducers: any = {
  customer: customerReducer,
  taost: toastReducer,
  toastReducer,
  signinReducer,
  deposit: depositReducer,
  expense: expenseReducer,
  loan: loanReducer,
  pledge: pledgeReducer,
  interestScheme: interestSchemeReducer,
  users: usersReducer,
  overview: overviewReducer,
  investment: investmentReducer
};

export default reducers;
