import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const createInvestment = createAsyncThunk(
  'investment/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.INVESTMENTS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvestment = createAsyncThunk(
  'investment/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(API.INVESTMENTS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvestment = createAsyncThunk(
  'investment/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(`${API.INVESTMENTS}/${payload.branch}`, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const payDeposit = createAsyncThunk(
  'deposit/pay',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.DEPOSIT_PAYMENT, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);