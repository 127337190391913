import React, { useEffect, useState } from 'react';
import { Spin, Table, Modal, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  getUnapprovedLoansAction,
  updateUnapprovedLoanAction,
} from '../../Loan/store/loanActions';
import { type Customer, type Loan } from '../../Customer/store/customerTypes';
import LoanModal from '../../Customer/LoanModal';

export default function LoanApprovalsIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const { unapprovedLoans }: any = useSelector<any>((state) => state?.loan);

  const getLoans = async (): Promise<void> => {
    await getUnapprovedLoansAction(dispatch, {});
  };

  const [selectedLoan, setSelectedLoan] = useState<Loan>();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer>();

  useEffect(() => {
    void getLoans();
  }, []);

  const handleUpdate = async (loan: Loan, status: string) => {
    await updateUnapprovedLoanAction(dispatch, { id: loan._id, status });
    void getLoans();
  };

  const columns = [
    {
      title: 'S.No',
      dataIndex: 'sno',
      render: (_: any, record: Loan, index: number) => index + 1,
      width: 50,
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: Loan, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: 'customer',
      render: (_: any, record: Loan, index: number) => (
        <img src={record.customer?.photo} style={{ height: 130, width: 130 }} />
      ),
    },
    {
      title: 'Principal',
      dataIndex: 'principal',
      key: 'principal',
      width: 40,
      render: (_: any, record: Loan, index: number) => (
        <p>{record.principal}</p>
      ),
    },
    {
      title: 'Approval Type',
      dataIndex: 'approvalType',
      key: 'approvalType',
      width: 60,
      render: (_: any, record: Loan, index: number) => (
        <p>{record.approvalType}</p>
      ),
    },
    {
      title: 'Loan Average',
      dataIndex: 'loanAverage',
      key: 'loanAverage',
      width: 60,
      render: (_: any, record: Loan, index: number) => (
        <p>{record.loanAverage?.toFixed(0)}</p>
      ),
    },
    {
      title: 'Calculated Value',
      dataIndex: 'calculatedValue',
      key: 'calculatedValue',
      width: 60,
      render: (_: any, record: Loan, index: number) => (
        <p>
          {record.approvalType === 'Additional Amount'
            ? record.calculatedPrincipal
            : record.calculatedDocumentCharges}
        </p>
      ),
    },
    {
      title: 'Requested Value',
      dataIndex: 'requestedValue',
      key: 'requestedValue',
      width: 60,
      render: (_: any, record: Loan, index: number) => (
        <p>
          {record.approvalType === 'Additional Amount'
            ? record.principal
            : record.documentCharge}
        </p>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: Loan) => (
        <p
          style={{
            color: '#DD1E2D',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={async () => {
            setSelectedLoan(record);
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            setSelectedCustomer(record.customer);
            showModal();
          }}
        >
          View Details
        </p>
      ),
    },
    {
      title: 'Approve',
      dataIndex: 'actions',
      width: 100,
      render: (_: any, record: Loan, index: number) => (
        <Button
          type="primary"
          style={{
            backgroundColor: '#387F39', // Custom background color
            borderColor: '#387F39', // Custom border color to match the background
          }}
          onClick={() => {
            void handleUpdate(record, 'approve');
          }}
        >
          Approve
        </Button>
      ),
    },
    {
      title: 'Reject',
      dataIndex: 'actions2',
      width: 100,
      render: (_: any, record: Loan, index: number) => (
        <Button
          type="primary"
          style={{
            backgroundColor: '#DD1E2D', // Custom background color
            borderColor: '#DD1E2D', // Custom border color to match the background
          }}
          onClick={() => {
            void handleUpdate(record, 'reject');
          }}
        >
          Reject
        </Button>
      ),
    },
  ];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="users__table__page">
      {!(unapprovedLoans.length > 0) && <Spin spinning={false} fullscreen />}
      <div style={{ width: '100%' }}>
        <Table dataSource={unapprovedLoans} columns={columns} />
      </div>
      <Modal
        width={1129}
        title="Loan Details"
        open={isModalOpen}
        onCancel={handleClose}
        footer={() => <></>}
      >
        <LoanModal
          openPrintModal={() => {}}
          customer={selectedCustomer}
          transactions={[]}
          loanDetail={selectedLoan}
        />
      </Modal>
    </div>
  );
}
