import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, postRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const createInterestScheme = createAsyncThunk(
  'interestscheme/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.LOAN_INTEREST, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBankInterestScheme = createAsyncThunk(
  'bankinterestscheme/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.BANK_INTEREST, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBankAccount = createAsyncThunk(
  'bankaccount/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.BANK_ACCOUNT_NAMES, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createBank = createAsyncThunk(
  'bank/create',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await postRequest(API.BANKS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInterestScheme = createAsyncThunk(
  'interestscheme/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.LOAN_INTEREST, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBankInterestScheme = createAsyncThunk(
  'bankinterestscheme/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.BANK_INTEREST,{branch: payload.branch});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBank = createAsyncThunk(
  'bank/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.BANKS, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBranch = createAsyncThunk(
  'branch/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const { data } = await getRequest(API.BRANCHES, {});
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
