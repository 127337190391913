import { createSlice } from '@reduxjs/toolkit';
import { getDeposit, updateDeposit } from './depositMiddleware';
import { type DepositResponse, type Deposit } from './depositTypes';

interface InitialState {
  deposit: Deposit;
  deposits: DepositResponse[];
}

const initialState: InitialState = {
  deposit: {
    id: '',
    branch: '',
    amount: 0,
    investedBy: "",
    interest: "",
    nominee: "",
    phoneNumber1: "",
    phoneNumber2: ""
  },
  deposits: []
};

const depositReducer = createSlice({
  name: 'depositReducer',
  reducers: {
    resetDeposits(state) {
      state.deposits = initialState.deposits
    }
  },
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDeposit.fulfilled, (state, action) => {
      state.deposits = action.payload;
    });
    builder.addCase(updateDeposit.fulfilled, (state, action) => {
      state.deposit = action.payload;
    });
  },
});

export const { resetDeposits } = depositReducer.actions;
export default depositReducer.reducer;