/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Spin,
  Select,
  DatePicker,
  Tag,
  Cascader,
} from 'antd';
import type { DatePickerProps, GetProp, CascaderProps } from 'antd';
// import { type RangePickerProps } from 'antd/es/date-picker';
import dayjs, { type Dayjs } from 'dayjs';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
import {
  craeteNewPledgeAction,
  getPledgeTypesAction,
} from '../store/pledgeActions';
import {
  searchLoanNumbersAction,
  // getLoanByLoanNumberAction,
} from '../../Loan/store/loanActions';
import {
  type PledgeItems,
  type BankInterest,
  // type Metal,
  // type BankScheme,
  // type Interest,
  type PledgeRequest,
} from '../store/pledgeTypes';
// import { type LoanItem } from '../../Loan/store/loanTypes';
import PledgeItemDescription from '../PledgeItemDescription';
import { useNavigate } from 'react-router-dom';
// import ImageUpload from '../../../components/ImageUpload';
// import WebcamImage from '../../../components/ImageUpload/webCamUpload';
// import ItemDescription from '../PledgeItemDescription';

interface SelectOption {
  label: string;
  value: string;
}

interface SelectOption2 {
  label: string;
  value: string;
  totalMonths: string;
  schemeLimit: null | string;
}

const { TextArea } = Input;

interface Option {
  value: string | number;
  label: string;
  children?: Option[];
}

type DefaultOptionType = GetProp<CascaderProps, 'options'>[number];

export default function PledgeFormIndex(): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const [form] = Form.useForm();

  // const { pledgeMetal }: any = useSelector<any>((state) => state.pledge);
  const { bankScheme }: any = useSelector<any>((state) => state.pledge);

  const [bankOptions, setBankOptions] = useState<SelectOption[]>([]);
  // const [metalOptions, setMetalOptions] = useState<SelectOption[]>([]);
  const [interestOptions, setInterestOptions] = useState<SelectOption2[]>([]);
  const [accountNameOptions, setAccountNameOptions] = useState<SelectOption[]>(
    []
  );

  const [loanData, setLoanData] = useState<any>([]);
  const [branch, setBranch] = useState<string>('');
  const [obName, setObName] = useState<string>('');

  // const [loanNumber, setLoanNumber] = useState<string>('');
  const [loanNumbers, setLoanNumbers] = useState<any>([]);
  const [loanItems, setLoanItems] = useState<any>([]);
  const [customerNames, setCustomerNames] = useState<string[]>([]);
  const [bank, setBank] = useState<any>('');

  const onChange = async (value: any) => {
    setLoanItems([]);
    setCustomerNames([]);
    const flattenedArray = value.reduce(
      (accumulator: any, currentArray: any) => {
        return accumulator.concat(currentArray);
      },
      []
    );
    setLoanNumbers(flattenedArray);

    const tempLoanItems: any[] = [];
    const tempCustomerNames: any[] = [];

    await Promise.all(
      flattenedArray.map(async (x: string) => {
        const { jewels } = loanData.filter((y: any) => y.loanNumber === x)[0];

        const customer = loanData.filter((y: any) => y.loanNumber === x)[0]
          .customer;

        tempCustomerNames.push(customer.name);

        if (Boolean(jewels) && jewels.length > 0) {
          const loanItemsWithNumber = jewels.map((jewel: PledgeItems) => ({
            ...jewel,
            loanNumber: x,
          }));
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          tempLoanItems.push(...loanItemsWithNumber);
        }
      })
    );
    setCustomerNames(tempCustomerNames);
    setLoanItems(tempLoanItems);
  };

  // const addLoanNumber = (value: string) => {
  //   if (!loanNumbers.includes(value)) {
  //     form.setFieldsValue({ LoanNumber: [...loanNumbers, value] });
  //     setLoanNumbers([...loanNumbers, value]);
  //     setLoanNumber('');
  //   }
  // };

  // const removeLoanNumber = (value: string) => {
  //   const updatedLoanNumbers = [...loanNumbers.filter((x) => x !== value)];
  //   setLoanNumbers(updatedLoanNumbers);
  //   form.setFieldsValue({ LoanNumber: updatedLoanNumbers });
  // };

  const getPledgeTypes = async (): Promise<void> => {
    await getPledgeTypesAction(dispatch, { branch });
  };

  useEffect(() => {
    form.setFieldsValue({ startDate: dayjs() });
  }, []);

  useEffect(() => {
    if (branch) {
      void getPledgeTypes();
    }
  }, [branch]);

  useEffect(() => {
    const bankOptions: Array<{ label: string; value: string }> = Object.keys(
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      bankScheme
    ).map((x: string) => {
      return {
        label: x,
        value: x,
      };
    });
    setBankOptions(bankOptions);

    // const metalOptions: Array<{ label: string; value: string }> =
    //   pledgeMetal.map((x: Metal) => {
    //     return {
    //       label: x.metalName,
    //       value: x.metalName,
    //     };
    //   });
    // setMetalOptions(metalOptions);
  }, [bankScheme]);

  const onFinish = async (values: PledgeRequest) => {
    setIsLoading(true);
    const action = craeteNewPledgeAction;
    const response = await action(dispatch, {
      ...values,
      // branch: ,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      jewels: modifyItemsToJewels(loanItems),
      documentCharge: Number(form.getFieldValue('documentCharge')),
      principal: Number(form.getFieldValue('principal')),
    });
    setIsLoading(false);
    if (response !== false) {
      form.resetFields();
      // setPhoto('');
      // setLoanNumbers([]);
      // setItems([]);
      setLoanNumbers([]);
      setLoanItems([]);
      setDatePickerDate(dayjs());
      navigate(`/pledge?pledgeID=${response}`);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const modifyItemsToJewels = (items: PledgeItems[]) => {
    const finalItems = items.filter((x) => x.isChecked && !x.isPledged);
    return finalItems.map((item) => {
      return {
        itemType: item.itemType,
        // Purity: item.purity.split('-')[0],
        grossWeight: Number(item.grossWeight),
        unwantedWeight: Number(item.unwantedWeight),
        netWeight: Number(item.netWeight),
        // PurityPercentage: Number(item.purity.split('-')[1]),
        quantity: Number(item.quantity),
        purity: item.purity,
        loanNumber: item.loanNumber,
        _id: item._id,
      };
    });
  };

  const [branchOptions, setBranchOptions] = useState<
    Array<{
      label: string;
      value: string;
    }>
  >([]);

  useEffect(() => {
    const branches = localStorage.getItem('branches');
    if (branches != null) {
      const parsedBranches = JSON.parse(branches);
      const branchOptionTemp: Array<{ label: string; value: string }> =
        parsedBranches.map((branch: { code: string; id: string }) => ({
          label: branch.code,
          value: branch.id,
        }));
      setBranchOptions(branchOptionTemp);
      setBranch(branchOptionTemp[0].value);
      form.setFieldsValue({ branch: branchOptionTemp[0].value });
    }
    form.setFieldsValue({ pledgeType: 'New' });
  }, []);

  useEffect(() => {}, [branch]);

  // const handleChangeMetal = (value: string): void => {
  //   const metalInfo: Metal = pledgeMetal.filter(
  //     (x: Metal) => x.metalName === value
  //   )[0];

  //   const purityOptionsMap: SelectOption[] = metalInfo?.purityQuality.map(
  //     (x, index) => {
  //       return {
  //         label: String(`${x}-${index}`),
  //         value: String(`${x}-${index}`),
  //       };
  //     }
  //   );
  //   setPurityOptions(purityOptionsMap);

  //   const metalTypesMap: SelectOption[] = metalInfo?.metalType.map((x) => {
  //     return {
  //       label: x,
  //       value: x,
  //     };
  //   });
  //   setMetalTypes(metalTypesMap);
  // };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [photo, setPhoto] = useState<string>('');
  // const [showWebCam, setShowWebCam] = useState(false);
  // const handleWebCam = (): void => {
  //   setShowWebCam(!showWebCam);
  // };
  // const [purityOptions, setPurityOptions] = useState<SelectOption[]>([]);
  // const [metalTypes, setMetalTypes] = useState<SelectOption[]>([]);
  // const [items, setItems] = useState<LoanItem[]>([]);
  // const [amountPayableToCustomer, setAmountPayableToCustomer] =
  //   useState<string>('');
  // const [customerId, setCustomerId] = useState<string>('');
  // const [loanNumber, setLoanNumber] = useState<string>('');
  // const [bank, setBank] = useState<string>('');
  // const [obNumber, setObNumber] = useState<string>('');
  // const [obAccountName, setObAccountName] = useState<string>('');
  // const [netWeight, setNetWeight] = useState<string>('');
  // const [loanAmount, setLoanAmount] = useState<string>('');
  // const [documentCharges, setDocumentCharges] = useState<string>('');
  // const [tenure, setTenure] = useState<string>('');
  // const [remarks, setRemarks] = useState<string>('');
  // const [interest, setInterest] = useState<string>('');
  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());
  // const [, setCreatedDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  // const [renewalDatePickerDate, setRenewalDatePickerDate] =
  //   useState<Dayjs | null>(dayjs());
  // const [, setRenewalDate] = useState<string>(dayjs().format('YYYY-MM-DD'));

  // const onChangeCreatedDate: DatePickerProps['onChange'] = (
  //   date,
  //   dateString
  // ): void => {
  //   setDatePickerDate(date);
  //   setCreatedDate(dateString);
  // };

  // const onChangeRenewalDate: DatePickerProps['onChange'] = (
  //   date,
  //   dateString
  // ): void => {
  //   setRenewalDatePickerDate(date);
  //   setRenewalDate(dateString);
  // };

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    form.setFieldsValue({ startDate: dateString });
    // setDob(dateString);
    setDatePickerDate(date);
  };

  // eslint-disable-next-line arrow-body-style
  // const disabledDate: RangePickerProps['disabledDate'] = (
  //   current: any
  // ): boolean => {
  //   // Can not select days before after today
  //   return current < dayjs().startOf('day');
  // };

  const handleBankChange = (value: string): void => {
    setBank(value);
    if (!bankScheme[value]) {
      setInterestOptions([]);
      setAccountNameOptions([]);
      return;
    }
    const interestOptions: SelectOption2[] = bankScheme[value]?.map(
      (x: BankInterest, index: number) => {
        return {
          label: `${x.totalMonths} month scheme ${x.schemeLimit ? '-' : ''} ${
            x.schemeLimit ?? ''
          }`,
          value: x.id,
        };
      }
    );
    const nameOptions: Array<{ label: string; value: string }> = bankScheme[
      value
    ][0]?.accountNames.map((x: any, index: number) => {
      return {
        label: x.name,
        value: x.name,
      };
    });
    setInterestOptions(interestOptions);
    setAccountNameOptions(nameOptions);
  };

  useEffect(() => {}, [interestOptions, bankOptions]);

  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some((option) =>
      (option.label as string).toLowerCase().includes(inputValue.toLowerCase())
    );

  const [pledgeType, setPledgeType] = useState<string>('New');

  const onSearchLoanNumber = async (value: string) => {
    if (value !== '' && value.length === 10) {
      const data = await searchLoanNumbersAction(dispatch, value);
      if (data) {
        const existingLoanData = loanData.some(
          (item: any) => item.loanNumber === data.loanNumber
        );

        if (existingLoanData) {
          const updatedLoanData = loanData.map((item: any) =>
            item.loanNumber === data.loanNumber ? data : item
          );
          setLoanData(updatedLoanData);
        } else {
          setLoanData([...loanData, data]);
        }
        setCascaderOptions(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          [
            {
              label: data.loanNumber,
              value: data.loanNumber,
            },
          ]
        );
      }
    } else {
      setCascaderOptions([]);
    }
  };

  const [cascaderOptions, setCascaderOptions] = useState<Option[]>();

  useEffect(() => {
    const refreshData = async () => {
      if (loanNumbers.length > 0) {
        const finalArray = loanNumbers.map((eachLoanNumber: string) => [
          eachLoanNumber,
        ]);
        await onChange(finalArray);
      }
    };
    void refreshData();
  }, [loanData]);

  const handleRefreshTable = async (loanNumber: string | undefined) => {
    await onSearchLoanNumber(loanNumber ?? '');
  };

  return (
    <Form
      form={form}
      name="pledge_form"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      <div className="pledge__form">
        {/* <div className="pledge__input__container">
            <p className="pledge__input__label">Customer ID</p>
            <Input
              value={customerId}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setCustomerId(e.target.value);
              }}
              className="pledge__input"
              placeholder="Enter Customer ID"
            />
          </div> */}
        {/* <Form.Item
          name="LoanNumber"
          rules={[
            { required: true, message: 'Minimum 1 loan number required!' },
          ]}
        > */}
        <div className="pledge__input__container">
          <p className="pledge__input__label">Loan Number</p>
          {/* <Input
              value={loanNumber}
              className="pledge__input"
              placeholder="Enter Loan number"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setLoanNumber(e.target.value);
              }}
              onPressEnter={(e: any) => {
                addLoanNumber(loanNumber);
                e.preventDefault();
              }}
            /> */}
          <Cascader
            style={{ width: '100%', height: 56 }}
            options={cascaderOptions}
            onChange={(value: any) => {
              void onChange(value);
            }}
            multiple
            autoClearSearchValue
            maxTagCount={4}
            showSearch={{ filter }}
            onSearch={(value) => {
              void onSearchLoanNumber(value);
            }}
          />

          <div className="loan__tags">
            {customerNames.map((x: string) => {
              return (
                <Tag className="loan__tag" key={x}>
                  {x}
                </Tag>
              );
            })}
          </div>
        </div>
        {/* </Form.Item> */}
        <Form.Item
          name="branch"
          rules={[{ required: true, message: 'Please select branch!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Branch</p>
            <Select
              value={branch}
              onChange={(value: string): void => {
                form.setFieldsValue({ branch: value });
                setBranch(value);
                form.setFieldsValue({ bank: null });
                handleBankChange('');
              }}
              style={{ width: 311 }}
              placeholder="Select branch"
              options={branchOptions}
              className="pledge__select"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="pledgeType"
          rules={[{ required: true, message: 'Please select pledge type!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Pledge Type</p>
            <Select
              value={pledgeType}
              onChange={(value: string): void => {
                form.setFieldsValue({ pledgeType: value });
                setPledgeType(value);
              }}
              style={{ width: 311 }}
              placeholder="Select Pledge Type"
              options={[
                { label: 'New', value: 'New' },
                { label: 'Repledge', value: 'Repledge' },
              ]}
              className="pledge__select"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="startDate"
          rules={[{ required: true, message: 'Please select start date!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Start Date</p>
            <DatePicker
              value={datePickerDate}
              // disabledDate={disabledDate}
              className="pledge__input"
              onChange={onChangeDate}
            />
          </div>
        </Form.Item>
        <Form.Item
          name="bank"
          rules={[{ required: true, message: 'Select Bank!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Bank name</p>
            <Select
              style={{ width: 311 }}
              value={bank}
              placeholder="Select interest type"
              onChange={(value: string): void => {
                form.setFieldsValue({ bank: value });
                handleBankChange(value);
              }}
              options={bankOptions}
              className="pledge__select"
            />
          </div>
        </Form.Item>
        <Form.Item name="obNumber" rules={[{ message: 'Enter OB Number!' }]}>
          <div className="pledge__input__container">
            <p className="pledge__input__label">OB Number</p>
            <Input
              className="pledge__input"
              placeholder="Enter OB Account number"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="obName"
          rules={[{ required: true, message: 'Enter OB Name!!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">OB Account Name</p>
            <Select
              style={{ width: 311 }}
              value={obName}
              placeholder="Select OB Account Name"
              onChange={(value: string): void => {
                form.setFieldsValue({ obName: value });
                setObName(value);
              }}
              options={accountNameOptions}
              className="pledge__select"
            />
          </div>
        </Form.Item>
        <Form.Item
          name="interestType"
          rules={[{ required: true, message: 'Select Interest scheme!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">OB ROI</p>
            <Select
              style={{ width: 311 }}
              placeholder="Select OB interest"
              onChange={(value: string, option: any): void => {
                form.setFieldsValue({ interestType: value });
              }}
              options={interestOptions}
              className="pledge__select"
            />
          </div>
        </Form.Item>
        {/* <Form.Item
          name="JewelryMetal"
          rules={[{ required: true, message: 'Select Metal Type!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Metal</p>
            <Select
              style={{ width: 311 }}
              placeholder="Select metal"
              onChange={(value: string): void => {
                form.setFieldsValue({ JewelryMetal: value });
                handleChangeMetal(value);
              }}
              options={metalOptions}
              className="pledge__select"
            />
          </div>
        </Form.Item> */}
        {/* <Form.Item
          name="paymentMethod"
          rules={[{ required: true, message: 'Select Payment method' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Payment Method</p>
            <Select
              placeholder="Select payment method"
              onChange={(value: string): void => {
                form.setFieldsValue({ paymentMethod: value });
              }}
              options={[
                { label: 'Cash', value: 'cash' },
                { label: 'Card', value: 'card' },
                { label: 'Online', value: 'online' },
                { label: 'Other', value: 'other' },
              ]}
              className="pledge__select"
            />
          </div>
        </Form.Item> */}
        <Form.Item
          name="principal"
          rules={[{ required: true, message: 'Enter Principal!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Principal</p>
            <Input
              type="number"
              className="pledge__input"
              placeholder="Enter principal"
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
            />
          </div>
        </Form.Item>
        {/* <Form.Item
          name="OnlinePayment"
          rules={[{ required: true, message: 'Enter Online Principal!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Online Principal</p>
            <Input
              type="number"
              className="pledge__input"
              placeholder="Enter Online Principal"
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
            />
          </div>
        </Form.Item> */}
        <Form.Item
          name="documentCharge"
          rules={[{ required: true, message: 'Enter OB Document charges!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Document charges</p>
            <Input
              type="number"
              className="pledge__input"
              placeholder="Enter document charges"
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
            />
          </div>
        </Form.Item>
        {/* <div className="pledge__input__container">
            <p className="pledge__input__label">Created Date</p>
            <DatePicker
              disabledDate={disabledDate}
              value={datePickerDate}
              className="pledge__input"
              onChange={onChangeCreatedDate}
            />
          </div> */}
        {/* <div className="pledge__input__container">
            <p className="pledge__input__label">Tenure</p>
            <Input
              value={tenure}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setTenure(e.target.value);
              }}
              className="pledge__input"
              placeholder="Enter tenure"
            />
          </div>
          <div className="pledge__input__container">
            <p className="pledge__input__label">Renewal Date</p>
            <DatePicker
              disabledDate={disabledDate}
              value={renewalDatePickerDate}
              className="pledge__input"
              onChange={onChangeRenewalDate}
            />
          </div> */}
        <Form.Item
          name="remarks"
          rules={[{ required: true, message: 'Enter remarks!' }]}
        >
          <div className="pledge__input__container">
            <p className="pledge__input__label">Pledge remarks</p>
            <TextArea
              rows={3}
              className="pledge__textarea"
              placeholder="Enter remarks"
            />
          </div>
        </Form.Item>
        {/* <div className="pledge__photo__container">
          <p className="pledge__input__label">Image</p>
          <p className="pledge__photo__sub__title">
            Image format .jpg .jpeg .png
          </p>
          <p className="pledge__photo__sub__title">
            Minimum size 300px x 300px
          </p>
          <ImageUpload
            onImage={(image: string) => {
              form.setFieldsValue({ Photo: image });
              setPhoto(image);
            }}
            src={photo}
          />
          {!showWebCam && (
            <p className="pledge__button" onClick={handleWebCam}>
              Start Webcam
            </p>
          )}
          {showWebCam && (
            <WebcamImage
              name="photo"
              onChange={(e: { target: { name: string; value: string } }) => {
                form.setFieldsValue({ Photo: e?.target?.value });
                setPhoto(e?.target?.value);
              }}
            />
          )}
        </div> */}
      </div>
      <div className="pledge__items__section">
        <p className="pledge__items__title">Item Description</p>
        <PledgeItemDescription
          items={loanItems}
          refreshTable={handleRefreshTable}
          updateItems={(newItems) => {
            setLoanItems(newItems);
          }}
        />
      </div>
      <div className="pledge__actions__section">
        <Button
          onClick={() => {
            form.resetFields();
            // setPhoto('');
          }}
          className="pledge__cancel__button"
        >
          Cancel
        </Button>
        <Button
          disabled={
            !loanNumbers.length ||
            !loanItems.filter((x: PledgeItems) => x.isChecked).length
          }
          htmlType="submit"
          className="pledge__save__button"
        >
          {isLoading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 24, color: 'white' }}
                  spin
                />
              }
            />
          ) : (
            <span>Create New Pledge</span>
          )}
        </Button>
      </div>
    </Form>
  );
}
