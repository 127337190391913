/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import React, { useState } from 'react';
import {
  type PledgeResponse,
  type Transaction,
  // type Interest
} from '../store/pledgeTypes';
import { Table, Modal, Input, Button, Spin } from 'antd';
import dayjs from 'dayjs';
// import PlusFilledWhite from '../../../assets/icons/plus-filled-white.svg';
// import LoanModal from '../LoanModal';
import PaymentModal from '../PaymentModal';
import {
  getPledgeByIdAction,
  updateObNumberAction,
} from '../store/pledgeActions';
import { LoadingOutlined, EditOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
interface Props {
  pledge: PledgeResponse;
}

export default function PledgeDetailsIndex({ pledge }: Props): JSX.Element {
  // const [selectedLoan, setSelectedLoan] = useState<Loan>();

  const pledgeTransactionColumns = [
    {
      title: 'Transaction Date',
      dataIndex: 'transactionDate',
      width: 46,
      render: (_: any, record: Transaction) => (
        <p className="closed__loan__data">
          {dayjs(record.transactionDate as string).format('DD-MM-YYYY')}
        </p>
      ),
    },
    {
      title: 'Principal Paid',
      dataIndex: 'principal',
      width: 46,
      render: (_: any, record: Transaction) => (
        <p className="closed__loan__data">{record.principal}</p>
      ),
    },
    {
      title: 'Interest',
      dataIndex: 'interestAmount',
      width: 46,
      render: (_: any, record: Transaction) => (
        <p className="closed__loan__data">{record.interestAmount}</p>
      ),
    },
    {
      title: 'Reducing Field',
      dataIndex: 'reducingField',
      width: 46,
      render: (_: any, record: Transaction) => (
        <p className="closed__loan__data">{record.reducingField ?? 0}</p>
      ),
    },
    {
      title: 'Extra',
      dataIndex: 'extra',
      width: 46,
      render: (_: any, record: Transaction) => (
        <p className="closed__loan__data">{record.extra ?? 0}</p>
      ),
    },
  ];

  // const pledgeTransactionColumns = [
  //   {
  //     title: 'Transaction ID',
  //     dataIndex: 'transactionId',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.transactionId}</p>
  //     ),
  //   },
  //   {
  //     title: 'Description',
  //     dataIndex: 'description',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.description}</p>
  //     ),
  //   },
  //   {
  //     title: 'Amount',
  //     dataIndex: 'amount',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.amount}</p>
  //     ),
  //   },
  //   {
  //     title: 'Bank',
  //     dataIndex: 'bankName',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.bankName}</p>
  //     ),
  //   },
  //   {
  //     title: 'Interest',
  //     dataIndex: 'interest',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.interest}</p>
  //     ),
  //   },
  //   {
  //     title: 'Principal Paid',
  //     dataIndex: 'principalPaid',
  //     width: 46,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">{record.principalPaid}</p>
  //     ),
  //   },
  //   {
  //     title: 'Transaction Date',
  //     dataIndex: 'transactionDate',
  //     width: 130,
  //     render: (_: any, record: Transaction) => (
  //       <p className="closed__loan__data">
  //         {dayjs(record.transactionDate as string).format('DD-MM-YYYY')}
  //       </p>
  //     ),
  //   },
  // ];

  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleClose = () => {
  //   setIsModalOpen(false);
  // };

  const [isPayModalOpen, setIsPayModalOpen] = useState(false);
  const showPayModal = () => {
    setIsPayModalOpen(true);
  };

  const handlePayClose = () => {
    setIsPayModalOpen(false);
  };

  const jewelColumns = [
    {
      title: 'S.No',
      dataIndex: '_id',
      key: 'index',
      render: (_: any, record: any, index: number) => <p>{index + 1}</p>,
    },
    {
      title: 'Customer Id',
      dataIndex: 'customerId',
      key: 'customerId',
      render: (_: any, record: any, index: number) => (
        <p>{record.customerId}</p>
      ),
    },
    {
      title: 'Customer Name',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (_: any, record: any, index: number) => (
        <p>{record.customerName}</p>
      ),
    },
    {
      title: 'Loan Number',
      dataIndex: 'loanNumber',
      key: 'loanNumber',
      render: (_: any, record: any, index: number) => (
        <p>{record.loanNumber}</p>
      ),
    },
    {
      title: 'Item Type',
      dataIndex: 'itemType',
      key: 'itemType',
      render: (_: any, record: any) => <p>{record.itemType}</p>,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      render: (_: any, record: any, index: number) => <p>{record.quantity}</p>,
    },
    {
      title: 'Gross Weight',
      dataIndex: 'grossWeight',
      key: 'grossWeight',
      render: (_: any, record: any, index: number) => (
        <p>{parseFloat(record.grossWeight).toFixed(2)}</p>
      ),
    },
    {
      title: 'Net Weight',
      dataIndex: 'netWeight',
      key: 'netWeight',
      render: (_: any, record: any, index: number) => (
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        <p>{parseFloat(record.netWeight.toFixed(2))}</p>
      ),
    },
    {
      title: 'Purity',
      dataIndex: 'purity',
      key: 'purity',
      render: (_: any, record: any, index: number) => <p>{record.purity}</p>,
    },
    {
      title: 'Closure Type',
      dataIndex: 'closureType',
      key: 'closureType',
      render: (_: any, record: any, index: number) => (
        <p>{record.closureType}</p>
      ),
    },
  ];

  const dispatch = useDispatch<AppDispatch>();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [obNumber, setObNumber] = useState(pledge.obNumber);

  const showModal = () => {
    setObNumber(pledge.obNumber);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const action = updateObNumberAction;
    const response = await action(dispatch, {
      obNumber,
      id: pledge._id,
    });
    setIsLoading(false);
    setIsModalVisible(false);
    if (response) {
      setObNumber('');
      await getPledgeByIdAction(dispatch, {
        pledgeNumber: pledge.pledgeNumber,
      });
    }
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="pledge__details__page">
      <div className="profile__section">
        <p className="title">Pledge details</p>
        {/* <Avatar size={64} icon={<img src={pledge?.photo} />} /> */}
        <p className="pledge__number">{pledge?.pledgeNumber}</p>
        {pledge?.status === 'active' && (
          <p
            style={{
              width: 73,
              height: 34,
              borderRadius: 12,
              backgroundColor: '#DD1E2D',
              color: 'white',
              fontSize: 14,
              fontWeight: 600,
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={() => {
              showPayModal();
            }}
          >
            Pay
          </p>
        )}
      </div>
      <div className="profile__details">
        <div className="detail">
          <p className="detail__title">Principal</p>
          <p className="data">{pledge.principal}</p>
        </div>
        <div className="detail">
          <p className="detail__title">Interest</p>
          <p className="data">{pledge?.pendingInterest}</p>
        </div>
        <div className="detail">
          <p className="detail__title">Branch</p>
          <p className="data">{pledge?.branch.name}</p>
        </div>
        <div className="detail">
          <p className="detail__title">Remaining Principal</p>
          <p className="data">{pledge?.remainingPrincipal}</p>
        </div>
        {/* <div className="detail">
          <p className="detail__title">Total Paid Interest</p>
          <p className="data">{pledge?.totalPaidInterest}</p>
        </div> */}
        <div className="detail">
          <p className="detail__title">Created Date</p>
          <p className="data">
            {dayjs(pledge.createdDate as string).format('DD-MM-YYYY')}
          </p>
        </div>
        <div className="detail">
          <p className="detail__title">Start Date</p>
          <p className="data">
            {dayjs(pledge.startDate as string).format('DD-MM-YYYY')}
          </p>
        </div>
        <div className="detail">
          <p className="detail__title">Due Date</p>
          <p className="data">
            {dayjs(pledge.dueDate as string).format('DD-MM-YYYY')}
          </p>
        </div>
        {/* <div className="detail">
          <p className="detail__title">Last payment date</p>
          <p className="data">
            {dayjs(pledge.lastPaymentDate as string).format('DD-MM-YYYY')}
          </p>
        </div> */}
        <div className="detail">
          <p className="detail__title">Account Name</p>
          <p className="data">{pledge?.obName}</p>
        </div>
        <div className="detail">
          <p className="detail__title">OB Name</p>
          <p className="data">{pledge?.bank}</p>
        </div>
        <div className="detail">
          <p className="detail__title">OB Number</p>
          <p className="data">
            {pledge?.obNumber}
            {!pledge.obNumberUpdated ? (
              <EditOutlined
                onClick={showModal}
                style={{ marginLeft: 8, cursor: 'pointer' }}
              />
            ) : (
              ''
            )}
          </p>
        </div>
        <div className="detail">
          <p className="detail__title">Status</p>
          <p className="data">{pledge?.status}</p>
        </div>
        <Modal
          title="Edit OB Number"
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleSave}
          okText="Save"
          cancelText="Cancel"
          footer={
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                key="cancel"
                onClick={handleCancel}
                style={{
                  width: 73,
                  height: 34,
                  borderRadius: 12,
                  backgroundColor: '#f0f0f0',
                  color: 'black',
                  fontSize: 14,
                  fontWeight: 600,
                  cursor: 'pointer',
                  marginRight: 8, // Adds spacing between the buttons
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none',
                }}
              >
                Cancel
              </Button>
              {isLoading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: 'white' }}
                      spin
                    />
                  }
                />
              ) : (
                <Button
                  key="save"
                  onClick={handleSave}
                  style={{
                    width: 73,
                    height: 34,
                    borderRadius: 12,
                    backgroundColor: '#DD1E2D',
                    color: 'white',
                    fontSize: 14,
                    fontWeight: 600,
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: 'none',
                  }}
                >
                  Save
                </Button>
              )}
            </div>
          }
        >
          <Input
            placeholder="Enter OB Number"
            value={obNumber}
            onChange={(e) => {
              setObNumber(e.target.value);
            }}
          />
        </Modal>
        {/* <div className="detail">
          <p className="detail__title">Gold Packet in Branch</p>
          <p className="data">{String(pledge?.goldPacketInBranch)}</p>
        </div> */}
      </div>
      {/* <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Pledge Transactions</p>
        </div>

        <Table
          dataSource={pledge?.transactions}
          columns={pledgeInterestColumns}
          pagination={false}
          rowKey={(record: Interest) => record.startDate}
        />
      </div> */}
      <div>
        <h2>Jewels</h2>
        <Table
          dataSource={pledge?.jewels}
          columns={jewelColumns}
          pagination={false}
        ></Table>
      </div>
      <div className="closed__loan__section">
        <div className="current__loan__header">
          <p className="closed__loan__title">Pledge Transactions</p>
        </div>

        <Table
          dataSource={pledge?.transactions}
          columns={pledgeTransactionColumns}
          pagination={false}
          rowKey={(record: any) => record._id.toString()}
        />
      </div>
      {/* <div className="closed__loan__section">
        <p className="closed__loan__title">Closed Loans</p>
        <Table
          dataSource={loan?.closedLoan}
          columns={closedLoanColumns}
          pagination={false}
          rowKey={(record: Loan) => record.loanNumber.toString()}
        />
      </div> */}

      <Modal
        width={727}
        title={`Pay Pledge - ${pledge?.pledgeNumber}`}
        open={isPayModalOpen}
        onCancel={handlePayClose}
        footer={() => <></>}
        destroyOnClose={true}
      >
        <PaymentModal closeModal={handlePayClose} pledge={pledge} />
      </Modal>
    </div>
  );
}
