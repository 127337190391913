import { createAsyncThunk } from '@reduxjs/toolkit';
import { getRequest, putRequest } from '../../../axios/requests';
import API from '../../../constant.api';

export const updatePrice = createAsyncThunk(
  'price/update',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await putRequest(`${API.METALS}/price/update`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPrice = createAsyncThunk(
  'price/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(API.METALS, {});
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getOurReport = createAsyncThunk(
  'report/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(API.REPORTS, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDashboard = createAsyncThunk(
  'dashboard/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(`${API.REPORTS}/dashboard`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLedger = createAsyncThunk(
  'ledger/get',
  async (payload: any, { rejectWithValue }) => {
    try {
      const res = await getRequest(`${API.REPORTS}/ledger`, payload);
      return res.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);