/* eslint-disable @typescript-eslint/non-nullable-type-assertion-style */
import React, { useState } from 'react';
import {
  Button,
  Spin,
  Input,
  Form,
  Select,
  type DatePickerProps,
  DatePicker,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { type AppDispatch } from '../../../redux/store';
// import type { DatePickerProps } from 'antd';
// import dayjs, { type Dayjs } from 'dayjs';
import {
  type DepositPayment,
  type DepositResponse,
} from '../store/depositTypes';
import { depositPaymentAction } from '../store/depositActions';
import dayjs, { type Dayjs } from 'dayjs';
// import { depositPaymentAction } from '../store/depositActions';

// const { TextArea } = Input;

interface Props {
  depositDetail: DepositResponse;
  closeModalNOOP: () => void;
}

export default function DepositPaymentModal({
  depositDetail,
  closeModalNOOP,
}: Props): JSX.Element {
  const [datePickerDate, setDatePickerDate] = useState<Dayjs | null>(dayjs());

  const [form] = Form.useForm();

  form.setFieldsValue({
    InterestAmount: depositDetail.interest,
  });

  const onChangeDate: DatePickerProps['onChange'] = (
    date: Dayjs | null,
    dateString: string
  ): void => {
    // setDob(dateString);
    setDatePickerDate(date);
    form.setFieldsValue({ TransactionDate: dateString });
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const onFinish = async (values: DepositPayment) => {
    setIsLoading(true);
    const action = depositPaymentAction;
    const response = await action(dispatch, {
      ...values,
      branch: window.localStorage.getItem('branch') ?? 'Krr',
      createdby: 'user',
      Principal: Number(form.getFieldValue('Principal')),
      InterestAmount: Number(form.getFieldValue('InterestAmount')),
    });
    setIsLoading(false);
    if (response !== null) {
      form.resetFields();
      closeModalNOOP();
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  form.setFieldsValue({
    InvestedId: Number(depositDetail.id),
    TransactionDate: dayjs().toString(),
  });

  return (
    <div className="deposit__payment__modal">
      <div className="divider" />
      <Form
        form={form}
        name="payment_form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
      >
        <Spin spinning={isLoading} fullscreen />
        <div className="payment__form">
          <Form.Item name="InvestedId">
            <div className="payment__input__container">
              <p className="payment__input__label">Invested ID</p>
              <Input
                value={depositDetail?.id}
                disabled
                className="payment__input"
                placeholder="Enter Customer Name"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="InterestMonth"
            rules={[
              { required: true, message: 'Please select interest month!' },
            ]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Interest Month</p>
              <Select
                style={{ width: 311 }}
                placeholder="Select interest month"
                onChange={(value: string): void => {
                  form.setFieldsValue({ InterestMonth: value });
                }}
                options={[
                  { value: 'Jan', label: 'Jan' },
                  { value: 'Feb', label: 'Feb' },
                  { value: 'Mar', label: 'Mar' },
                  { value: 'Apr', label: 'Apr' },
                  { value: 'May', label: 'May' },
                  { value: 'Jun', label: 'Jun' },
                  { value: 'Jul', label: 'Jul' },
                  { value: 'Aug', label: 'Aug' },
                  { value: 'Sep', label: 'Sep' },
                  { value: 'Oct', label: 'Oct' },
                  { value: 'Nov', label: 'Nov' },
                  { value: 'Dec', label: 'Dec' },
                ]}
                className="payment__select"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="InterestYear"
            rules={[
              { required: true, message: 'Please select interest year!' },
            ]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Interest Year</p>
              <Select
                style={{ width: 311 }}
                placeholder="Select interest year"
                onChange={(value: string): void => {
                  form.setFieldsValue({ InterestYear: value });
                }}
                options={[
                  { value: '2021', label: '2021' },
                  { value: '2022', label: '2022' },
                  { value: '2023', label: '2023' },
                  { value: '2024', label: '2024' },
                  { value: '2025', label: '2025' },
                  { value: '2026', label: '2026' },
                  { value: '2027', label: '2027' },
                  { value: '2028', label: '2028' },
                  { value: '2029', label: '2029' },
                  { value: '2030', label: '2030' },
                ]}
                className="payment__select"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="Principal"
            rules={[{ required: true, message: 'Please enter principal' }]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Principal</p>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                className="payment__input"
                placeholder="Enter Principal"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="InterestAmount"
            rules={[
              { required: true, message: 'Please enter interest amount' },
            ]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Interest Amount</p>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                disabled
                value={depositDetail.interest}
                className="payment__input"
                placeholder="Enter online amount"
              />
            </div>
          </Form.Item>
          {/* <Form.Item
            name="OfflineAmount"
            rules={[{ required: true, message: 'Please enter offline amount' }]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Offline amount</p>
              <Input
                type="number"
                onWheel={(e) => {
                  e.currentTarget.blur();
                }}
                disabled
                value={depositDetail.offlineAmount}
                className="payment__input"
                placeholder="Enter offline amount"
              />
            </div>
          </Form.Item> */}
          <Form.Item
            name="CollectedBy"
            rules={[{ required: true, message: 'Please enter collected by' }]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Collected By</p>
              <Input
                className="payment__input"
                placeholder="Enter collected by"
              />
            </div>
          </Form.Item>
          <Form.Item
            name="TransactionDate"
            rules={[
              { required: true, message: 'Please select transaction date!' },
            ]}
          >
            <div className="payment__input__container">
              <p className="payment__input__label">Transaction Date</p>
              <DatePicker
                value={datePickerDate}
                className="payment__input"
                onChange={onChangeDate}
              />
            </div>
          </Form.Item>
        </div>
        <div className="payment__actions__section">
          <Button
            onClick={() => {
              closeModalNOOP();
            }}
            className="payment__cancel__button"
          >
            Cancel
          </Button>
          <Button htmlType="submit" className="payment__save__button">
            {isLoading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 24, color: 'white' }}
                    spin
                  />
                }
              />
            ) : (
              <span>Pay</span>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}
